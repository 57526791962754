/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useEffect } from 'react'
import { ChatIcon } from "./Feedback/icons/Icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clearChat } from '../../redux/slices/AiAssistant';
import FeedbackButton from "./Feedback/FeedbackButton";

function SettingsHeader() {
  const urlPath = window.location.pathname;
  const dispatch = useDispatch();
  const {chatData} = useSelector(state =>  state.aiAssistant);
  const {theme, colorPicker} = useSelector(state => state.theme)

  useEffect(() => {
    (urlPath?.includes("ai-assistant"));
  }, [urlPath])

  const startNewChat = (e) => {
    e.preventDefault(); 
    const input = document.querySelector('.chatTextarea');
    dispatch(clearChat());
    input?.focus();
    setTimeout(() => {
      input.style.height = '25px';
    }, 100)
  }

  return (
    <header className="header">
      <div className="d-flex header-inner">
        <div className="site-logo">
          {
            urlPath == '/ai-assistant' 
            ? 
              <div className="backTopage">
                <Link to={process.env.REACT_APP_ROOT} className="bkLink">
                  <span className="bkIco">  
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 1.25L6 5.75L1.5 1.25" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <h4 className="bkTitle">AI Assistant</h4>
                </Link>
                
              </div>
            :
            <a className="logo">LifeTab</a>
           }
          
        </div>
        <div className="heder-midle"></div>
        
        <div className="header-right">
          {
           urlPath == '/ai-assistant' 
           ? 
            (
              chatData?.length > 0
              ?
              <button style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}} className="newChat" onClick={startNewChat}>
                <i className="chatIco"><ChatIcon color={theme == 'dark' ? '#000' : '#fff'} /> </i>
                <span className="btnText">New Chat</span>
              </button>
              :
              <button className="newChat">
                <i className="chatIco"><ChatIcon  /> </i>
                <span className="btnText">New Chat</span>
              </button>
            )
            :
            (
              urlPath?.includes("subscription-plan") ?
              <FeedbackButton />
              :
              ""
            )
          }
        </div>

      </div>
    </header>
  )
}

export default SettingsHeader
