/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import { Modal } from 'react-bootstrap';
import AddTask from '../../AddTasks/AddTask';
import { useSelector } from 'react-redux';
import EditTaskList from '../../AddTasks/EditTaskList';

function TaskModal({ onHide, data, show, currentPage }) {
    const { theme } = useSelector(state => state.theme);
    const focusTimerState = useSelector(state => state.focusTimer);

    return (
        <>
        <Modal show={show} onHide={onHide} className="task-modal">
            <Modal.Body>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                        {
                            data === null
                            ?
                            <AddTask onHide={onHide} setFocus={true} currentPage={currentPage} focusTimerState={focusTimerState} />
                            :
                            <EditTaskList setEditTaskId={onHide} item={data} />
                        }
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default TaskModal