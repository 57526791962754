import React from 'react'
import { useSelector } from 'react-redux'

function LeftArrow() {
  const { colorPicker, theme } = useSelector(state => state.theme)
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9L5 5L1 1" stroke={theme == "dark" ? "white" : "black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default LeftArrow