
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom';
import Layout from './pages/Layout';
import toast, { Toaster } from 'react-hot-toast';
import { AuthProvider } from './AuthProvider';
import { SocketProvider } from './SocketContext';

function App() {
  return (
    <AuthProvider>
      <SocketProvider>
        <BrowserRouter>
          <Suspense fallback={<div className="initial-loader" />}>
            <Layout />
          </Suspense>
        </BrowserRouter>

        <Toaster       
          toastOptions={{ duration: 1000}}
          reverseOrder={false}
          containerClassName="deleteToastClass"
        />
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;
