// App.js
import React from 'react';
import ControlSignupForm from '../../Components/Outseta/ControlSignup';

const ControlSignUpOutseta = () => {
    return (
        <div className="page-wrapper">
            <ControlSignupForm />
        </div>
    );
};

export default ControlSignUpOutseta;

