import React, { useEffect, useRef, useState } from 'react'
import { saveConversation } from '../action/chatGPT';
import { CheckIcon } from '../LandingHeader/Feedback/icons/Icons';

const EditChatTitle = ({theme, trigger, c}) => {
    const field = useRef('');

    const save = (e) => {
        e.preventDefault();
        if(field.current.value.replace(/^\s|\s$/, '').length === 0){
            return false;
        }
        const data = {id: c.id, title: field.current.value, conversation: c.conversation};
        saveConversation(data).then(response => {
            trigger(data);
        });
    }

    return (
        <form className='edit-chat-title' onSubmit={save} style={{width: '100%', display: 'flex'}}>
            <div>
                <input autoFocus={true} type="text" defaultValue={c.title} ref={field} />
            </div>
            <a href="#" onClick={save}><CheckIcon color={theme == 'dark' ? 'gray' : 'black'} /></a>
        </form>
    )
}

export default EditChatTitle