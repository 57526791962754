import React from 'react'
import { useSelector } from 'react-redux'

function CloudImg() {
  const {colorPicker , theme}=useSelector(state=>state.theme)
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="11" fill={colorPicker}/>
    <path d="M14.6008 7.4006C14.3204 7.4006 14.0499 7.44301 13.7946 7.51983C13.7966 7.47982 13.8006 7.44101 13.8006 7.4006C13.8006 6.0751 12.7256 5.00006 11.4001 5.00006C10.2922 5.00006 9.36243 5.75143 9.08557 6.77126C8.81111 6.66204 8.51264 6.60042 8.19937 6.60042C6.87347 6.60042 5.79883 7.67507 5.79883 9.00097C5.79883 10.3261 6.87347 11.4015 8.19937 11.4015C9.30722 11.4015 10.237 10.6501 10.5139 9.62991C10.7884 9.73913 11.0868 9.80115 11.4001 9.80115C11.5501 9.80115 11.6962 9.78554 11.8386 9.75954C11.8154 9.90357 11.8002 10.0504 11.8002 10.2012C11.8002 11.7476 13.0545 13.0019 14.6008 13.0019C16.1472 13.0019 17.4015 11.7476 17.4015 10.2012C17.4015 8.65449 16.1472 7.4006 14.6008 7.4006Z" fill={theme == "dark" ? "black" :"#CCD6DD"}/>
    <path d="M16.2016 12.999C16.0148 12.999 15.8376 13.033 15.6659 13.0806C15.7523 12.8702 15.8015 12.6405 15.8015 12.3989C15.8015 11.4047 14.9954 10.5985 14.0011 10.5985C13.5934 10.5985 13.2222 10.7389 12.9205 10.967C12.638 9.8367 11.6182 8.99811 10.4003 8.99811C9.26486 8.99811 8.30185 9.72707 7.94737 10.7413C7.6481 10.6485 7.32963 10.5985 6.99955 10.5985C5.23195 10.5985 3.79883 12.0316 3.79883 13.7992C3.79883 15.5672 5.23195 16.9999 6.99955 16.9999H16.2016C17.3067 16.9999 18.2021 16.1045 18.2021 14.9995C18.2021 13.8944 17.3067 12.999 16.2016 12.999Z" fill={theme == "dark" ? "black" :"#E1E8ED"}/>
    </svg>
  )
}

export default CloudImg