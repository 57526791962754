import React from 'react'

function JournalMenuIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.6665 2.5H6.6665C7.55056 2.5 8.3984 2.85119 9.02352 3.47631C9.64865 4.10143 9.99984 4.94928 9.99984 5.83333V17.5C9.99984 16.837 9.73644 16.2011 9.2676 15.7322C8.79876 15.2634 8.16288 15 7.49984 15H1.6665V2.5Z" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3333 2.5H13.3333C12.4493 2.5 11.6014 2.85119 10.9763 3.47631C10.3512 4.10143 10 4.94928 10 5.83333V17.5C10 16.837 10.2634 16.2011 10.7322 15.7322C11.2011 15.2634 11.837 15 12.5 15H18.3333V2.5Z" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default JournalMenuIcon