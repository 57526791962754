import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TaskModal from './Settings/Modal/TaskModal'
// import BroadcastChannelAPI from '../Components/FocusTimer/BroadcastChannelAPI'
import { AuthContext } from '../AuthProvider'
import PublicRoutes from './PublicRoutes'
import { authentication } from '../Components/action/utilities'
import PrivateRoutes from './PrivateRoutes'
import FocusTimerSockets from './FocusTimerSockets'
import { useSocket } from '../SocketContext'
import { isExpired, decodeToken } from "react-jwt";
import { initFocusTimer } from '../redux/slices/timer'
import { useDispatch } from 'react-redux'
import { setFocusTasksList } from '../redux/slices/TaskSlice'
import { subscriptionDetails } from '../Components/action/common'

function Layout() {
  const focusTimer = useSelector(state =>  state.focusTimer);
  const {activeTimer, pause, start, fullscreen} = focusTimer;
  const focusTimerString = JSON.stringify(focusTimer);
  const focusTimerMemo = useMemo(() => focusTimerString ? JSON.parse(focusTimerString) : [], [focusTimerString]);

  const {focusTasksList} = useSelector(state => state.task);
  const focusTasksListString = JSON.stringify(focusTasksList);
  const focusTasksListMemo = useMemo(() => focusTasksListString ? JSON.parse(focusTasksListString) : [], [focusTasksListString]);

  const [taskModal, setTaskModal] = useState(false);
  const { isAuthenticated, setSubscription } = useContext(AuthContext);
  const location = useLocation();
  const socket = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('keydown', focusInputFieldCmd, false);
    document.addEventListener('keyup', focusInputFieldCtrl, false);
  }, []);

  useEffect(() => {
    subscriptionDetails().then(data => {
        const daysLeft = Math.ceil((new Date(data?.renewalDate) - new Date()) / (1000 * 60 * 60 * 24));
        setSubscription({...data, daysLeft})
    })
  }, [location])

  const focusInputFieldCmd = (e) => {
    if ((e.metaKey)  && e.key == 'Enter') {
      focusTaskInputField();
    }
  }
  const focusInputFieldCtrl = (e) => {
    if ((e.ctrlKey == true)  && e.key == 'Enter') {
      focusTaskInputField();
    }
  }

  const focusTaskInputField = () => {
    setTaskModal(true);
  }

  useEffect(() => {
      if(socket){
        const handleFocusTimer = (data) => {
            const { action, focusTimerData, focusTasksListData } = data;
            // Stop timer in other opened tabs
            switch (action) {
                case 'fetchFocusTimer':
                  triggerWebSocket({action: 'initTimer', focusTimerData: focusTimerMemo, focusTasksListData: focusTasksListMemo})
                  break;

                case 'initTimer':
                  dispatch(initFocusTimer(focusTimerData));
                  dispatch(setFocusTasksList(focusTasksListData));
                  break;
            }
        }
        socket.off('focusTimerInit', handleFocusTimer);
        socket.on('focusTimerInit', handleFocusTimer);

        // Cleanup function to remove the event listener
        return () => {
          socket.off('focusTimerInit', handleFocusTimer);
        };
      }
  }, [socket, focusTimerMemo, focusTasksListMemo])

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){  
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimerInit', {room: user['outseta:accountUid'], data});
    }
  }

  return (
    isAuthenticated && authentication()
    ?
    <>
      {
        taskModal
        &&
        <TaskModal
            show={true}
            data={null}
            currentPage={window.location.href.match(/tasks/) ? 'noDate' : 'home'}
            onHide={() => {
              setTaskModal(false)
            }}
        />
      }
      {/* <BroadcastChannelAPI /> */}
      <PrivateRoutes activeTimer={activeTimer} location={location} pause={pause} start={start} fullscreen={fullscreen} />
      <FocusTimerSockets socket={socket} />
    </>
    :
    (
      location.pathname.match(/\/(signup|login|welcome|logout|plan-upgrade|experimental-signup|control-signup)$/) === null
      ?
      <Navigate to="/welcome" />
      :
      <PublicRoutes />
    )
  )
}

export default Layout