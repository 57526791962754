/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { pauseSound, setTimerCompleted, stopTimer } from '../../redux/slices/timer';
import BackgroundImage from '../common/BackgroundImage';
import { focusTimerFormatV5 } from '../action/utilities';
import moment from 'moment';
import FocusTimerBar from './FocusTimerBar';
import FocusTimerCompleteModal from '../../pages/Settings/Modal/FocusTimerCompleteModal';
import { completedTask, createTask, updateTaskList } from '../action/common';
import { pushSetTaskList, setFocusTasksList, setTaskList, updateSetTaskList } from '../../redux/slices/TaskSlice';
import EditTaskList from '../../pages/AddTasks/EditTaskList';
import { saveSessions } from '../action/timer';
import { useSocket } from '../../SocketContext';
import RecurringIcon from '../../pages/Tasks/RecurringIcon';
import { rrulestr } from 'rrule';
import momentTimezone from 'moment-timezone';
import { Checkbox } from '../LandingHeader/Feedback/icons/Icons';

function FocusModeCompleted() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme, colorPicker } = useSelector(state => state.theme);
  const focusTimer = useSelector(state => state.focusTimer);
  const { activeTimer, focusSessions, pause, timerCompleted } = focusTimer;
  const {focusTasksList, taskList} = useSelector(state => state.task);
  const [todaySessions, setTodaySessions] = useState([]);
  const [showModal, setShowModal] = useState(!pause);
  const [editTask, setEditTask] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);
  const socket = useSocket();

  useEffect(() => {
    if(activeTimer === null){
        navigate(process.env.REACT_APP_ROOT)
    }else{
      focusSessions?.length > 0 && saveSessions({"sessions": {sessions: focusSessions}});
    }
  }, []);

  // useEffect(() => {
  //   // Trigger timer in wepapp and other clients
  //   if (socket !== null){
  //     const user = decodeToken(localStorage.getItem('token'));
  //     socket.emit('focusTimer', {room: user['outseta:accountUid'], data: {timer: focusTimer, tasks: focusTasksList}});
  //   }
  // }, [])

  useEffect(() => {
    if(updatedValue !== null){
      dispatch(setFocusTasksList([...focusTasksList].map(task => task.id == updatedValue.id ? {...task, title: updatedValue.title} : task)));
    }
  }, [updatedValue])

  useEffect(() => {
    if(!showModal){
      pauseSound();
    }
  }, [showModal])

  const exit = (e) => {
    e.preventDefault();

    dispatch(stopTimer());
    navigate(process.env.REACT_APP_ROOT); 
  }

  useEffect(() => {
      setTodaySessions(focusSessions.filter(session => {
          return moment().format('L') === moment(session.startTime).format('L')
      }));
      
  }, [focusSessions])

  const calcFocusedTime = (lastSession = false) => {
    let seconds = 0;
    let pauseSeconds = 0;

    if(!todaySessions.length)
      return '';

    if(lastSession){
      const session = todaySessions[todaySessions.length - 1];
      seconds += session.endTime - session.startTime;
      session?.pauses.map(pause => {
        pauseSeconds += pause.endTime - pause.startTime;
      });
    }else{
      todaySessions.map(session => {
        seconds += session.endTime - session.startTime;
        session?.pauses.map(pause => {
          pauseSeconds += pause.endTime - pause.startTime;
        });
      });
    }

    return focusTimerFormatV5((seconds - pauseSeconds)/1000, true);
  }

  const stopSound = () => {
    setShowModal(false);
    dispatch(setTimerCompleted());
  }

  const onCompleteTask = async (task, status = 1) => {
    let requestBody = {
      "id": task?.id,
      status
    }
    try {
      let tasks = [...focusTasksList];
      if(task?.repeat?.rule){
        const newTaskDate = moment().startOf('date').toISOString();
        const rruleSet = rrulestr(task?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to complete the current occurrence
        rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
        await updateTaskList(updatedTask)
        dispatch(updateSetTaskList(updatedTask));

        // Extract relevant fields from task object
        const {
          title,
          tags,
          project,
          time,
          week,
          timeZone,
          taskSource
        } = task;
  
        const response = await createTask({
          title,
          tags,
          project,
          time,
          week,
          timeZone, 
          status, 
          completedAt: newTaskDate, 
          date: newTaskDate, 
          repeat: null,
          origTaskId: task?.id,
          orgRepeatId: task?.orgRepeatId,
          taskSource
        })
        dispatch(pushSetTaskList(response?.data?.responseData?.data));
        tasks = [...focusTasksList].map(t => t.id == task.id ? response?.data?.responseData?.data : t);
      }else{
        await completedTask(requestBody);
        tasks = [...focusTasksList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : null } : t);
        const todayTasks = [...taskList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : null } : t);
        dispatch(setTaskList(todayTasks));
      }
      dispatch(setFocusTasksList(tasks));
    } catch ({ response }) {

    }
    // try {
    //   await completedTask(requestBody);
    //   const tasks = [...focusTasksList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : task.completedAt } : t);
    //   dispatch(setFocusTasksList(tasks));
    // } catch ({ response }) {}
  }

  const editTaskHandler = (e, id) => {
    if(!e.target?.closest('.check-container'))
      setEditTask(id);
  }

  const lastSessionCompletionTime = calcFocusedTime(true);

  return (
    <div className={`main-body-wrap break-focus-page break-timer-start focus-mode-timer-start focus-mode-timer-completed ${theme}`} style={{
      background: theme == 'dark' ? '#000' : '#fff'
    }}
    css={{
      '&:before': {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(39, 203, 154, 0.00) 0%, ${colorPicker} 100%)`
      }
    }}
    >
      <div className='backgroundBottom' style={{bottom: 0}}>
        <BackgroundImage height="975" />
      </div>
      <div className="page-wrapper focus-mode-start" id="focus-mode-settings-page">
        <div className="page-container">
          <div className="tasks-list-container">
            <div className="focused-items-heading">
              Focus Session {todaySessions.length} complete
              {todaySessions.length > 0 && <h1><span style={{color: colorPicker}} dangerouslySetInnerHTML={{__html: lastSessionCompletionTime}}></span><span> spent focused</span></h1>}
              <p>You've done <span dangerouslySetInnerHTML={{__html: calcFocusedTime()}}></span> of deep work today.</p>
            </div>
            <div className="focus-session-tasks" style={{background: 'transparent'}}>
              <h3 className='inner-title'><span style={{color: colorPicker}}>{focusTasksList.filter(task => task.status == 1).length} Tasks Completed</span> /{focusTasksList.length}</h3>
              <ul className="ul-task-list">
              {
                  focusTasksList.map(task => ( 
                      task.status == 1 
                      ? 
                      <>
                      {
                        editTask === task.id
                        ?
                        <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                        :
                        <li className="task-item li-task-list completed-task" key={task.id}
                        css={{
                          '&:hover': {
                            background: `none !important`,
                          }
                        }}
                        
                        onClick={e => editTaskHandler(e, task.id)}
                        >
                          <Checkbox theme={theme} defaultChecked={true} name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task, 0)} colorPicker={colorPicker}/>
                          <span
                              css={{
                                  color: theme == "dark" ? "rgba(255, 255, 255, 0.5) !important" : "rgba(0, 0, 0, 0.5) !important",
                              }}
                              className='task-title' data-tip data-for={`longText-${task?.id}`}>{task?.title}
                              <RecurringIcon task={task} colorPicker={colorPicker} />
                          </span>
                        </li> 
                      }
                      </>
                      : null
                  ))
              }
              </ul>

              <h3 className='inner-title' style={{marginTop: '10px'}}>Tasks for Another Time</h3>
              <ul className="ul-task-list">
              {
                  focusTasksList.map(task => (
                    task.status == 0
                    ? 
                    <>
                    {
                      editTask === task.id
                      ?
                      <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                      :
                      <li className="task-item li-task-list completed-task" key={task.id}
                      css={{
                        '&:hover': {
                          background: `none !important`,
                        }
                      }}
                      onClick={e => editTaskHandler(e, task.id)}
                      >
                        <Checkbox theme={theme} name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task)} colorPicker={colorPicker}/>
                        <span
                            css={{
                                color: theme == "dark" ? "white !important" : "black !important",
                            }}
                            className='task-title' data-tip data-for={`longText-${task?.id}`}>{task?.title}
                            <RecurringIcon task={task} colorPicker={colorPicker} />
                        </span>
                      </li> 
                      }
                      </>
                      : 
                      null
                  ))
              }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        {todaySessions.length > 0 && <FocusTimerBar lastSessionCompletionTime = {lastSessionCompletionTime} />}
      </div>
      {
        showModal && !timerCompleted
        &&
        <FocusTimerCompleteModal todaySessions={todaySessions} lastSessionCompletionTime={lastSessionCompletionTime} show={showModal} onHide={stopSound} />
      }
    </div>
  )
}

export default FocusModeCompleted