/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback } from 'react'
import { useState, useRef } from 'react'
import { createTask } from '../../Components/action/common'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  pushAllTaskList,
  pushCompletedTaskList,
  pushGroupedList,
  pushNoDateTaskList,
  pushOverDueTaskList,
  pushSetTaskList,
  setFocusTasksList
} from '../../redux/slices/TaskSlice'
import { useEffect } from 'react'
import moment from 'moment'
import momentTimezone from 'moment-timezone';
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import { jsx } from "@emotion/core";
import PlusIcon from './PlusIcon'
import CalendarIcon from './CalendarIcon'
import { Mention, MentionsInput } from 'react-mentions'
import { calendarFormat, format, isValidDate, parseMentionInput } from '../../Components/action/utilities'
import Select from 'react-select'
import RepeatedTaskModal from '../Settings/Modal/RepeatedTaskModal.js'
import { RRule } from 'rrule'
import Skeleton from 'react-loading-skeleton'
// import { isValidDate } from 'rrule/dist/esm/dateutil'

function AddTask({ fetchTotalCount, currentPage, groupDate, setSelectedDate = (date) => {}, selectedDate, completedList, fetchAddTaskData, onAddTaskUpdate, setTodayTaskList, upComing, upComingtaskList, onHide, setFocus = false, isActive = true, focusTimerState = {}, isSettled = true }) {
  const dispatch = useDispatch()
  // const { register, handleSubmit, reset, getValues } = useForm()
  const { handleSubmit } = useForm()
  const inputRef = useRef('');
  const titleRef = useRef();

  // global state
  const { dateChange, headerName, taskDaySelected, focusTasksList } = useSelector(state => state.task)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const [selectedDay, setSelectedDay] = useState(taskDaySelected);
  const [addTaskFocus, setAddTaskFocus] = useState(setFocus)
  const [onDateChage, setOnDateChage] = useState(null)
  const [onInputFocus, setOnInputFocus] = useState(setFocus)
  const [inputFieldValue, setInputFieldValue] = useState(false)
  const [taskTitle, setTaskTitle] = useState('');
  const [selectedRepeat, setSelectedRepeat] = useState(1);
  const [repeatListOptions, setRepeatListOptions] = useState([]);
  const [openCustomRepeat, setOpenCustomRepeat] = useState(false);
  const repeatOptionsRef = useRef(null);

  const initRepeatListOptions = useCallback(() => {
    const TaskDate = {
      from: onDateChage || new Date()
    }
    const dtstart = momentTimezone(TaskDate?.from).tz(momentTimezone.tz.guess()).endOf('day').toDate()
    const selectRepeatList = [
      { value: 1, label: `Does not repeat`, rule: null },
      { value: 2, label: `Daily`, rule: (new RRule({dtstart, freq: RRule.DAILY})).toString() },
      { value: 3, label: `Weekly on ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, interval: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 4, label: `Monthly on the first ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 5, label: `Monthly on the last ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: -1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 6, label: `Annually on ${TaskDate?.from && format(TaskDate.from, "MMMM DD")}`, rule: (new RRule({dtstart, freq: RRule.YEARLY, interval: 1})).toString() },
      { value: 7, label: `Every weekday (Monday to Friday)`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]})).toString() },
      { value: 99, label: null, rule: null },
      { value: 100, label: `Custom...`, rule: null }
    ];

    setSelectedRepeat(1)
    setRepeatListOptions(selectRepeatList)
  }, [onDateChage]);

  useEffect(() => {
   setSelectedDay(currentPage == 'noDate' ? 'No Date' : taskDaySelected)
  }, [taskDaySelected])

  const reset = () => {
    setTaskTitle('');
  }

  const onAddTask = async () => {
    //const data = getValues();
    const dateTime = moment(dateChange).format('YYYY-MM-DD')
    let date;
    let upcomingDateValue;

    switch (selectedDay) {
      case 'No Date':
        date = null
        upcomingDateValue = null
        break;
      default:
        date = upcomingDateValue = new Date(moment(onDateChage).startOf('date')).toISOString()
    }
    
    const {tags, project, title} = parseMentionInput(taskTitle ?? "");

    if (title) {
      try {
        const requestBody = {
          "title": title,
          "tags": tags,
          "project": project,
          "date": date,
          "repeat": repeatListOptions.filter(item => item.value === selectedRepeat)?.[0] ?? null,
          "status": completedList === true ? 1 : 0
        }
        let response = await createTask(requestBody)
        if(response?.data?.responseData?.data){
          response.data.responseData.data.Project = project ? {title: project} : null
          response.data.responseData.data.Tags = tags ? tags.map((tag, index) => ({name: tag, id: index+1})) : [];
        }
        switch (currentPage) {
          case 'upComing':
            dispatch(pushGroupedList(response?.data?.responseData?.data))
            break;

          case 'overDue':
            dispatch(pushOverDueTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;

          case 'allTask':
            dispatch(pushAllTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;

          case 'noDate':
            dispatch(pushNoDateTaskList(response?.data?.responseData?.data))
            // fetchTotalCount()
            break;
          case 'completedList':
            dispatch(pushCompletedTaskList(response?.data?.responseData?.data))
           // fetchTotalCount()
            break;

          default:
            // if (moment(response?.data?.responseData?.data?.date).format('YYYY-MM-DD') == dateTime) {
            dispatch(pushSetTaskList(response?.data?.responseData?.data));
            const {pause, start, activeTimer, fullscreen} = focusTimerState;
            //console.log({pause, start, activeTimer, fullscreen, focusTasksList})
            if(pause === false && start === true && activeTimer === 'focusMode' && fullscreen === true){
              dispatch(setFocusTasksList([...focusTasksList, {...response?.data?.responseData?.data, focus: 1}]));
            }

            if (dateTime != moment().format('YYYY-MM-DD')) {
              setSelectedDay('DatePicker')
            } else {
              setSelectedDay('Today')
            }
        }

        if(fetchTotalCount){
          fetchTotalCount();
        }
        
        if(onHide){
          onHide(response?.data?.responseData?.data);
          return;
        }

        setOnInputFocus(false)
        setAddTaskFocus(false)
        if (currentPage == "upComing") {
          setOnInputFocus(false)
          let repotionsBlock = document.getElementById(groupDate)
          if (repotionsBlock) repotionsBlock.style.display = "none"
          setAddTaskFocus(false);
          reset()
        } else {
          reset()
          let repotionsBlock = inputRef.current; //document.querySelector("#repotionsBlock")
          repotionsBlock.style.display = "none"
        }

        setSelectedDate(dateTime)
        if (dateTime == selectedDate) {
          fetchAddTaskData()
        }
        if(setTodayTaskList)
          setTodayTaskList(true)
        if(onAddTaskUpdate)
        onAddTaskUpdate()

      } catch (response) {
        console.log('ERROR==>', response)
      }
    }
  }

  const selectDayOfDate = (date) => {
    if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))) {
      setSelectedDay('Today')
    } else if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment(new Date(moment().startOf().add(1, 'days'))).format('YYYY-MM-DD'))) {
      setSelectedDay('Tomorrow')
    } else if ((moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD') == moment(new Date(moment().startOf().add(7, 'days'))).format('YYYY-MM-DD'))) {
      setSelectedDay('Next Week')
    } else {
      setSelectedDay('DatePicker')
    }
  }

  const addTaskOnupComingFocus = (groupDate) => {
    setOnInputFocus(true)
    selectDayOfDate(groupDate);

    let repotionsBlock = document.getElementById(groupDate)
    if (repotionsBlock) {
      repotionsBlock.style.display = "block"
    }
    setAddTaskFocus(true)
  }

  const addTaskOnFocus = () => {
    setOnInputFocus(true)
    let repotionsBlock = inputRef.current; //document.getElementById("repotionsBlock")
    if (repotionsBlock) {
      repotionsBlock.style.display = "block";
    }
    setAddTaskFocus(true)
  }

  const onCancelUpComingTask = (groupDate) => {
    setOnInputFocus(false)
    let repotionsBlock = document.getElementById(groupDate)
    if (repotionsBlock) repotionsBlock.style.display = "none"
    setAddTaskFocus(false);
    reset();
    setSelectedRepeat(1)

    if(onHide)
      onHide();
  }
  const onCancelTask = (e) => {
    setOnInputFocus(false)
    inputRef.current.style.display = "none";
    setAddTaskFocus(false);
    reset();
    setSelectedRepeat(1)

    if(onHide)
      onHide();
  }

  const onClickDateRangeSet = (day) => {
    setSelectedDay(day)
    handleAddTaskFilter(day)
  }

  const onDatePickerDateChange = (e) => {
    setOnDateChage(e)
  }

  function handleAddTaskFilter(taskDaySelected) {
    let date = null
    if (taskDaySelected === 'Today') {
      date = new Date()
    } else if (taskDaySelected === 'Tomorrow') {
      date = new Date(moment().startOf().add(1, 'days'))
    } else if (taskDaySelected === 'Next Week') {
      // date = moment(date).add(7, 'days').toISOString()
      date = new Date(moment().add(7, 'days'))
    }
    else {
      date = null
    }

    if (date)
      setOnDateChage(date);
  }

  const onInputValueChange = (value) => {
    if (value != "") {
      setInputFieldValue(true)
    } else {
      setInputFieldValue(false)
    }
  }

  useEffect(() => {
    switch (headerName) {
      case 'All':
        setSelectedDay("Today")
        break;
      case 'Overdue':
        setSelectedDay("Today")
        break;
      case 'Completed':
        setSelectedDay("Today")
        break;
      case 'NoDate':
        setSelectedDay('No Date')
        break;
      default:
    }
  }, [headerName])

  useEffect(() => {
    let currentDate = moment(dateChange).format('D MMM YYYY');
    setOnDateChage(groupDate ?? currentDate);
    // if(setFocus){
    //   currentPage == "home" ? selectDayOfDate(dateChange) : setSelectedDay('No Date')
    // }
  }, [])

  useEffect(() => {
    if(titleRef.current){
      titleRef.current.className = `add-task-input  ${inputFieldValue == false ? "" : "input-filled"} ${(theme == "dark") ? " add-task-placeholder" : ""} ${isActive ? 'active' : ''} ${addTaskFocus == true ? 'focus-input add-task-placeholderOnFocus' : ''}`
    }
  }, [inputFieldValue, addTaskFocus, isActive])

  useEffect(() => {
    !groupDate && setOnDateChage(dateChange)
  }, [dateChange])

  useEffect(() => {
    initRepeatListOptions();
  }, [onDateChage])

  function getMentionTags() {
    const items = [].map((t) => {
      return {
        id: t.name,
        display: t.name,
      };
    });
    const regex = /#(\w+)/g;

    let match;

    while ((match = regex.exec(taskTitle))) {
      const isAlready = items.find((i) => i.display === match[1]);
      if (!isAlready) {
        items.push({
          id: null,
          display: match[1],
        });
      }
    }
    return items;
  }

  function getProjectMentions() {
    const items = [].map((t) => {
      return {
        id: t.title,
        display: t.title,
      };
    });
    const regex = /@(\w+)/g;

    let match;

    while ((match = regex.exec(taskTitle))) {
      const isAlready = items.find((i) => i.display === match[1]);
      if (!isAlready) {
        items.push({
          id: null,
          display: match[1],
        });
      }
    }
    return items;
  }

  const suggestionTags = getMentionTags();
  const suggestionProjects = getProjectMentions();

  const mentionStyle = {
    input: {
      border: 0
    },
    control: {
      fontSize: 16,
      height: '18px'
    },
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: colorPicker,
          color: "white",
        },
      },
    },
  };

  const CustomStyle = {
    singleValue: (base, state) => {
      return {
        ...base,
        background: theme !== 'dark' ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}` : `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker}`,
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text !important'
      }
    },
    menu: (base, state) => {
      return {
        ...base,
        background: theme === 'dark' ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #000' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF'
      }
    },
   option: (base, state) => {
      let background = theme !== 'dark' ? 'black' : '#fff';
      let color = `${theme !== 'dark' ? 'black' : '#fff'} !important`;
      let backgroundImage = 'none';
      let backgroundPosition = '0 0';
      // const isLastOption = state.options.indexOf(state.data) === state.options.length - 1;

      // if (isLastOption) {
      //   background = `linear-gradient(0deg,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),${colorPicker} !important`;
      // }

      if (state.isFocused) {
        background = theme === 'dark' ? `linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9)), ${colorPicker} !important` : `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)), ${colorPicker} !important`;
        color = `${theme === 'dark' ? '#000' : '#fff'} !important`;
      }

      if (state.isSelected) {
        color = "white !important";
        background = `black url('data:image/svg+xml;charset=UTF-8,%3Csvg width%3D%2214%22 height%3D%2210%22 viewBox%3D%220 0 14 10%22 fill%3D%22none%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath d%3D%22M13 1L4.75 9.25L1 5.5%22 stroke%3D%22${colorPicker.replace(/^#/, '%23')}%22 stroke-width%3D%221.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22/%3E%3C/svg%3E') no-repeat !important`;
        backgroundPosition = 'right 20px center !important';
      }

      return {
        ...base,
        color,
        background,
        backgroundImage,
        backgroundPosition
      };
    }
  } 

  const setCustomRule = (value) => {
    if(value?.rule){
      setSelectedRepeat(99);
      setRepeatListOptions(options => options.map(option => option.value === 99 ? {...option, ...value} : option));
    }else{
      setOpenCustomRepeat(value);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey) {
      e.preventDefault(); // Prevent default form submission
      onAddTask();
    }else{
      if (e.key == 'Escape' || e.keyCode === 27) {
        e.target.closest('.type-header')?.classList?.remove('bg-light-addTask');
        e.target?.classList?.remove('focus-input');
        e.target?.classList?.remove('active');
        e.target?.blur();
        e.target.value = '';
        e.target.closest('.type-header').querySelector('.repetions-block').style.
        display = 'none';
      }
    }
  };

  return (
    <>
      <div className={`type-header  ${onInputFocus ? 'bg-light-addTask' : ''}`}
      style={{ background: theme == "dark" ? "black" : "white" }}
      css={{
        '&.bg-light-addTask': {
          background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`
        }
      }}>
        <form onSubmit={(e) => {
          e.preventDefault();
          onAddTask();
        }}
        >
          <div className="filed-type" style={{ borderBottom: theme == "dark" ? "1px solid white" : "" }} css={{
            input: {
              color: theme == "dark" ? "white !important" : ""
            }
          }}>
            {
              isSettled ?
              <MentionsInput
            inputRef={titleRef}
            value={taskTitle}
            singleLine={true}
            onChange={(e, newVal, newPlain, mentions) => {
              setTaskTitle(newVal);
            }}
            placeholder="Add a Task..."
            onKeyPress={handleKeyPress}
            autoFocus={setFocus} 
            autoComplete="off"
            onFocus={() => { !setFocus && (currentPage == "upComing" ? addTaskOnupComingFocus(onDateChage) : addTaskOnFocus()) }}
            style={mentionStyle}
            customSuggestionsContainer={(children)=><div className='suggestions-list'>{children}</div>}
          >
            <Mention
              trigger="#~~~"
              markup="#[__display__]"
              data={suggestionTags}
              renderSuggestion={(suggestion) => {
                return (
                  <div className="w-40 px-2 py-1">{suggestion.display}</div>
                );
              }}
              className="tags-mention font-inter outline-none border-none"
              style={{
                backgroundColor: colorPicker,
                borderRadius: "4px"
              }}
              displayTransform={(id, display) => "#" + display}
            />
            {/* <Mention
              trigger="@"
              markup="@[__display__]"
              data={suggestionProjects}
              renderSuggestion={(suggestion) => {
                return (
                  <div className="w-40 px-2 py-1">{suggestion.display}</div>
                );
              }}
              className="project-mention  font-inter outline-none border-none"
              style={{
                backgroundColor: colorPicker,
                borderRadius: "4px"
              }}
              displayTransform={(id, display) => "@" + display}
            /> */}
              </MentionsInput>
              :
              <Skeleton width={100} height={20} />
            }
          
            {/* <input id='addTaskInputField' type="text" {...register("title")}
              css={{
                color: theme == "dark" ? "white !important" : ""
              }}
              // style={{color:theme == "dark" ? "white" : "gray"}}
              className={`add-task-input  ${inputFieldValue == false ? "" : "input-filled"} ${(theme == "dark") ? " add-task-placeholder" : ""} ${isActive ? 'active' : ''} ${addTaskFocus == true ? 'focus-input add-task-placeholderOnFocus' : ''}`}
              placeholder="Add a Task..." autoFocus={setFocus} onFocus={() => { !setFocus && (currentPage == "upComing" ? addTaskOnupComingFocus(onDateChage) : addTaskOnFocus()) }}
              // onBlur={(e)=>{onInputValueChange(e?.target?.value);onBlurUpcomingChange(groupDate)}}
              // onChange={(e)=>onInputValueChange(e?.target?.value)}
              onKeyDown={(e) => {
                if (e.ctrlKey == true && e.key == 'Enter') {
                  // onAddTask();
                }
                if (e.metaKey && e.key == 'Enter') {
                  onAddTask();
                }
              }}

              onKeyUp={(e) => {
                if ((e.key == 'Enter' && !e.ctrlKey && !e.metaKey) || e.key == 'Escape' || e.keyCode === 27) {
                  e.target.closest('.type-header')?.classList?.remove('bg-light-addTask');
                  e.target?.classList?.remove('focus-input');
                  e.target?.classList?.remove('active');
                  e.target?.blur();
                  e.target.value = '';
                  e.target.closest('.type-header').querySelector('.repetions-block').style.display = 'none';
                }
              }}

            /> */}

            <span className="add-sqc">
              {/* <img src="/images/plus-square.svg" alt='' /> */}
              <PlusIcon />
            </span>

            {/* <div className="press-option">
              <span className={`pr-content ${addTaskFocus ? "add-task-placeholderOnFocus" : ""}`} style={{ color: theme == "dark" ? "white" : "gray", opacity: 0.3 }}>Press Ctrl+Enter</span>
            </div> */}
          </div>

          <div ref={inputRef} className="repetions-block" id={currentPage == "upComing" ? groupDate : 'repotionsBlock'} style={{ display: setFocus ? "block" : "none" }}

          >
            <div className="task-repetions">
              <div className="add-repetions">

                <div className={`cs-select`}
                  css={{
                    color: theme == "dark" ? "white !important" : "white !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`
                    }}
                  onClick={() => onClickDateRangeSet('DatePicker')}>
                    <CalendarIcon color="gray" width={14} height={14} />
                    <span className="sl-ico darkPrimaryColor">
                      {calendarFormat(onDateChage)}
                    </span> 
                  <Datepicker onChange={(e) => { onDatePickerDateChange(e) }} value={currentPage == "upComing" ? onDateChage : dateChange} />
                </div>

                {/* <div className={`cs-select ${selectedDay === 'Today' ? 'activeDate' : ''} `}
                  css={{
                  color: theme == "dark" ? "white !important" : "black !important",
                  border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                  '&:hover': {
                    background: theme == "dark" && `${colorPicker} !important`
                  }
                }}
                style={{ backgroundColor: selectedDay == 'Today' ? colorPicker : '' }} 
                onClick={() => onClickDateRangeSet('Today')}>
                  <span className='st-span'
                    style={{ color: `${(selectedDay === 'Today' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (selectedDay === 'Today' && theme == "light") ? "black" : ""}` }}
                  > Today </span>
                </div>

                <div className={`cs-select ${selectedDay === 'Tomorrow' ? 'activeDate' : ''} `}
                  css={{
                    color: theme == "dark" ? "white !important" : "black !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    '&:hover': {
                      background: theme == "dark" && `${colorPicker} !important`
                    }
                  }}
                  style={{ backgroundColor: selectedDay == 'Tomorrow' ? colorPicker : '' }}
                  onClick={() => onClickDateRangeSet('Tomorrow')}>
                  <span className="st-span"
                    style={{ color: `${(selectedDay === 'Tomorrow' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (selectedDay === 'Tomorrow' && theme == "light") ? "black" : ""}` }}
                  > Tomorrow </span>
                </div>
                <div className={`cs-select ${selectedDay === 'Next Week' ? 'activeDate' : ''} `}
                  css={{
                    color: theme == "dark" ? "white !important" : "black !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    '&:hover': {
                      background: theme == "dark" && `${colorPicker} !important`
                    }
                  }}
                  style={{ backgroundColor: selectedDay == 'Next Week' ? colorPicker : '' }}
                  onClick={() => onClickDateRangeSet('Next Week')}>
                  <span className='st-span'
                    style={{ color: `${(selectedDay === 'Next Week' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (selectedDay === 'Next Week' && theme == "light") ? "black" : ""}` }}
                  > Next Week </span>
                </div>

                <div className={`cs-select ${selectedDay === 'No Date' ? 'activeDate' : ''} `}
                  css={{
                    color: theme == "dark" ? "white !important" : "black !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    '&:hover': {
                      background: theme == "dark" && `${colorPicker} !important`
                    }
                  }}
                  style={{ backgroundColor: selectedDay == 'No Date' ? colorPicker : '' }}
                  onClick={() => onClickDateRangeSet('No Date')}>
                  <span className='st-span'
                    style={{ color: `${(selectedDay === 'No Date' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (selectedDay === 'No Date' && theme == "light") ? "black" : ""}` }}
                  > No Date </span>
                </div> */}
                {
                  isValidDate(onDateChage) 
                  &&
                  <div ref={repeatOptionsRef} className={`cs-select recurring-options-dropdown ${selectedDay === 'Custom' ? 'activeDate' : ''} `}
                    css={{
                      color: theme == "dark" ? "white !important" : "black !important",
                      border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                      backgroundImage: theme == "dark" ? `url(/images/repeat-ico-white.svg)` : `url(/images/repeat-ico.svg)`,
                      backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`,
                      '&:hover': {
                        backgroundColor: theme == "dark" && `${colorPicker} !important`
                      }
                    }}>
                    <Select
                        styles={CustomStyle}
                        onChange={(value) => value.value === 100 ? setOpenCustomRepeat(true) : setSelectedRepeat(value.value)}
                        options={repeatListOptions.filter(option => option.label !== null)}
                        value={repeatListOptions.filter(option => option.value === selectedRepeat)[0]}
                        className="react-select repeated-task-select"
                        classNamePrefix={"repeating-task-setting" || ""}
                    />
                    {
                      openCustomRepeat
                      &&
                      <RepeatedTaskModal targetRef={repeatOptionsRef} onHide={(value = null) => setCustomRule(value)} task={{date: onDateChage || new Date()}} />
                    }
                  </div>
                }
              </div>
              <div className="repetions-action">
                <div className='repeTions'>
                  <button type="button" className="recAct-btn cancel-btn" css={{
                      color: theme == "dark" ? "rgba(255,255,255,0.5) !important" : "black !important",
                      backgroundColor: `${theme == "dark" ? "#000" : "#fff"} !important`,
                      border: '1px solid rgba(0, 0, 0, 0.03) !important'
                    }} onClick={() => { upComing == true ? onCancelUpComingTask(groupDate) : onCancelTask() }}>
                      Cancel
                  </button>
                  <div className="repeTions">
                    <button type='submit' className="recAct-btn save-btn" css={{
                      background: `${colorPicker} !important`,
                      border: `1px solid rgba(0,0,0,0.05) !important`,
                      color: "white !important"
                    }}>Add Task</button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddTask