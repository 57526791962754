/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { startTimer } from '../../redux/slices/timer';
import { focusTimerFormatV3, setFocusOnTimer } from "../action/utilities";
import { FocusMode } from '../LandingHeader/Feedback/icons/Icons';
import moment from "moment";
import { useSelector } from "react-redux";
import FocusModeSettingsModal from "../../pages/Settings/Modal/FocusModeSettingsModal";

const TimerOptions = ({timer, colorPicker, theme, defaultOption = 'focusModeSettings', onChange = () => {}}) => {
    const [option, setOption] = useState(defaultOption);
    const [todaySessions, setTodaySessions] = useState([]);
    const { focusSessions } = useSelector(state => state.focusTimer);
    const dispatch = useDispatch();
    const startBtn = useRef('');
    const navigate = useNavigate();
    const mounted = useRef(false);
    const [settingsModal, setSettingModal] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', startTimerOnEnterPress);

        setTimeout(() => {
            mounted.current = true;
        }, 100)

        return () => {
            document.removeEventListener('keydown', startTimerOnEnterPress);
        }
    }, []);

    useEffect(() => {
        onChange(option);
        if(mounted.current === true && option !== ''){
            setFocusOnTimer();
        }
    }, [option]);

    useEffect(() => {
        setTodaySessions(focusSessions.filter(session => {
            return moment().format('L') === moment(session.startTime).format('L')
        }));
        
    }, [focusSessions])

    const startTimerOnEnterPress = (e) => {
        if(e.target.className === 'timer-custom-time' && e.key == 'Enter' && startBtn?.current){
            startBtn.current.click();
        }
    }

    const startTimerHandler = (e) => {
        e.preventDefault();
        if(option == 'focusModeSettings'){
           // setSettingModal(true);
           navigate('/focus-mode-settings');
            return;
        }
        if(timer){
            dispatch(startTimer(option));
            option == 'break' && navigate("/break-timer-start");
        }
    }

    const calcFocusedTime = () => {
        let seconds = 0;
        let pauseSeconds = 0;
        todaySessions.map(session => {
            seconds += session.endTime - session.startTime;
            session?.pauses.map(pause => {
                pauseSeconds += pause.endTime - pause.startTime;
            });
        });

        return focusTimerFormatV3((seconds - pauseSeconds) / 1000, true);
    }

    return (
        <>
            <div className='focusTimerSt'>
                {
                    todaySessions.length > 0
                    ?
                    <div className="slectFocusOpt" style={{color: theme === 'dark' ? '#fff' : '#000'}}><span css={{
                        color: colorPicker,
                        opacity: '1 !important',
                        'span': {
                            opacity: '1 !important'
                        }
                    }} dangerouslySetInnerHTML={{__html: calcFocusedTime()}} /> focused today...</div>
                    :
                    <div className={`slectFocusOpt`} style={{gap: '10px', color: theme === 'dark' ? '#fff' : '#000'}}><FocusMode color={colorPicker} /><span>Focus</span></div>
                }
                
                <div className='focusStart'>
                    {
                        document.activeElement !== document.querySelector('.timer-custom-time')
                        ?
                        <button onClick={startTimerHandler} className='startEvnt' style={{color: 'grey', background: theme == "dark" ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10)), #000000' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #FFF'}}  css={
                            {
                                '&:hover': {background: `${colorPicker} !important`, color: `${theme == 'dark' ? '#000' : '#fff'} !important`},
                                '&:hover svg path': {
                                    stroke: theme == 'dark' ? "#000000" : '#ffffff'
                                }
                            }
                        }>{todaySessions.length > 0 ? 'New Session' : 'Start Session'}</button>
                        :
                        <button ref={startBtn} style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}} onClick={startTimerHandler} className='startEvnt'>{todaySessions.length > 0 ? 'New Session' : 'Start Session'}</button>
                    }
                </div>
            </div>
            {
                settingsModal
                &&
                <FocusModeSettingsModal show={true} onHide={e => setSettingModal(false)} />
            }
        </>
    )
}

export default TimerOptions