import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
  settings: {},
  settingsFetched: false,
  addQuickLinkClick:true,
  quotes: null,
}

const SettingSclice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state, action) {
      state.settings = action.payload ?? {}
      state.settingsFetched = true;
    },

    pushToSetting(state, action) {
      state.settings = { ...state.settings, ...action.payload }
    },
    setAddQuickLinkClick(state,action){
      state.addQuickLinkClick=action.payload;
      // state.settings = { ...state.settings, quickLinksVisit: true }
    },
    setQuickLinksVisit(state,action){
      state.addQuickLinkClick=true;
      state.settings = { ...state.settings, quickLinksVisit: true }
    },
    setQuotes(state, action) {
      state.quotes = action.payload
    },
  }
})


// reducers exports
export const {setSettings, setQuickLinksVisit , pushToSetting , setAddQuickLinkClick , setQuotes} = SettingSclice.actions;
export default SettingSclice.reducer;
