import React from 'react'
import { useSelector } from 'react-redux'

function TimerMenuIcon({activeTimerSetting , isMouseEnterSettingTimer}) {
  const {theme , colorPicker}=useSelector(state=>state.theme)

  const strokeColorCondition = ((theme == "dark" && activeTimerSetting ==  false )) ? "#4C4C4C" : "white"
  const onMouseEnterCondition= isMouseEnterSettingTimer && "black"
  const strCol = ((theme == "dark" && (colorPicker == "#27cb9a" || colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" )) && activeTimerSetting) ? "black" : ""
  const strCol1 = (isMouseEnterSettingTimer && activeTimerSetting) ? onMouseEnterCondition : strokeColorCondition
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ? 
        <path d="M8.3335 1.66667H11.6668" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> 
        : 
        <path d="M8.3335 1.66667H11.6668" stroke={strCol1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ? 
        <path d="M10 11.6667L12.5 9.16667" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> 
        : 
        <path d="M10 11.6667L12.5 9.16667" stroke={strCol1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ? 
        <path d="M10.0002 18.3333C13.6821 18.3333 16.6668 15.3486 16.6668 11.6667C16.6668 7.98477 13.6821 5 10.0002 5C6.31826 5 3.3335 7.98477 3.3335 11.6667C3.3335 15.3486 6.31826 18.3333 10.0002 18.3333Z" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        : 
        <path d="M10.0002 18.3333C13.6821 18.3333 16.6668 15.3486 16.6668 11.6667C16.6668 7.98477 13.6821 5 10.0002 5C6.31826 5 3.3335 7.98477 3.3335 11.6667C3.3335 15.3486 6.31826 18.3333 10.0002 18.3333Z" stroke={strCol1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
    </svg>
  )
}

export default TimerMenuIcon