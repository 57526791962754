import React, { createContext, useState, useEffect } from 'react';
import { authentication } from './Components/action/utilities';
import { BASE_URL, EXTENSION, OUTSETA_PLANS } from './Components/common/constants';
import { fetchSessions } from './Components/action/timer';
import { setFocusSessions } from './redux/slices/timer';
import { useDispatch } from 'react-redux';
import { subscriptionDetails } from './Components/action/common';
import moment from 'moment';
import { isExpired, decodeToken } from "react-jwt";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [subscription, setSubscription] = useState({})
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const dispatch = useDispatch();

    const validateToken = (token) => {
        const bearerToken = "Bearer " + token;
        const headers = new Headers();
        headers.append("Authorization", bearerToken);
        headers.append('Content-Type', 'application/json');

        return fetch(`${BASE_URL}user-profile?${new URLSearchParams({todayDate: new Date(moment().startOf('date')).toISOString()})}`, {
            method: 'GET',
            headers: headers,
            redirect: 'follow',
        });
    }
  
    useEffect(() => {
        const params = window?.location?.search ? new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        }) : {};

        const accessToken = params?.access_token ?? null;
        let auth = authentication();
        let isTokenValid = false;

        // Auth check in web app
        if(accessToken){
            validateToken(accessToken).then(response => response.json()).then(data => {
                // Don't proceed if redirect is triggered
                if(checkForRedirect(accessToken, data)){
                    return;
                }
                setAccessToken(accessToken);
                auth = true;
                isTokenValid = true;
                setIsAuthenticated(true);
                setLoading(false);
                // Trigger - Timer Tracker Auth Sync
                try{
                    window?.chrome.runtime.sendMessage(EXTENSION.ID.TIMETRACKER, { action: 'authUpdateTimerTracker', token: accessToken }, (response) => {
                        console.log('Timer Tracker Auth Sync - ', response);
                    });
                }catch(e){
                    console.log('Timer Tracker Auth Sync - FAILED: ', e)
                }
            }).catch(error => {
                setLoading(false);
            })
        }else{
            isTokenValid = true;
        }

        if(!isTokenValid)
            return;

        // Auth check in chrome extension
        if(window?.chrome?.storage){
            // Check web app cookies 
            window?.chrome.runtime.sendMessage({ action: 'getAuthToken' }, (response) => {
                if (response?.authToken) {
                    window.localStorage.setItem("token", response.authToken);
                    setIsAuthenticated(true);
                    window?.chrome.runtime.sendMessage({ action: 'getColor'});
                    // Use the token for authenticated requests
                } else {
                    clearStorage();
                }
                setLoading(false);
            });

            // Receive message for clearing storage of extension
            window?.chrome.runtime.onMessage.addListener((message, sender, sendResponse) => {
                if (message.action === "clearLocalStorge") {
                    clearStorage();
                    sendResponse({response: "Session is cleared"});
                }
            });
        }else{
            // Check auth in web app
            if(auth){
                setIsAuthenticated(true);
            }
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        // Fetch focus timer sessions and store in local storage
        if(isAuthenticated){
            const token = localStorage.getItem("token");
            const user = decodeToken(token);
            setUserProfile(user);
            // subscriptionDetails().then(data => {
            //     const daysLeft = Math.ceil((new Date(data?.renewalDate) - new Date()) / (1000 * 60 * 60 * 24));
            //     setSubscription({...data, daysLeft})
            // })
            fetchSessions().then(response => {
                dispatch(setFocusSessions(response?.data?.responseData?.sessions?.sessions ?? []));
            });
        }
    }, [isAuthenticated])

    const clearStorage = () => {
        window.chrome.storage.local.clear(function () {});
        localStorage.clear();
        dispatch({ type: "DESTROY_SESSION" });
        setIsAuthenticated(false);
    }

    const checkForRedirect = (token, data) => {
        let redirect = false;
        const user = decodeToken(token);

        // If user is new or in control group, redirect to control page and don't set access token
        if(data?.responseData?.isNewUser || user?.['outseta:planUid'] === OUTSETA_PLANS.CONTROL.PLANID){
            // If user is in experimental group, redirect to experimental page and set access token
            if(user?.['outseta:planUid'] === OUTSETA_PLANS.EXPERIMENTAL.PLANID){
                setAccessToken(token);
                window.location.href = OUTSETA_PLANS.EXPERIMENTAL.POST_SIGNUP_REDIRECT;
                redirect = true;
            }else{
                // If user is in control group, redirect to control page and don't set access token
                if(user?.['outseta:planUid'] === OUTSETA_PLANS.CONTROL.PLANID && moment(data?.responseData?.Created).add(6, 'day').isAfter(moment())){
                    window.location.href = OUTSETA_PLANS.CONTROL.POST_SIGNUP_REDIRECT;
                    redirect = true;
                }
            }
        }

        return redirect
    }

    const setAccessToken = (accessToken) => {
        // Set cookie with the token
        const date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = `authToken=${accessToken};${expires};`;
        localStorage.setItem("token", accessToken);
    }
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, subscription, setSubscription, userProfile }}>
        {!loading && children}
      </AuthContext.Provider>
    );
  };
  
  export { AuthContext, AuthProvider };
  