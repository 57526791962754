import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { exchangeTodoistAccessToken } from './action/common';
import { TODOIST } from './common/constants';

const TodoistAuth = () => {
    const { settings } = useSelector(state => state.settings)
    
    const queryParams = () => {
        const paramsObj = new URLSearchParams(window.location.search);
        let newObj = {};
        for (const [key, value] of paramsObj) {
            newObj[key] = value;
        }
        return newObj;
    }

    useEffect(() => {
        const {error, code, state} = queryParams();
        
        if(error || !code){
            window.close();
        }else{
            exchangeTodoistAccessToken(code, state).then(response => {
                window.close();
            }).catch(response => {
                window.close();
            });
        }
    }, [])

    return (
        <div style={{
            position: 'absolute',
            top: '100px',
            left: 0,
            right: 0,
            textAlign: 'center'
        }}><img style={{verticalAlign: 'bottom'}} src="/images/logos_todoist-icon.svg" alt=""/> Todoist is connecting ...</div>
    )
}

export default TodoistAuth