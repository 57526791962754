/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { createTask, updateTaskList } from '../../Components/action/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAllTaskList,
  updateGroupedList,
  updateNoDateTaskList,
  updateOverDueTaskList,
  updateSetTaskList,
  updateCompletedTaskList,
  pushGroupedList,
  pushOverDueTaskList,
  pushAllTaskList,
  pushNoDateTaskList,
  pushCompletedTaskList,
  pushSetTaskList
} from '../../redux/slices/TaskSlice'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { jsx } from "@emotion/core";
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import CalendarIcon from './CalendarIcon'
import { Mention, MentionsInput } from 'react-mentions'
import { parseMentionInput, format } from '../../Components/action/utilities'
import Select from 'react-select'
import RepeatedTaskModal from '../Settings/Modal/RepeatedTaskModal'
import { RRule, rrulestr } from 'rrule'
import momentTimezone from 'moment-timezone';
import ConfirmRecurringTask from '../Tasks/ConfirmRecurringTask'
import { SaveIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'

function EditTaskList({
  groupDate = "",
  setEditTaskId = (e) => {},
  currentPage,
  hideActions = false,
  setSelectedDate = (e) => {},
  item,
  setEditTaskListData = (e) => {},
  fetchAddTaskData,
  setUpdatedValue = (e) => {} }) {

  const dispatch = useDispatch()
  const { handleSubmit } = useForm()
  const { dateChange, taskDaySelected } = useSelector(state => state.task)
  const [titleValue, setTitle] = useState(item?.title)
  const [dateRange, setDateRange] = useState(new Date(item?.repeat?.rule ? (groupDate ? groupDate : dateChange) : item?.date))
  const [datePickerValue, setDatePickerValue] = useState(new Date())
  const [taskDate, setTaskDate] = useState(item?.repeat?.rule ? (groupDate ? groupDate : dateChange) : item?.date)
  const [editTaskSelected, setEditTaskSelected] = useState(taskDaySelected)
  const {  colorPicker , theme } = useSelector(state => state.theme)
  const dateTime = moment(dateRange).format('YYYY-MM-DD')
  const [autoFocus, setAutoFocus] = useState(false);
  const [selectedRepeat, setSelectedRepeat] = useState(item?.repeat?.value ?? 1);
  const [repeatListOptions, setRepeatListOptions] = useState([]);
  const [openCustomRepeat, setOpenCustomRepeat] = useState(false);
  const [editRecurringTask, setEditRecurringTask] = useState(false)
  const repeatOptionsRef = useRef(null);

// console.log('dateRangedateRange', dateRange, editTaskSelected)
  const initRepeatListOptions = useCallback(() => {
    const TaskDate = {
      from: dateRange || new Date()
    }
    const dtstart = momentTimezone(TaskDate?.from).tz(momentTimezone.tz.guess()).endOf('day').toDate()
    const selectRepeatList = [
      { value: 1, label: `Does not repeat`, rule: null },
      { value: 2, label: `Daily`, rule: (new RRule({dtstart, freq: RRule.DAILY})).toString() },
      { value: 3, label: `Weekly on ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, interval: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 4, label: `Monthly on the first ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: 1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 5, label: `Monthly on the last ${TaskDate?.from && format(TaskDate.from, "dddd")}`, rule: (new RRule({dtstart, freq: RRule.MONTHLY, interval: 1, bysetpos: -1, byweekday: RRule[format(TaskDate?.from, "dddd").toUpperCase().slice(0, 2)]})).toString() },
      { value: 6, label: `Annually on ${TaskDate?.from && format(TaskDate.from, "MMMM DD")}`, rule: (new RRule({dtstart, freq: RRule.YEARLY, interval: 1})).toString() },
      { value: 7, label: `Every weekday (Monday to Friday)`, rule: (new RRule({dtstart, freq: RRule.WEEKLY, byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]})).toString() },
      { value: 99, label: null, rule: null },
      { value: 100, label: `Custom...`, rule: null }
    ];

    setSelectedRepeat(item?.repeat?.value ?? 1)
    setRepeatListOptions(selectRepeatList.map(option => option.value == item?.repeat?.value ? item.repeat : option))
  }, [dateRange]);

  const onAddTaskUpdateHandler = () => {
    if(item?.repeat?.rule){
      // confirmAlert({
      //   customUI: ({onClose}) => <ConfirmRecurringTask title="Edit repeating task" yesButtonText="Edit task" onClose={onClose} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onAddTaskUpdate(item, recurring)} />
      // });
      setEditRecurringTask(true)
    }else{
      onAddTaskUpdate(item);
    }
  }

  const onAddTaskUpdate = async (item, recurring = null) => {
    setEditRecurringTask(false)
    setEditTaskId(null);
    let date = new Date(moment().startOf('date')).toISOString()
    if (editTaskSelected === 'Today') {
      date = new Date(moment().startOf('date')).toISOString()
    } else if (editTaskSelected === 'Tomorrow') {
      date = moment(date).add(1, 'days').toISOString()
    } else if (editTaskSelected === 'Next Week') {
      date = moment(date).add(7, 'days').toISOString()
    } else if (editTaskSelected === 'No Date') {
      date = null
    }else{
      date = new Date(moment(dateRange).startOf('date')).toISOString()
    }

    const {tags, project, title} = parseMentionInput(titleValue ?? item?.title);

    let taskData = {
      "id": item?.id,
      "title": item.title,
      "tags": item?.tags,
      "project": item?.project,
      "repeat": item?.repeat ?? null,
      "date": item?.date ?? null,
      "orgRepeatId": item?.orgRepeatId ? item?.orgRepeatId : (item?.repeat?.rule ? item?.id : null)
    };

    let requestBody = {
      "id": item?.id,
      "title": title,
      "tags": tags,
      "project": project,
      "repeat": repeatListOptions.filter(item => item.value === selectedRepeat)?.[0] ?? null,
      "date": date,
      "orgRepeatId": item?.orgRepeatId ? item?.orgRepeatId : (item?.repeat?.rule ? item?.id : null)
    };

    // if (updatedTitle) {
    try {
      let repeat = null;
      const editedDate = dateRange;
      if(recurring !== null && recurring !== 3){
        const rruleSet = rrulestr(item?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to edit the current occurrence only
        if(recurring == 1){
          rruleSet.exdate(momentTimezone(editedDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        }else{
          
          if(recurring == 2){
            // If this is a recurring task and want to edit the current occurrence and all future occurrences of the task
            const rrule = new RRule({...(RRule.parseString(rruleSet._rrule.toString())), until: momentTimezone(editedDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate()});
            rruleSet._rrule[0] = rrule;
            rruleSet.rrule(rrule);
          }
        }

        repeat = {...item?.repeat, rule: rruleSet.toString()};
      }else{
        repeat = requestBody.repeat;
        // If task is set to recurring and orgRepeatId is not set, then set it to the current id
        if(repeat?.rule && !requestBody?.orgRepeatId){
          requestBody.orgRepeatId = item?.id;
        }
        taskData = {...requestBody};
      }

      await updateTaskList({...taskData, repeat})
      delete taskData.project;
      delete taskData.tags;
      taskData = {...taskData, repeat, Project: project ? {title: project} : null, Tags: tags ? tags.map((tag, index) => ({name: tag, id: index+1})) : []};
      switch (currentPage) {
        case 'upComing':
          dispatch(updateGroupedList(taskData))
          break;
        case 'noDate':
          dispatch(updateNoDateTaskList(taskData))
          break;
        case 'completedList':
          dispatch(updateCompletedTaskList(taskData))
          break;
        case 'allTask':
          dispatch(updateAllTaskList(taskData))
          break;
        case 'overDue':
          dispatch(updateOverDueTaskList(taskData))
          fetchAddTaskData()
          break;
        default:
          dispatch(updateSetTaskList(taskData));

      }
      setUpdatedValue(taskData);

      if(recurring !== null && recurring !== 3){
        delete requestBody.id;
        if(requestBody?.repeat?.rule){
          const rruleSet = rrulestr(requestBody?.repeat?.rule, {
            forceset: true,
          })
          let rruleOptions = {...(RRule.parseString(rruleSet._rrule.toString())), dtstart: momentTimezone(requestBody.date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
          if(recurring == 1){
            rruleOptions = {...rruleOptions, until: momentTimezone(requestBody.date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
          }
          const rrule = new RRule(rruleOptions);
          rruleSet._rrule[0] = rrule;
          rruleSet.rrule(rrule);

          requestBody.repeat = {...requestBody?.repeat, rule: rruleSet.toString()};
        }

        let response = await createTask(requestBody)
        if(response?.data?.responseData?.data){
          response.data.responseData.data.Project = project ? {title: project} : null
          response.data.responseData.data.Tags = tags ? tags.map((tag, index) => ({name: tag, id: index+1})) : [];
        }
        switch (currentPage) {
          case 'upComing':
            dispatch(pushGroupedList(response?.data?.responseData?.data))
            break;

          case 'overDue':
            dispatch(pushOverDueTaskList(response?.data?.responseData?.data))
            break;

          case 'allTask':
            dispatch(pushAllTaskList(response?.data?.responseData?.data))
            break;

          case 'noDate':
            dispatch(pushNoDateTaskList(response?.data?.responseData?.data))
            break;
          case 'completedList':
            dispatch(pushCompletedTaskList(response?.data?.responseData?.data))
            break;
          default:
            dispatch(pushSetTaskList(response?.data?.responseData?.data));
        }
      }
      setEditTaskSelected('DatePicker')
      setEditTaskListData(true);
      setSelectedDate(dateTime)
      // if (dateTime == selectedDate) {
      //   fetchAddTaskData()
      // }
    } catch (e) {
      console.log('ERROR', e)
    }
    // }
  }

  const onClickDateRangeSet = (day) => {
    setEditTaskSelected(day)
  }

  useEffect(() => {
    let currentTime = moment().format('MM/DD')
    if (item) {
      let title = item.title;
      if (item.Project) {
        title += ` @[${item.Project.title}]`;
      }
      if (item.Tags) {
        item.Tags.forEach((tag) => {
          title += ` #[${tag.name}]`;
        });
      }

      setTitle(title);
    }
    if (currentPage == "overDue") {
      setEditTaskSelected("DatePicker")
    } else {
      if (moment(dateRange).format('MM/DD/YYYY') == moment().format('MM/DD/YYYY')) {
        setEditTaskSelected("Today")
      } else if (moment().add(1, 'days').format('MM/DD/YYYY') == moment(dateRange).format('MM/DD/YYYY')) {
        setEditTaskSelected("Tomorrow")
      } else if (moment().add(7, 'days').format('MM/DD/YYYY') == moment(dateRange).add(7, 'days').format('MM/DD/YYYY')) {
        setEditTaskSelected("Next Week")
      } else {
        setEditTaskSelected("DatePicker")
      }
    }

    setTimeout(() => {
      setAutoFocus(true)
    }, 1)
  }, [])

  function getMentionTags() {
    const items = [].map((t) => {
      return {
        id: t.name,
        display: t.name,
      };
    });
    const regex = /#(\w+)/g;

    let match;

    while ((match = regex.exec(titleValue))) {
      const isAlready = items.find((i) => i.display === match[1]);
      if (!isAlready) {
        items.push({
          id: null,
          display: match[1],
        });
      }
    }
    return items;
  }

  function getProjectMentions() {
    const items = [].map((t) => {
      return {
        id: t.title,
        display: t.title,
      };
    });
    const regex = /@(\w+)/g;

    let match;

    while ((match = regex.exec(titleValue))) {
      const isAlready = items.find((i) => i.display === match[1]);
      if (!isAlready) {
        items.push({
          id: null,
          display: match[1],
        });
      }
    }
    return items;
  }

  const suggestionTags = getMentionTags();
  const suggestionProjects = getProjectMentions();

  const mentionStyle = {
    input:{
      color: theme == 'dark' ? 'white' : 'black'
    },
    control: {
      fontSize: 16,
    },
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: colorPicker,
          color: "white",
        },
      },
    },
  };

  useEffect(() => {
    initRepeatListOptions();
  }, [dateRange])

  const CustomStyle = {
    singleValue: (base, state) => {
      return {
        ...base,
        background: theme !== 'dark' ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}` : `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker}`,
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text !important'
      }
    },
    menu: (base, state) => {
      return {
        ...base,
        background: theme === 'dark' ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #000' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF'
      }
    },
   option: (base, state) => {
      let background = theme !== 'dark' ? 'black' : '#fff';
      let color = `${theme !== 'dark' ? 'black' : '#fff'} !important`;
      let backgroundImage = 'none';
      let backgroundPosition = '0 0';
      // const isLastOption = state.options.indexOf(state.data) === state.options.length - 1;

      // if (isLastOption) {
      //   background = `linear-gradient(0deg,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),${colorPicker} !important`;
      // }

      if (state.isFocused) {
        background = theme === 'dark' ? `linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9)), ${colorPicker} !important` : `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)), ${colorPicker} !important`;
        color = `${theme === 'dark' ? '#000' : '#fff'} !important`;
      }

      if (state.isSelected) {
        color = "white !important";
        background = `black url('data:image/svg+xml;charset=UTF-8,%3Csvg width%3D%2214%22 height%3D%2210%22 viewBox%3D%220 0 14 10%22 fill%3D%22none%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath d%3D%22M13 1L4.75 9.25L1 5.5%22 stroke%3D%22${colorPicker.replace(/^#/, '%23')}%22 stroke-width%3D%221.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22/%3E%3C/svg%3E') no-repeat !important`;
        backgroundPosition = 'right 20px center !important';
      }

      return {
        ...base,
        color,
        background,
        backgroundImage,
        backgroundPosition
      };
    }
  }

  const setCustomRule = (value) => {
    if(value?.rule){
      setSelectedRepeat(99);
      setRepeatListOptions(options => options.map(option => option.value === 99 ? {...option, ...value} : option));
    }else{
      setOpenCustomRepeat(value);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey) {
      e.preventDefault(); // Prevent default form submission
      onAddTaskUpdateHandler();
    }
  };

  return (
    <li className="li-task-list edit-list" css={{
      background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`
    }}>
      <form onSubmit={(e) => {
          e.preventDefault();
          onAddTaskUpdateHandler();
      }}>
        <div className="filed-type"  style={{ borderBottom: theme == "dark" ? "1px solid white" : "" }}>
          {
            autoFocus && 
            <MentionsInput
                id='editTaskInputField'
                value={titleValue}
                singleLine={true}
                onChange={(e, newVal, newPlain, mentions) => {
                  setTitle(newVal);
                }}
                placeholder="Add a Task..."
                className="add-task-input focus-input"
                onKeyPress={handleKeyPress}
                autoFocus={autoFocus} 
                autoComplete="off"
                style={mentionStyle}
                customSuggestionsContainer={(children)=><div className='suggestions-list'>{children}</div>}
              >
                <Mention
                  trigger="#~~~"
                  markup="#[__display__]"
                  data={suggestionTags}
                  renderSuggestion={(suggestion) => {
                    return (
                      <div className="w-40 px-2 py-1">{suggestion.display}</div>
                    );
                  }}
                  className="tags-mention font-inter outline-none border-none"
                  style={{
                    backgroundColor: colorPicker,
                    borderRadius: "4px"
                  }}
                  displayTransform={(id, display) => "#" + display}
                />
                {/* <Mention
                  trigger="@"
                  markup="@[__display__]"
                  data={suggestionProjects}
                  renderSuggestion={(suggestion) => {
                    return (
                      <div className="w-40 px-2 py-1">{suggestion.display}</div>
                    );
                  }}
                  className="project-mention  font-inter outline-none border-none"
                  style={{
                    backgroundColor: colorPicker,
                    borderRadius: "4px"
                  }}
                  displayTransform={(id, display) => "@" + display}
                /> */}
              </MentionsInput>
          }
          {/* {autoFocus && <input type="text" autoFocus={autoFocus} name="title" className="add-task-input focus-input" defaultValue={titleValue}
            css={{
              color: theme == "dark" ? "white !important" :""
            }}
            onKeyDown={(e) => {
              if ((e.ctrlKey == true || e.metaKey) && e.key == 'Enter') {
                onAddTaskUpdate();
                dispatch(setActiveTask(null))
              }
            }}

            onChange={(e) => {
              setUpdatedTitle(e?.target?.value); setTitle(e?.target?.value)

            }} />} */}
        </div>
        <div className='repetions-block' style={{ display: "inline-block" }}>
          <div className="task-repetions">
            {
              hideActions === false
              &&
              <div className="add-repetions">
                <div className={`cs-select`}
                  css={{
                    color: theme == "dark" ? "white !important" : "white !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`
                  }}
                  onClick={() => onClickDateRangeSet('DatePicker')}>
                    <CalendarIcon color="gray" width={14} height={14} />
                    <span className="sl-ico darkPrimaryColor">
                        {
                          editTaskSelected == "DatePicker" ?  moment(taskDate).format('D MMM YYYY') : editTaskSelected
                        }
                    </span>
                  <Datepicker
                    onChange={(date) => { setDateRange(date); setDatePickerValue(date); setTaskDate(date) }}
                    value={currentPage == "overDue" ? new Date(item?.date) : dateRange}
                  />
                  {/* <DateTimePicker
                    onChange={(date) => { setDateRange(date); setDatePickerValue(date); setTaskDate(date) }}
                    value={currentPage == "overDue" ? new Date(item?.date) : dateRange}
                  /> */}
                </div>



                {/* <div className={`cs-select st-span ${editTaskSelected === 'Today' ? 'activeDate' : ''} `}
                css={{
                  color: theme == "dark" ? "white !important" : "black !important",
                  border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                  '&:hover': {
                    background: theme == "dark" && `${colorPicker} !important`
                  }
                }}
                  style={{ backgroundColor: editTaskSelected == 'Today' ? colorPicker : '', }}
                  onClick={() => onClickDateRangeSet('Today')}>
                  <span className='st-span'
                  //  style={{ color: `${editTaskSelected === 'Today' ? "white" : 'black'}` }}
                  style={{ color: `${(editTaskSelected === 'Today' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (editTaskSelected === 'Today' && theme == "light") ? "black" : ""}` }}
                  >  Today</span>
                </div>
                <div className={`cs-select st-span ${editTaskSelected === 'Tomorrow' ? 'activeDate' : ''} `}
                css={{
                  color: theme == "dark" ? "white !important" : "black !important",
                  border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                  '&:hover': {
                    background: theme == "dark" && `${colorPicker} !important`
                  }
                }}
                  style={{ backgroundColor: editTaskSelected == 'Tomorrow' ? colorPicker : ''}}
                  onClick={() => onClickDateRangeSet('Tomorrow')}>
                  <span className='st-span'
                  style={{ color: `${(editTaskSelected === 'Tomorrow' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (editTaskSelected === 'Tomorrow' && theme == "light") ? "black" : ""}` }}
                  >Tomorrow</span>
                </div>
                <div className={`cs-select st-span ${editTaskSelected === 'Next Week' ? 'activeDate' : ''} `}
                css={{
                  color: theme == "dark" ? "white !important" : "black !important",
                  border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                  '&:hover': {
                    background: theme == "dark" && `${colorPicker} !important`
                  }
                }}
                  style={{ backgroundColor: editTaskSelected == 'Next Week' ? colorPicker : '' }}
                  onClick={() => onClickDateRangeSet('Next Week')}>
                  <span className='st-span' 
                  style={{ color: `${(editTaskSelected === 'Next Week' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (editTaskSelected === 'Next Week' && theme == "light") ? "black" : ""}` }}

                  >Next Week</span>
                </div>
                <div className={`cs-select st-span ${editTaskSelected === 'No Date' ? 'activeDate' : ''} `}
                css={{
                  color: theme == "dark" ? "white !important" : "black !important",
                  border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                  '&:hover': {
                    background: theme == "dark" && `${colorPicker} !important`
                  }
                }}
                  style={{ backgroundColor: editTaskSelected == 'No Date' ? colorPicker : '' }}
                  onClick={() => onClickDateRangeSet('No Date')}>
                  <span className='st-span' 
                  style={{ color: `${(editTaskSelected === 'No Date' && theme == "dark") ? 'black' : theme == "dark" ? "white" : (editTaskSelected === 'No Date' && theme == "light") ? "black" : ""}` }}
                  >No Date</span>
                </div> */}

                <div
                  ref={repeatOptionsRef}
                  className='cs-select recurring-options-dropdown'
                  css={{
                    color: theme == "dark" ? "white !important" : "black !important",
                    border: theme == "dark" ? "1px solid rgba(255, 255, 255, 0.2) !important" : "1px solid black important",
                    backgroundImage: theme == "dark" ? `url(/images/repeat-ico-white.svg)` : `url(/images/repeat-ico.svg)`,
                    backgroundColor: `${theme == 'dark' ? '#000' : '#fff'} !important`,
                    '&:hover': {
                      backgroundColor: theme == "dark" && `${colorPicker} !important`
                    }
                  }}>
                  <Select
                      styles={CustomStyle}
                      onChange={(value) => value.value === 100 ? setOpenCustomRepeat(true) : setSelectedRepeat(value.value)}
                      options={repeatListOptions.filter(option => option.label !== null)}
                      value={repeatListOptions.filter(option => option.value === selectedRepeat)[0]}
                      className="react-select repeated-task-select"
                      classNamePrefix={"repeating-task-setting" || ""}
                  />
                  {
                    openCustomRepeat
                    &&
                    <RepeatedTaskModal targetRef={repeatOptionsRef} onHide={(value = null) => setCustomRule(value)} task={{...item, date: dateRange || new Date()}} />
                  }
                </div>
              </div>
            }
            
            <div className="repetions-action">
              <div className="repeTions">
                <button className="recAct-btn cancel-btn"
                css={{
                  color: theme == "dark" ? "rgba(255,255,255,0.5) !important" : "black !important",
                  backgroundColor: `${theme == "dark" ? "#000" : "#fff"} !important`,
                  border: '1px solid rgba(0, 0, 0, 0.03) !important'
                }}
                onClick={() => { setEditTaskId(null) }}>Cancel</button>
                <div style={{position: 'relative'}}>
                  <button type='submit' className="recAct-btn save-btn" css={{
                    background: `${colorPicker} !important`,
                    border: `1px solid rgba(0,0,0,0.05) !important`,
                    color: "white !important"
                  }}>Save</button>
                  {
                    editRecurringTask && <ConfirmRecurringTask iconComponent={<SaveIcon color="grey" />} onHide={() => setEditRecurringTask(false)} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onAddTaskUpdate(item, recurring)} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </li>
  )
}

export default EditTaskList