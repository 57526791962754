import React from 'react'
import { RepeatIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
import Tooltip from '../../Components/common/Tooltip'
import { calendarFormat } from '../../Components/action/utilities'

const RecurringIcon = ({task, colorPicker, overdue = false}) => {
  const color = overdue ? '#D95555' : colorPicker
  const textClassName = overdue ? 'darkOverDueColor' : 'darkPrimaryColor'

  return (
    (task?.repeat?.rule || task?.orgRepeatId) && 
    <Tooltip wrapperClassName="repeat-icon-span" className='overDue-tooltip tooltip-mt0' text={task?.repeat?.label ?? calendarFormat(task?.date)} color={color} textClassName={textClassName}>
        <a className="hovr-btn tooltip-hover">
            <span className='repeat-icon'>
                <RepeatIcon color={color} />
            </span>
        </a>
    </Tooltip>
  )
}

export default RecurringIcon