/** @jsxRuntime classic */
/** @jsx jsx */
import { useMonth } from "@datepicker-react/hooks";
import { jsx } from "@emotion/core";
import Day from "./Day";
import NavButton from "./NavButton";
import prev from '../assets/prv.svg';
import nxt from '../assets/nxt.svg';
import TodayMenu from "../../SideBar/TodayMenu";
import { useSelector } from "react-redux";
// import crcl from '../assets/crcl.svg';

function Month({ year, month, firstDayOfWeek, goToPreviousMonths, goToNextMonths, gotoToday }) {
  const {colorPicker} = useSelector(state=>state.theme)
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek
  });

  return (
    <div>
      <div css={{ 
        textAlign: "center", 
        margin: "0 0 16px" ,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <strong css={{
          fontFamily: 'Karla',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          display: 'flex',
          alignTtems: 'center',
          textTransform: 'uppercase',
          color: 'rgba(255, 255, 255, 0.5)'
        }}>{monthLabel}</strong>
        <div className="buttonAction">
          <NavButton onClick={goToPreviousMonths}><img src={prev} /></NavButton>
          <span style={{cursor: "pointer"}} className="crcl datepicker-month-nav" onClick={gotoToday}><TodayMenu activeStyleHome={true} color={month == new Date().getMonth() && year == new Date().getFullYear() ? 'grey' : colorPicker} /></span>
          <NavButton onClick={goToNextMonths}><img src={nxt} /></NavButton>
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          marginBottom: "10px",
          fontSize: "10px"
        }}
      >
        {weekdayLabels.map(dayLabel => (
          <div css={{ textAlign: "center", fontSize: "12px",
          color: 'rgba(255, 255, 255, 0.5)' }} key={dayLabel}>
            {dayLabel.toUpperCase()}
          </div>
        ))}
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center"
        }}
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
}

export default Month;
