/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react";
import { useDatepicker, START_DATE, END_DATE } from "@datepicker-react/hooks";
import { jsx } from "@emotion/core";
import Month from "./Month";
import NavButton from "./NavButton";
import DatepickerContext from "./datepickerContext";
import moment from "moment";
import '../styles.css';
import { useSelector } from "react-redux";

import check from '../assets/check.svg';
import Check from "./Check";
import { setTaskDaySelected } from "../../../redux/slices/TaskSlice";
import { useDispatch } from 'react-redux'

function  Datepicker({ value, onChange, showTopDates = true, removeOnChange = true }) {
  const dispatch = useDispatch()
  const datePickerRef = useRef('');
  //console.log('valuevaluevaluevalue', value)
  const [state, setState] = useState({
    startDate: value ? new Date(moment(value).toISOString()) : new Date(),
    endDate: value ? new Date(moment(value).toISOString()) : new Date(),
    focusedInput: START_DATE
  });

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    numberOfMonths: 1,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange
  });

  useEffect(() => {
    setState({ startDate: new Date(moment(value).toISOString()), endDate: new Date(moment(value).toISOString()), focusedInput: END_DATE });
  }, [value])

  function handleDateChange(data) {
    const date = new Date(moment(data.endDate ?? data.startDate).toISOString());
    if (!data.focusedInput) {
      setState({ startDate: date, endDate: date, focusedInput: START_DATE });
    } else {
      setState({ startDate: date, endDate: date, focusedInput: END_DATE });
    }

    goToDate(date)
    onChange && onChange(date);
    removeOnChange === true && datePickerRef.current.classList.remove('active');
  }

  const selectDate = (e, date) => {
    e.preventDefault();
    if (date !== null) {
      handleDateChange({ startDate: new Date(date.toISOString()), endDate: new Date(date.toISOString()), focusedInput: START_DATE })
    }
  }

  const renderDate = (day, index) => {
    let dayName = '';
    switch (day) {
      case 0:
        dayName = 'Today';
        // dispatch(setTaskDaySelected('Today'))
        break;
      case 1:
        dayName = 'Tomorrow';
        break;
      case 7:
        dayName = 'Next Week';
        break;
      default:
        dayName = '';
    }
    const date = day !== null ? moment().add(day, 'days') : null;

    return day !== null ? <div key={index} className="row headerRow"><div className="col-12 colmFull" onClick={e => selectDate(e, date)}><div className="col-6 colmLeft"><a href="#" className="clTtile">{dayName}</a></div><div className="col-6 colmRight"><span className="clDate">{date.format('ddd')}</span> <span className="clMonth">{date.format('DD MMMM')}</span>{moment(state.startDate).format('MM/DD/YYYY') === date.format('MM/DD/YYYY') ? <span className="activeDate"> <Check /> </span> : null}</div></div></div> : <div key={index} className="row headerRow"><div className="col-12 colmFull" onClick={e => onChange(null)}><div className="col-6 colmLeft"><a href="javascript: void(0);" className="clTtile">No Date</a></div><div className="col-6 colmRight">-</div></div></div>
  }

  const openDatePickerOnHover = (e) => {
    (e.target.closest('.hover-action') || e.target.closest('.days')) && openDatePicker(e);

    // Set calendar to todays
    if (e.target.closest('.days')) {
      goToDate(state.startDate);
    }
  }

  const hideDatePickerOnHoverOut = (e) => {
    (e.target.closest('.hover-action') || e.target.closest('.days')) && datePickerRef.current.classList.remove('active');
  }

  const openDatePicker = (e) => {
    e.preventDefault();
    document.querySelectorAll('.advancedDatePicker.active')?.forEach(el => {
      el.classList.remove('active');
    });

    // Toggle picker position in view port
    datePickerRef.current.classList.add('active');
    const dimPicker = datePickerRef.current.getBoundingClientRect();
    const dimBtn = e.target.getBoundingClientRect();

    //auto 0 auto auto
    if((window.innerHeight - dimBtn.top) > dimPicker.height){
      datePickerRef.current.style.inset = !e.target.closest('.hover-action') ? '100% auto auto 0px' : '100% 0 auto auto';
    } else {
      datePickerRef.current.style.inset = !e.target.closest('.hover-action') ? 'auto auto 100% 0px' : 'auto 0 100% auto';
    }
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover
      }}
    >
      <div onMouseLeave={hideDatePickerOnHoverOut} css={{
            display: 'inline-block',
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%'
      }}>
        <input type="button" className="datePickerButton" onMouseEnter={openDatePickerOnHover} onClick={openDatePicker} value={state ? state?.endDate : ''} />
        <div className="advancedDatePicker"  ref={datePickerRef} css={{
          position: "absolute",
          zIndex: "9999",
          background: "#000",
          color: '#fff',
          padding: '10px'
        }}>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
              gridGap: "0 64px",
              gridTemplateColumns: 'repeat(1, 330px)'
            }}
          >
            <div>
              <h3 css={{ marginTop: '10px', marginBottom: '25px', fontZize: '16px', lineHeight: '19px', fontFamily: 'Karla' }}>{moment(state.startDate).format('ddd, DD MMM YYYY')}</h3>
              {
                showTopDates === true && [0, 1, 7, null].map((day, index) => renderDate(day, index))
              }
            </div>
            {activeMonths.map((month) => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
                gotoToday={e => goToDate(new Date())}
                goToPreviousMonths={goToPreviousMonths}
                goToNextMonths={goToNextMonths}
              />
            ))}
          </div>
        </div>
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
