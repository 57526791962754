/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash"
import {useSelector} from 'react-redux'

function AllLoader({ loop, customClassName = '' }) {
  const {theme} =useSelector(state=>state.theme)
  return (
    <>
      {_.times(loop !== undefined ? loop : 1, (key) => {
        return (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className={customClassName}>
              <form className="overdue-form common-form" style={{background: theme == "dark" ? "black" :""}}>
                <div className="type-header" style={{background: theme == "dark" ? "black" :""}}>
                  <div className="filed-type" style={{ border: "none" }}>
                    <span className="add-sqc">
                      <Skeleton height={"20px"} width="20px" />
                    </span>
                    <span style={{ marginLeft: '33px' }}> <Skeleton height={"20px"} width="300px" /></span>

                  </div>
                  <span style={{ marginTop: "2px" }}><Skeleton height={"2px"} width="100%" /></span>
                </div>
                <div className="task-list">
                  <div className="task-list-data">
                    <ul className="ul-task-list">
                      <li className="li-task-listLoader">

                        <div className="task-sections" css={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "right",
                          alignItems: "start",
                          gap: "20px",
                        }}>
                          <label css={{
                            marginTop: "0",
                            display: "flex",
                            gap: "20px",
                          }}>
                            <span> <Skeleton height={"20px"} width="20px" className='trashLoader'/></span>
                            <Skeleton height={"20px"} width="300px" />
                          </label>
                          <label css={{
                            marginTop: "0",
                            display: "flex",
                            gap: "20px",
                          }}>
                            <span> <Skeleton height={"20px"} width="20px" className='trashLoader' /></span>
                            <Skeleton height={"20px"} width="300px" />
                          </label>
                          <label css={{
                            marginTop: "0",
                            display: "flex",
                            gap: "20px",
                          }}>
                            <span> <Skeleton height={"20px"} width="20px" className='trashLoader' /></span>
                            <Skeleton height={"20px"} width="300px" />
                          </label>
                        </div>  
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </SkeletonTheme>
        );
      })}
    </>
  )
}

export default AllLoader