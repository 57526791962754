import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import _ from 'lodash';
import moment from 'moment'
import TaskListDetails from './TaskListDetails'
import { dateToFromNowDaily, hexToRgb, verifyRepeatedTask } from '../../Components/action/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupedList, setTotalCount } from '../../redux/slices/TaskSlice';
import InfiniteScroll from "react-infinite-scroll-component";
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader';
import { Accordion } from 'react-accessible-accordion';
import AllLoader from '../../Components/SkeletonLoading/AllLoader';


function Upcoming() {
  // use hooks
  const dispatch = useDispatch();
  const [alldays, setAllDays] = useState([]);
  const [groupTaskList, setGroupTaskList] = useState({})
  const [todayTaskList, setTodayTaskList] = useState(false)
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));

  // global states
  const { taskGroupedList } = useSelector(state => state?.task);
  const { colorPicker , theme} = useSelector(state => state?.theme);


  const fetchTaskListData = useCallback(async () => {
  
    let requestBody = {
      "date": currentTime.toISOString(),
      // "status": 0,
      "type": 3
    }

    try {
      let response = await fetchTaskList(requestBody)
      dispatch(setGroupedList(response?.data?.responseData));
      setTodayTaskList(true)
    } catch ({ response }) {}
    
  }, [currentTime, dispatch]);

  useEffect(() => {
    let days = [];
    for(let index=0; index<10; index++){
      days.push(moment().add(index, 'days').format('MM/DD/YYYY'));
    }

    setAllDays(days);
  }, [])

  useEffect(() => {
    fetchTaskListData()
  }, [fetchTaskListData]);

  useEffect(() => {

    let groupTaskListData = _.groupBy(taskGroupedList, (task) => {
      return moment(task?.date).format('L')
    });
    setGroupTaskList(groupTaskListData)
  }, [taskGroupedList])

  const fetchMoreData = () => {
    console.log('fetchMoreData')
    setAllDays(alldays => {
      let days = [];
      const lastDay = alldays[alldays.length - 1];
      for(let index=1; index<=10; index++){
        days.push(moment(lastDay, 'MM/DD/YYYY').add(index, 'days').format('MM/DD/YYYY'));
      }

      return [...alldays, ...days];
    })
  };
  
  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
    
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {}
  }

  useEffect(()=>{
    fetchTotalCount()
  },[])

  const toggleAccordionArrow = (items) => {
    document.querySelectorAll('.accordion_arrow.active')?.forEach(elem => elem?.classList.remove('active'));
    if(items.length > 0){
      items.map(item => document.querySelector(`.${item}`)?.querySelector('.accordion_arrow')?.classList.add('active'))
    }
  }

  return (
    <div id="tasks-list-wrapper" style={{boxShadow: `0px 4px 250px ${hexToRgb(colorPicker)}`}}>
      <div className="upcoming-form"  style={{background: theme == "dark" ? "black" :"white"}}>
        <div className="accordion-view">
          <InfiniteScroll
            dataLength={alldays?.length ?? 0}
            next={fetchMoreData}
            hasMore={true}
            loader={<AllLoader  />}
            scrollableTarget="tasks-list-wrapper"
          >
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['accitem0']} onChange={toggleAccordionArrow}>
            {
              alldays?.map((groupDate, index) => {
                return (
                  <TaskListDetails
                    key={groupDate}
                    currentPage="upComing"
                    fetchTaskListData={fetchTaskListData}
                    todayTaskList={todayTaskList}
                    title={dateToFromNowDaily(groupDate, colorPicker , theme)}
                    upComingtaskList={taskGroupedList?.filter(t => verifyRepeatedTask(t, groupDate, moment(t?.date).format('L') === groupDate))}
                    groupDate={groupDate}
                    showAddTask={true}
                    index={index}
                    isDefaultActive = {index === 0}
                    setTodayTaskList={setTodayTaskList}
                    fetchTotalCount={fetchTotalCount}
                  />
                )
              })
            }
            </Accordion>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Upcoming