/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import FocusTimerBar from "./FocusTimerBar";
import moment from "moment";
import BackgroundImageTeacup from "../common/BackgroundImageTeacup";
import { isExpired, decodeToken } from "react-jwt";
import { useSocket } from "../../SocketContext";

function BreakTimerStart() {
  const { colorPicker, theme } = useSelector(state => state.theme);
  const {focusTasksList} = useSelector(state => state.task);
  const focusTimer = useSelector(state => state.focusTimer);
  const {focusSessions} = focusTimer;
  const socket = useSocket();

  // useEffect(() => {
  //   // Trigger timer in wepapp and other clients
  //   if (socket !== null){
  //     const user = decodeToken(localStorage.getItem('token'));
  //     socket.emit('focusTimer', {room: user['outseta:accountUid'], data: {timer: focusTimer, tasks: focusTasksList}});
  //   }
  // }, [])

  return (
    <div className={`break-focus-page break-timer-start focus-mode-break-time ${theme}`} css={{
      backgroundColor: colorPicker,
      backgroundImage: theme === 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))` : `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))`
    }}>
      <div className='backgroundBottom' css={{bottom: '100px !important'}}>
        <BackgroundImageTeacup />
      </div>
      <div className="page-wrapper">
        <div className="page-container">
          <div className="focused-items-heading" style={{marginTop: '20px'}}>
            Focus Session {focusSessions.filter(session => moment().format('L') === moment(session.startTime).format('L')).length} {focusTasksList.length > 0 ? <span style={{color: colorPicker}}>{focusTasksList.filter(t => t.status == 1).length} Completed</span> : null}<span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}}> / {focusTasksList.length}</span>
          </div>
          <div className='page-lg-title' style={{color: theme == 'dark' ? '#fff' : '#000'}}>You’re on a Break</div>
        </div>
      </div>
      <FocusTimerBar resumeTimerFlag={true} showSec={true} />
    </div>
    
  )
}

export default BreakTimerStart
