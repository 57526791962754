/* eslint-disable */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { getBlockedSites, postBlockedSites } from '../../Components/action/common'
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from 'react-redux'
import Button from './Button'
import ArrowDown from './ArrowDown'
import SiteBlockerIcon from './SiteBlockerIcon'
import Select from 'react-select';
import InfoIcon from './InfoIcon'
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import NoSitesBg from './NoSitesBg'
import SurePopupIcon from './SurePopupIcon'
import { Cancel } from '../../Components/LandingHeader/Feedback/icons/Icons'
import NoSitesBgDark from './NoSitesBgDark'

function getHoursAndMinutesFromSeconds(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);

  if (!hours || isNaN(hours)) {
    hours = 0;
  }

  if (!minutes || isNaN(minutes)) {
    minutes = 0;
  }

  return {
    hours: hours,
    minutes: minutes
  };
}


const DeletingSiteAnimation = ({ site, onFinish, handleUndo, theme, colorPicker }) => {
  useEffect(() => {
    const interval = setTimeout(() => {
      onFinish(site);
    }, 3000);
    return () => {
      clearInterval(interval);
    }
  }, [])
  return (
    <div className="field-block mt-5" id={`sites_${site.id}`}>
      <div className="field-collection"
        style={{ padding: '25px 20px' }}
        css={{
          background: theme == "dark" ? `linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff`

        }}
      >
        <div className="logo-text">
          <span style={{ color: colorPicker, textTransform: 'capitalize', fontSize: '14px', fontWeight: '500' }}>[Removed]</span>
        </div>
        <div className="">
          <span onClick={() => handleUndo(site)}
            style={{ color: colorPicker, textTransform: 'capitalize', fontSize: '12px', fontFamily: "Karla", fontWeight: '500', cursor: 'pointer' }}>Undo</span>
        </div>
      </div>
      <div
        style={{
          height: '5px',
          background: colorPicker,
          width: '100%',
          animation: 'progress-animation 3s linear',
        }}
      ></div>
    </div>
  )
}
const Interval = ({ theme, colorPicker, handleFromTimeChange, handleTimeChange, handleAddInterval, handleRemoveInterval, blockedSites }) => {
  const customInputStyle = {
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    padding: '5px',
  };

  return (
    <div className={theme}>
      {blockedSites.intervals.length ?
        blockedSites.intervals.map((interval, index) => (
          <div className="field-data intervals" key={index}>
            <div className="filed-data-block">
              <TimePicker
                onChange={newFromTime => handleFromTimeChange(newFromTime, index)}
                value={interval.fromTime}
                disableClock={true}
                isOpen={false}
                format="h:mm a"
                className={theme == 'dark' ? 'custom-input-select-dark' : ""}
              />
            </div>
            <span style={{ width: '8%', textAlign: 'center' }}> to </span>
            <div className="filed-data-block">
              <TimePicker
                onChange={newToTime => handleTimeChange(newToTime, index)}
                value={interval.toTime}
                disableClock={true}
                isOpen={false}
                format="h:mm a"
                className={theme == 'dark' ? 'custom-input-select-dark' : ""}
              />
            </div>
            <div css={{
              width: '30px',
              height: '30px',
              position: 'absolute',
              right: '-12%',
              cursor: 'pointer',
              border: `1px solid ${theme === 'dark' ? 'hsla(0, 0%, 100%, .15)' : 'rgba(0, 0, 0, 0.15)'}`,
              borderRadius: '57px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }} onClick={() => handleRemoveInterval(index)}>
              <Cancel color="grey" />
            </div>
          </div>

        )) : ""}

      <div className="field-button" style={{ marginTop: 10 }}>
        <button className="changeName"
          css={{
            background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
            color: `${colorPicker} !important`

          }}
          onClick={handleAddInterval}>
          Add Interval
        </button>
      </div>
    </div>
  );
};

const customStyles = {
  // Style for the input
  input: (provided) => ({
    ...provided,
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    padding: '5px',
  }),

};

function SiteBlocker({ activeTab, setActiveTab }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { colorPicker, theme } = useSelector(state => state.theme)
  const [onMouseEnterProfile, setOnMouseEnterProfile] = useState(false)
  const { settings } = useSelector(state => state.settings)
  const [onMouseEnterInfo, setOnMouseEnterInfo] = useState(false)
  const [selectedDays, setSelectedDays] = useState([])
  const [isDeleted, setIsDeleted] = useState(false);
  const [id, setId] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSaving, setShowSaving] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showBlockInfo, setShowBlockInfo] = useState(false);
  const divRef = useRef(null);
  const [surePopupShow, setSurePopupShow] = useState(false);
  const [deletingSiteIds, setDeletingSiteIds] = useState([]);

  const [timeOuts, setTimeOuts] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleAddInterval = () => {
    setBlockedSites(((prev) => ({
      ...prev, ['intervals']: [...prev.intervals, { fromTime: '', toTime: '' }]
    })));
  };

  const handleFromTimeChange = (newFromTime, index) => {
    setBlockedSites(prevState => {
      const updatedIntervals = [...prevState.intervals];
      updatedIntervals[index].fromTime = newFromTime;
      return { ...prevState, intervals: updatedIntervals };
    });
  };

  const handleTimeChange = (newToTime, index) => {
    setBlockedSites(prevState => {
      const updatedIntervals = [...prevState.intervals];
      updatedIntervals[index].toTime = newToTime;
      return { ...prevState, intervals: updatedIntervals };
    });
  };

  const handleRemoveInterval = index => {
    setBlockedSites(prevState => {
      const updatedIntervals = [...prevState.intervals];
      updatedIntervals.splice(index, 1);
      return { ...prevState, intervals: updatedIntervals };
    });
  };

  const [blockedSites, setBlockedSites] = useState({
    blockedSites: [],
    // intervals:[{"fromTime":"2:50","toTime":"20:30"}],
    intervals: [],
    dailyUsage: 0,
    usageDays: [],
    status: false,
    visitCount: 1
  });

  const handleUsageDaysChange = day => {
    console.log("selectedOptions", day);

    const updatedSchedule = [...blockedSites.usageDays];
    const index = updatedSchedule.indexOf(day);

    if (index === -1) {
      updatedSchedule.push(day);
    } else {
      updatedSchedule.splice(index, 1);
    }

    setBlockedSites(prevState => ({
      ...prevState,
      usageDays: updatedSchedule
    }));
  };

  const weekDayOptions = [
    { value: 'Monday', label: 'Mon' },
    { value: 'Tuesday', label: 'Tue' },
    { value: 'Wednesday', label: 'Wed' },
    { value: 'Thursday', label: 'Thu' },
    { value: 'Friday', label: 'Fri' },
    { value: 'Saturday', label: 'Sat' },
    { value: 'Sunday', label: 'Sun' }
  ]

  const handleTypeChange = (site, status) => {
    const id = site.id;
    const updatedData = blockedSites.blockedSites.map((blocked_site) => {
      if (blocked_site.id == id) {
        return {
          ...blocked_site,
          isSoft: status
        }
      }
      return blocked_site;
    });

    setBlockedSites(((prev) => ({
      ...prev, ['blockedSites']: updatedData
    })));
  }
  const handleDelete = async (site) => {
    setDeletingSiteIds((prevDeletingSiteIds) => [...prevDeletingSiteIds, site.id]);
  }


  const handleToggle = async (site, value) => {
    const id = site.id;
    const updatedData = blockedSites.blockedSites.map((blocked_site) => {
      if (blocked_site.id == id) {
        return {
          ...blocked_site,
          isActive: value
        }
      }
      return blocked_site;
    });

    setBlockedSites(((prev) => ({
      ...prev, ['blockedSites']: updatedData
    })));
  }

  const updateDetails = async (formData) => {
    setSubmitted(true);
    try {
      if (!JSON.stringify(blockedSites.blockedSites).includes(formData.site)) {
        const maxId = blockedSites.blockedSites.length > 0 ? Math.max(...blockedSites.blockedSites.map(site => site.id)) : 0;

        const data = {
          blockedSites: [
            ...blockedSites.blockedSites,
            {
              id: maxId + 1,
              url: !formData.site.startsWith('https://') ? 'https://' + formData.site : formData.site,
              isSoft: true,
              isActive: true,
            },
          ],
          intervals: [...blockedSites.intervals],
          dailyUsage: blockedSites.dailyUsage,
          usageDays: [...blockedSites.usageDays],
          status: blockedSites.status,
          visitCount: blockedSites.visitCount
        };
        setBlockedSites(prevState => ({
          ...prevState,
          ...data
        }));
      }

      reset();

    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    getBlockedSitesData();
    // return () => {
    //   const updatedSites = blockedSites.blockedSites.filter(site => !deletingSiteIds.includes(site.id));
    //   if (deletingSiteIds) {
    //     setBlockedSites(((prev) => ({
    //       ...prev, ['blockedSites']: updatedSites
    //     })));
    //   }
    // }
  }, [])


  const getBlockedSitesData = async () => {
    let response = await getBlockedSites();
    if (response && Object.keys(response).length > 0) {
      // if (response.visitCount <= 4) {
      //   setShowInfo(true);
      // } else {
      //   setShowInfo(false);
      // }
      setBlockedSites(response);
      setId(response.id);
    }
  }
  useEffect(() => {
    // if (blockedSites.visitCount <= 4) {
    //   setShowInfo(true);
    // } else {
    //   setShowInfo(false);
    // }
    if (!isDeleted) {
      if (Object.keys(blockedSites.blockedSites).length || blockedSites.status) { //should be false
        setShowSaving(true);
        setShowSaved(false);
        postBlockedSites(blockedSites, id, isDeleted).then((response) => {
          // Update 
          // try{
          //   if(window?.chrome){
          //     window?.chrome.runtime.sendMessage('lidddpjoecpledllomnlkleeenaeanlm', { action: 'updateBlockedSites', data: response }, (response) => {
          //       console.log('Blocked sites are updated');
          //     });
          //   }
          // }catch(e){
          //   console.log('ERROR: Blocked sites are not updated', e);
          // }
          setId(response.id);
          setTimeout(() => {
            setShowSaving(false);

            setShowSaved(true);
            setTimeout(() => {
              setShowSaved(false);
            }, 3000);
          }, 2000);
        })
      }
    }

  }, [blockedSites])


  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };
  const handleFocus = () => {
    setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen); // Toggle menuIsOpen state
  };

  const getsiteTitle = (value) => {
    const url = new URL(value);
    const parts = url.hostname.split('.');
    const hostname = parts.length > 2 ? parts.slice(-2).join('.') : url.hostname;
    return hostname.split('.')[0];
  }
  const init = async () => {

    // Get the access token from the callback url
    const accessToken = localStorage.getItem('token');
    /* eslint-disable no-undef */
    if (accessToken && chrome.storage !== undefined) {
      // If there is an access token present
      chrome.storage.local.set({ accessToken: accessToken }, function () {
      });
    }
  };

  init();


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowBlockInfo(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    handleSubmit(updateDetails)(event);
  };

  function updateDailyUsage(newSeconds, isMinutes) {

    if (surePopupShow) {
      setSurePopupShow(false)
    }
    let newTime = 0;
    let existing = getHoursAndMinutesFromSeconds(blockedSites.dailyUsage);

    let newTimeInSeconds = newSeconds * (isMinutes ? 60 : 3600);
    if (!newTimeInSeconds || isNaN(newSeconds)) {
      newTimeInSeconds = 0
    }

    if (isMinutes) {
      newTime = (existing.hours * 3600) + newTimeInSeconds
    } else {
      newTime = (existing.minutes * 60) + newTimeInSeconds
    }

    setBlockedSites(prevState => ({
      ...prevState,
      dailyUsage: newTime,
    }))

    // if (newTime > 1800 && !hasConfirmedMax) {
    //   return setSurePopupShow(true);
    // }
    if (newTime > 1800) {
      return setSurePopupShow(true);
    }
  }

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',];

  const renderDay = (day) => {
    const isSelected = blockedSites.usageDays.includes(day);

    return (
      <div
        key={day}
        tooltip={day}
        className={`day ${isSelected ? 'selected' : ''}`}
        css={{
          background: isSelected ? colorPicker : ''
        }}
        onClick={() => handleUsageDaysChange(day)}
      >
        {day.charAt(0)}
      </div>
    );
  };

  return (
    <>
      <li 
        css={{
          '&:hover .accordion-title': {
            color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important") : "white !important"
          },
          '.accordion-title': {
            color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c") && (activeTab == "profile")) ? "white !important" : ((theme == "dark" && colorPicker == "#f8961e") && (activeTab == "profile")) ? "black !important" : ((theme == "dark" && colorPicker == "#ffca3a") && (activeTab == "profile")) ? "black !important" : ((theme == "dark" && colorPicker == "#27cb9a") && (activeTab == "profile")) ? "black !important" : ((theme == "dark" && colorPicker == "#4cc9f0") && (activeTab == "profile")) ? "black !important" : "white !important") : "white",
            opacity: theme == "dark" && ((theme == "dark" && (activeTab == 'block' || onMouseEnterProfile)) ? `0.9 !important` : `0.9 !important`)
          },
        }}
        className={`li-setting-tab ${(activeTab == 'block') ? 'active' : ''} site-blocker-settings ${theme}`}>
        <div className="settings-accordion-tab"
          onClick={() => {
            if (blockedSites.status) {
              if (blockedSites.visitCount <= 4 && activeTab !== 'block') {
                setBlockedSites(prevState => ({
                  ...prevState,
                  visitCount: ++blockedSites.visitCount
                })
                )
              }
              setActiveTab(activeTab => activeTab == 'block' ? null : 'block');
            }
          }
          }

          style={{ background: theme == "dark" ? "black" : "" }}
          css={{
            background: activeTab == 'block' ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : 'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
            '&:hover': {
              background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`,
            }
          }}>
          <div className="settings-accordion-heading" >
            <figure className="settings-icon">
              {
                theme == "dark" ? <SiteBlockerIcon isProfileActive={activeTab == 'block'} onMouseEnterProfile={onMouseEnterProfile} isActiveSettings={activeTab} /> : <img src="images/ban.svg" className="img-default" />
              }
            </figure>
            <h3 className="accordion-title text-uppercase" style={{ color: (theme == "dark") ? "white" : "", opacity: theme == "dark" ? 0.7 : "" }}>Site Blocker</h3>
          </div>
          <div className="settings-accordion-text-arrow">

            {activeTab && showSaving &&
              <div className="settings-accordion-changes">
                <img src="images/save.svg" className="img-default" />
                <span>Saving Changes</span>
              </div>
            }
            {activeTab && showSaved &&
              <div className="settings-accordion-changes">
                <img src="images/save.svg" className="img-default" />
                <span>Changes Saved</span>
              </div>
            }

            {blockedSites.status &&
              <div className="settings-accordion-arrow-site">
                <img src="images/arrow-down.svg" />
              </div>
            }

            <div className="accordion-toggle"
            >
              <div class="on-off-toggle" >
                <input class="on-off-toggle__input custom_on_off_input" type="checkbox" id="status"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(event) => {
                    setActiveTab(activeTab => activeTab == 'block' ? null : 'block');

                    setBlockedSites(prevState => ({
                      ...prevState,
                      status: event.target.checked
                    })
                    )

                  }}
                  checked={blockedSites.status}

                />

                <label for="status" className="on-off-toggle__slider focus-label"
                  css={{
                    background: !blockedSites.status ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),linear-gradient(0deg,rgba(255,255,255,0.05),rgba(255,255,255,0.05)),#000000 !important` : `${colorPicker} !important`
                  }}
                ></label>
              </div>
            </div>

          </div>

        </div>

        {
          activeTab == 'block' && blockedSites.status && <div className="settings-accordion-content">
            <div className="inner-form" css={{
              background: theme == 'dark' ? '#000' : ""
            }} style={{ paddingTop: 20 }}
            >

              <form onSubmit={handleFormSubmit} className="block">
                <div className="field-block mt-30">
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                    <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Blocked Sites</label>
                     
                  </div>
                  <div className="block-field-info" style={{ marginBottom: 0, opacity: 1, fontSize: '16px', color: theme == "dark" ? "#fff" : "#909090" }}><span style={{ color: colorPicker }}>Hard Block : </span> Stops you from completely from accessing a site (unless you have a daily allowance set)</div>
                  
                  <div className="block-field-info" style={{ fontSize: '16px', opacity: 1, color: theme == "dark" ? "#fff" : "#909090" }}><span style={{ color: colorPicker }}>Soft Block : </span> Makes you wait 10 seconds before accessing a site on your block list.</div>
                  <div className="settings-accordion-arrow mt-30">
                    {/* {
                      theme != "dark" ? <InfoIcon isActiveSettings={activeTab} onMouseEnterProfile={onMouseEnterProfile} isProfileActive={activeTab == 'block'} profileActive="profile" /> : <img src="images/info-outline.svg" />
                    } */}
                    {/* <InfoIcon isActiveSettings={activeTab} onMouseEnter={onMouseEnterInfo} isProfileActive={activeTab == 'block'} profileActive="profile" />  */}
                  </div>
                  {showInfo &&
                    <div className='info-sites'
                      css={{
                        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff`
                      }}
                    >
                      <div style={{ width: '341px' }}>
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'Playfair Display',
                            fontWeight: '600'
                          }}
                          css={{
                            color: `${colorPicker} !important`
                          }}
                        >Soft Block</span>
                        <h4
                          css={{
                            color: `${colorPicker} !important`
                          }}
                        >A New Way To Block Sites</h4>
                        <p
                          css={{
                            color: `${colorPicker} !important`
                          }}
                        >The soft block shows you a countdown of 10 seconds , giving you time to stop and reflect on whether or not visiting that site is the best use of your time.</p>
                      </div>
                      <div>
                        <img src="images/breathInthumb.svg" style={{ position: "absolute", zIndex: 9, top: '5%' }}></img>
                        <img style={{ position: 'absolute', top: 0, right: '4%' }} src="images/hardThumb.svg"></img>

                      </div>
                      <span className='info-sites-close' onClick={() => setShowInfo(false)}><img src="images/close-model.svg"></img></span>
                    </div>
                  }

                  <div className="field-data">
                    <div className="filed-data-block">
                      <div className="info_input" style={{ borderRadius: '10px' }}
                        css={{
                          border: theme == 'dark' ? "1px solid rgba(255, 255, 255, 0.15)" : '1px solid rgba(0, 0, 0, 0.1)'
                        }}
                      >

                        <input placeholder="Enter a web address (ex:facebook.com)" id="site" name='site' {...register("site", {
                          required: {
                            value: true,
                            message: 'Site is required'
                          },
                          pattern: {
                            value:
                              /^([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
                            message: "That is not a valid URL. Please write the site name followed by the domain extension. (Eg. sitename.com)",
                          },
                        })
                        } className="input-field"
                          css={{ color: theme == 'dark' ? `#fff !important` : `` }}

                        />
                        <div className="info_text">
                          <span>https://</span>
                        </div>
                        {errors.site && <span class="input-internal-label" style={{ position: 'absolute', right: 20, top: 18 }}><InfoIcon color={'#D95555'}></InfoIcon></span>}
                      </div>
                      {submitted && errors.site && <p class="error">{errors.site.message}</p>}
                    </div>
                    <div className="field-button">
                      <button type="submit"
                        className="changeName"
                        css={{
                          background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                          color: `${colorPicker} !important`

                        }}
                        style={{ cursor: "pointer" }}>Block Site</button>
                    </div>
                  </div>
                </div>
              </form>
              {blockedSites.blockedSites.length ?
                <>
                  <div className="sitesList" style={{ marginTop: '30px' }}>
                    {blockedSites.blockedSites.map((site, index) => (

                      <div>
                        <div key={site.id}>
                          {deletingSiteIds.includes(site.id) ? (
                            <DeletingSiteAnimation site={site} onFinish={() => {
                              setDeletingSiteIds(p => p.filter(i => i != site.id))
                              setBlockedSites((prevSites) => ({
                                ...prevSites,
                                blockedSites: prevSites.blockedSites.filter(j => j.id != site.id)
                              }));
                            }} handleUndo={(site) => {
                              setDeletingSiteIds(p => p.filter(i => i != site.id))

                            }}
                              theme={theme} colorPicker={colorPicker} />
                          ) : (
                            <div className="field-block mt-5" id={`sites_${site.id}`}>
                              <div className="field-collection"
                                css={{
                                  background: theme == "dark" ? `linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #000 !important` : `linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), #FFF;`,

                                  '&:hover': {
                                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff`
                                  }
                                }}
                              >
                                <div className="logo-text">
                                  <img src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${site.url}&size=48`} style={{ padding: 10, width: '100%', maxWidth: '48px', borderRadius: '50%' }} />

                                  <div style={{ color: '#000000' }}>
                                    <span style={{ color: theme == 'dark' ? '#fff' : '#000000', textTransform: 'capitalize', fontSize: '14px' }}>{getsiteTitle(site.url)}</span>

                                    <p style={{ color: theme == 'dark' ? '#fff' : '#000000' }}>{site.url}</p></div>
                                </div>

                                <div className='cta-btn'>
                                  <button
                                    className={`block-btn`}

                                    onClick={() => { handleTypeChange(site, false); }}
                                    css={{
                                      background: theme == "dark" ? (site.isSoft ? `#000000 !important` : `${colorPicker}`) : site.isSoft ? `#ffffff !important` : `${colorPicker}`,
                                      color: site.isSoft ? '#000' : '#fff !important',
                                    }}
                                  ><span
                                    css={{
                                      color: site.isSoft ? (theme == 'dark' ? `#fff` : '#000') : (theme == 'dark' ? `#000` : '#fff'),
                                      opacity: site.isSoft ? '0.5' : '1'
                                    }}
                                  >Hard Block</span></button>

                                  <button
                                    className={` block-btn`}
                                    onClick={() => { handleTypeChange(site, true); }}
                                    css={{
                                      background: theme == "dark" ? (!site.isSoft ? `#000000 !important` : `${colorPicker}`) : !site.isSoft ? `#ffffff !important` : `${colorPicker}`,
                                      color: !site.isSoft ? `#000 !important` : '#fff !important',
                                    }}
                                  >
                                    <span
                                      css={{
                                        color: !site.isSoft ? (theme == 'dark' ? `#fff` : '#000') : (theme == 'dark' ? `#000` : '#fff'),
                                        opacity: !site.isSoft ? '0.5' : '1'
                                      }}
                                    >Soft Block</span>
                                  </button>

                                  <div className='trash-btn' style={{ margin: 10 }} onClick={() => handleDelete(site)}>

                                    <img src={theme == 'dark' ? 'images/trash-outline.svg' : 'images/trash.svg'}

                                      className="img-default" />
                                  </div>
                                  <div class="on-off-toggle" >
                                    <input class="on-off-toggle__input" type="checkbox" id={`siteStatus_${site.id}`} onChange={(e) => handleToggle(site, e.target.checked)} checked={site.isActive} />

                                    <label for={`siteStatus_${site.id}`} className="on-off-toggle__slider focus-label"
                                      css={{
                                        background: !site.isActive ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),linear-gradient(0deg,rgba(255,255,255,0.05),rgba(255,255,255,0.05)),#000000 !important` : `${colorPicker} !important`
                                      }}
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
                : <>
                  {/*  */}
                  <div className='no-sites'
                    css={{
                      marginTop: '30px',
                      position: 'relative',
                      height: '150px',
                      overflow: 'hidden',
                      borderRadius: '15px',
                      background: theme === 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), ${colorPicker}`
: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff`                    }}
                  >
                    {
                      theme === 'dark'
                      ?
                      <NoSitesBgDark style={{ width: '100%', position: 'absolute', left: '50%', height: '385px' }} />
                      :
                      <NoSitesBg />
                    }
                    
                    <div id="bg-image" ></div>
                    <span className='no-sites-text'
                      css={{
                        // color: `${theme !== 'dark' ? colorPicker : 'white'} !important`,
                        background: `${theme === 'dark' ? `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), ${colorPicker}` : `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${colorPicker}`} !important`,
                        WebkitBackgroundClip: 'text !important',
                        WebkitTextFillColor: 'transparent !important',
                        position: 'absolute',
                        left: '40px',
                        bottom: '40px',
                        textAlign: 'center',
                        fontFamily: 'Playfair Display',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal'
                      }}
                    >Not blocking any sites</span>
                  </div>
                </>
              }
              <div className="field-block mt-30 block ban-data-block">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Daily Usage Allowance - <span style={{ color: colorPicker }}>Hard Block</span></label>
                <div className="block-field-info" style={{ color: theme == "dark" ? "white" : "#909090" }}>Set a limit for the amount of time you can spend each day on sites that have ‘Hard block’ turned on.</div>

                <div className="field-data time">
                  <div className="filed-data-block" style={{ position: 'relative', minWidth: '48%', width: '48%' }}>
                    <input type="number" id="hours" className="input-field"
                      value={getHoursAndMinutesFromSeconds(blockedSites.dailyUsage).hours.toString()}
                      onChange={(event) => {
                        const value = parseInt(event.target.value);
                        updateDailyUsage(value, false)
                      }

                      }
                      css={{
                        color: theme == 'dark' ? '#fff !important' : '#000 !important',
                        border: theme == 'dark' ? '1px solid rgba(255, 255, 255, 0.15) !important' : '1px solid rgba(0, 0, 0, 0.10) !important'

                      }}
                      name="hours" placeholder="0" />
                    <span className="input-internal-label" style={{ position: 'absolute', right: 20, top: 12 }}>hours</span>
                  </div>
                  <div className="filed-data-block" style={{ position: 'relative', minWidth: '48%', width: '48%' }}>
                    <input type="number" id="minutes" className="input-field" name="minutes" placeholder="0"
                      value={getHoursAndMinutesFromSeconds(blockedSites.dailyUsage).minutes.toString()}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        updateDailyUsage(value, true)

                      }
                      }
                      css={{
                        color: theme == 'dark' ? '#fff !important' : '#000 !important',
                        border: theme == 'dark' ? '1px solid rgba(255, 255, 255, 0.15) !important' : '1px solid rgba(0, 0, 0, 0.10) !important'

                      }}
                    />
                    <span className="input-internal-label" style={{ position: 'absolute', right: 20, top: 12 }}>mins</span>
                  </div>
                </div>
                {surePopupShow &&
                  <div className='surePopup'
                    css={{
                      background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                      color: `${colorPicker} !important`

                    }}
                  >
                    <div >
                      <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Are you sure?</label>
                      <p>That seems like quite a bit of time for distractions</p>
                      <span css={{
                        color: `${colorPicker} !important`
                      }}
                        onClick={() => {
                          setSurePopupShow(false)
                        }}>Yes, I’m sure!</span>
                    </div>
                    <div>
                      <SurePopupIcon></SurePopupIcon>
                    </div>
                  </div>
                }

              </div>

              <div className="field-block mt-30 block ban-data-block">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Schedule</label>
                <div className="block-field-info" style={{ color: theme == "dark" ? "white" : "#909090" }}>Choose the times and days you would like site blocking to be active.</div>

                <p style={{ color: theme == "dark" ? "white" : "" }}></p>
                <Interval
                  theme={theme}
                  colorPicker={colorPicker}
                  handleFromTimeChange={handleFromTimeChange}
                  handleTimeChange={handleTimeChange}
                  handleAddInterval={handleAddInterval}
                  handleRemoveInterval={handleRemoveInterval}
                  blockedSites={blockedSites}
                />
              </div>
              <div className="field-block mt-30 block ban-data-block">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "" }}>Selected Days</label>
                <div className="block-field-info" style={{ color: theme == "dark" ? "white" : "#909090" }}>(Click a day to activate/deactivate)</div>

                <div className="field-data">
                  <div className="filed-data-block" style={{ width: '100%' }}
                    css={{
                      '.general-setting__option--is-selected': {
                        background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        color: '#fff'
                      }
                    }}>

                    <div className="schedule">
                      {days.map(renderDay)}
                    </div>
                    {/* {weekDayOptions.map((weekday, index) => (
                      <div style={{
                        borderRadius: "50%",
                        background: blockedSites.usageDays.includes('mon') || blockedSites.usageDays.includes('wed') ? '#27cb9a' : 'grey',
                        height: '40px',
                        width: '40px'
                      }}>{weekday.label}</div>
                    ))} */}


                    {/* <Select
                      onChange={(value) => handleUsageDaysChange(value)}
                      options={weekDayOptions}
                      value={blockedSites.usageDays}
                      menuPlacement="top"
                      isMulti
                      className={theme == 'dark' ? 'react-select custom-input-select-dark' : "react-select"}
                      classNamePrefix={"general-setting" || ""}
                      menuIsOpen={menuIsOpen}
                      onMenuClose={handleMenuClose}
                      onFocus={handleFocus} // Toggle menuIsOpen state on focus
                      onBlur={() => { setMenuIsOpen(false) }} // Close the menu on blur
                      styles={customStyles} // Apply custom styles to the Select component

                    /> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        }

      </li>
    </>
  )

}

export default SiteBlocker