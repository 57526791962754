import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { fetchInboxTaskList, totalTaskCount } from '../../Components/action/common'
import AddTask from '../AddTasks/AddTask'
import TaskListDetails from './TaskListDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setAllTaskList, setDateChange, setTotalCount } from '../../redux/slices/TaskSlice'
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import moment from 'moment'
import { Accordion } from 'react-accessible-accordion'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'

function All() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const [list, setList] = useState([])
  const currentTimes=moment().startOf('date')
  const [currentTime,setCurrentTime]=useState(new Date(currentTimes))

  // global state
  const { allTaskList } = useSelector(state => state.task)
  const { theme} = useSelector(state => state.theme)

  const fetchAddTaskData = useCallback(async () => {
    setLoader(true)
    try {
      const response = await fetchInboxTaskList({date: currentTime.toISOString()})
      const allTaskList = _.chunk([...(_.sortBy(response?.data?.responseData.filter(t => t.status != '1'), ['status', 'date'])), ...(_.orderBy(response?.data?.responseData.filter(t => t.status == '1'), ['isMostImportant', 'completedAt'], ['desc', 'desc']))], 10);
      const chunk = allTaskList.splice(0, 1);
      setList(allTaskList)
      dispatch(setAllTaskList(chunk?.[0] ?? []))
      setLoader(false)
    } catch ({ response }) {
      setLoader(false)
    }
  }, [dispatch])

  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {
      
    }
  }

  useEffect(() => {
    dispatch(setDateChange(new Date()))
    fetchAddTaskData()
  }, [])

  const fetchMoreData = () => {
    const chunk = list.splice(0, 1);
    if(chunk.length > 0){
      setList(list)
      dispatch(setAllTaskList([...allTaskList, ...chunk[0]]))
    }
  }

  return (
      loader ? <AllLoader /> : <> <div id="tasks-list-wrapper" className="overdue-form task-list-page common-form" style={{background:theme == "dark" ? "black" :"white"}} >
          <AddTask isAddTask={true} fetchAddTaskData={fetchAddTaskData} allTask={true} fetchTotalCount={fetchTotalCount}  currentPage="allTask" />
          <div className="task-list">
            <div className="task-list-data">
              <div className="ul-task-list">
                <div className="task-list">
                <InfiniteScroll
                    dataLength={allTaskList?.length ?? 0}
                    next={fetchMoreData}
                    hasMore={list.length > 0}
                    loader={<UpcomingLoader  />}
                    scrollableTarget="tasks-list-wrapper"
                  >
                <Accordion preExpanded={['accitem0']}>
                  <TaskListDetails
                    allTaskList={allTaskList}
                    currentPage="allTask"
                    fetchAddTaskData={fetchAddTaskData}
                    fetchTotalCount={fetchTotalCount}
                  />
                </Accordion>
                </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default All