/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useRef } from 'react'
import { useSelector } from "react-redux";

const Tooltip = ({ children, wrapperClassName = "", className="", text = '', delay = 500, color = '', textClassName="darkPrimaryColor" }) => {
    const tooltipRef = useRef();
    const {theme} = useSelector(state => state.theme)

    // useEffect(() => {
    //     tooltipRef.current.querySelector('.tooltip-hover').addEventListener('mouseenter', showTooltip)
    //     tooltipRef.current.querySelector('.tooltip-hover').addEventListener('mouseleave', hideTooltip)

    //     return () => {
    //         if (tooltipRef.current) {
    //             tooltipRef.current.querySelector('.tooltip-hover').removeEventListener('mouseenter', showTooltip)
    //             tooltipRef.current.querySelector('.tooltip-hover').removeEventListener('mouseleave', hideTooltip)
    //         }
    //     }
    // }, []);

    // const showTooltip = (e) => {
    //     const t = tooltipRef.current.querySelector('.tooltip-text');
    //     setTimeout(() => {
    //         t.style.display = 'block';
    //     }, delay)
    // }

    // const hideTooltip = (e) => {
    //     const t = tooltipRef.current.querySelector('.tooltip-text');
    //     t.style.display = 'none';
    // }

    return (
        <span ref={tooltipRef} className={wrapperClassName}>
            <span className='tooltip-hover'>{children}</span>
            <span className={`${className} tooltip-text`} css={{ 
                color: `${theme == 'dark' ? 'white' : '#242424'} !important`, 
                background: `${theme == 'dark' ? '#000000' : '#f3f3f3'} !important`,
                border: `${theme == 'dark' ? '1px solid #4c4b4b' : 'none'} !important`
            }}>{text}</span>
        </span>
    )
}

export default Tooltip