import axios from "axios";
import AbortController from "abort-controller";

const CHATGPT_API_ROOT = 'https://api.openai.com/v1/chat/completions';
const CHATGPT_API_TOKEN = 'Bearer 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';
const CHATGPT_MODEL = "gpt-4o-mini"; //"gpt-3.5-turbo";
let controller = null;

export const sendPrompt = async (data) => {
    controller = new AbortController()
    const response = await fetch(CHATGPT_API_ROOT, {
        method: "POST",
        mode: "cors", 
        cache: "no-cache", 
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          "Authorization": CHATGPT_API_TOKEN
        },
        body: JSON.stringify({messages: data, model: CHATGPT_MODEL, stream: true})
    });

    const reader = response.body.pipeThrough(new window.TextDecoderStream()).getReader();

    return reader;
}

export const stopGeneratingCompletion = () => {
  controller !== null && controller.abort();
}

export const getChatTitle = async (data) => {
  const response = await fetch(CHATGPT_API_ROOT, {
      method: "POST",
      mode: "cors", 
      cache: "no-cache", 
      headers: {
        "Content-Type": "application/json",
        "Authorization": CHATGPT_API_TOKEN
      },
      body: JSON.stringify({messages: data, model: CHATGPT_MODEL, stream: false})
  });

  const result = response.json();

  return result;
}

export const fetchConversations = (props) => {
    return axios({
      method: 'GET',
      url: '/chat/chats',
      params: props
    })
}
  
export const saveConversation = (props) => {
    return axios({
      method: 'POST',
      url: '/chat/save',
      data: props
    })
}

export const deleteConversation = (id) => {
    return axios({
      method: 'DELETE',
      url: '/chat',
      data: {id}
    })
}

export const undoConversation = (id) => {
    return axios({
      method: 'DELETE',
      url: '/chat',
      data: {id, undo: 1}
    })
}