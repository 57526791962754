import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import { completedTask, deleteTask } from '../../Components/action/common'
import { pushCompletedTaskList, undoAllTaskSlice, undoNoDataTaskSlice, undoOverDueTaskSlice, undoTaskSlice, undoTrashSlice, undoUpcomingTaskSlice, updateAllTaskStatus, updateCompleteTaskStatus, updateGroupedStatus, updateNoDateTaskStatus, updateOverDueTaskStatus, updateStatus, updateTrashTaskStatus } from '../../redux/slices/TaskSlice'

function Toaster({isHomePageHighlightedTask,  fetchTotalCount = () => {}, data, task, title, completeTaskData, deleteTaskData, fetchAddTaskData, currentPage, currentIndex }) {
  const dispatch = useDispatch()
  const {theme} =useSelector(state=>state.theme)
  const [show, setShow] = useState(true);

  useEffect(() => {
    document.removeEventListener('keydown', undoAction);
    document.addEventListener('keydown', undoAction);
  }, []);

  const undoAction = (e) => {
    if ((e.ctrlKey == true || e.metaKey) && (e.key == 'z' || e.keyCode == 26)) {
      document.removeEventListener('keydown', undoAction);
      onUndoTask();
    }
  }

  const onUndoTask = async () => {

    if(isHomePageHighlightedTask == true){

    }
    setShow(true)
    
    if (deleteTaskData == true) {
      let response = await deleteTask({ "id": task?.id, "undo": 1 })

      switch (currentPage) {
        case 'home':
          dispatch(undoTaskSlice(task));
          break;
        case 'overDue':
          dispatch(undoOverDueTaskSlice(task));
          break;
        case 'upComing':
          dispatch(undoUpcomingTaskSlice(task));
          break;
        case 'allTask':
          dispatch(undoAllTaskSlice(task))
          break;
        case 'noDate':
          dispatch(undoNoDataTaskSlice(task))
          break;
        default:

      }
      fetchTotalCount()
    } else if (completeTaskData == true) {
      const payload = { "id": task?.id, "status": 0 };
      let response = await completedTask(payload)
     
      switch (currentPage) {
        case 'home':
          dispatch(updateStatus(payload));
          break;
        case 'overDue':
          dispatch(updateOverDueTaskStatus(payload));
          break;
        case 'upComing':
          dispatch(updateGroupedStatus(payload));
          break;
        case 'allTask':
          dispatch(updateTrashTaskStatus(payload))
          break;
        case 'noDate':
          dispatch(updateNoDateTaskStatus(payload))
          break;
        case 'completedList':
          dispatch(updateCompleteTaskStatus(payload))
          break;
        default:
      }
      fetchTotalCount();
    }

    if(currentPage == "trash"){
      try {
        let response = await deleteTask({ "id": task?.id })
            fetchAddTaskData("isUndoClick")
      } catch ({response}) {
        
      }
    }
    if(currentPage == "completedList"){
      const payload = { "id": task?.id, "status": 1 };
      try {
        let response = await completedTask(payload)
        fetchAddTaskData("isRestoreCompleted")
      } catch ({response}) {
        
      }
    }

    setShow(false)

  }

  const onRestoreCompletedTask = async () => {
    let requestBody = {
      "id": task?.id,
      "status":1
    }
    try {
      await completedTask(requestBody);
      dispatch(pushCompletedTaskList(task))

    } catch ({ response }) {
     
    }
   
  }
  return (
    <>
      {
        show && <div id={task?.id} className="task-delete-popup bottom-conformation-popup " style={{ position: "fixed" , border:theme == "dark" ? "1px solid white" :""}}>
          <div className="task-undo">
            <button className="delete leftWrap" type="button">
              <img src="images/trash-2-white.svg" alt='' /> <span className="task-delete">{title}</span>
            </button>
            <button className="undo" type="button" onClick={onUndoTask}>
              <span className="task-archive">Undo</span>
            </button>
          </div>
        </div>
      }
    </>

  )
}

export default Toaster