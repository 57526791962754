/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useEffect, useState } from "react"
import { SITE_BASE_URL } from "../common/constants"
import { RoundCheckbox } from "../LandingHeader/Feedback/icons/Icons"
import { useSelector } from "react-redux";
import { focusTimerFormatV3, focusTimerFormatV4 } from "../action/utilities";
import { fetchBlockedSitesData } from "../action/common";

const PreExpire = ({data, colorPicker, theme}) => {

    const {focusSessions} = useSelector(state => state.focusTimer);
    const {taskList} = useSelector(state => state.task);
    const [focusTime, setFocusTime] = useState(0);
    const [totalTasks, setTotalTasks] = useState(0);
    const [totalBlockedSites, setTotalBlockedSites] = useState(0);
    const [totalInCompletedTasks, setTotalInCompletedTasks] = useState(0);

    useEffect(() => {
        const totalTime = calculateTotalTime(focusSessions);
        setFocusTime(totalTime);
    }, [focusSessions])

    useEffect(() => {
        const totalTasks = taskList.filter(task => task.taskSource === null && !task?.deletedAt);
        const totalInCompletedTasks = totalTasks.filter(task => task.status != '1' && !task?.deletedAt);
        setTotalTasks(totalTasks.length);
        setTotalInCompletedTasks(totalInCompletedTasks.length);
        fetchBlockedSitesData().then(response => {
            setTotalBlockedSites(response.blockCount);
        })
    }, [taskList])

    const calculateTotalTime = (data) => {
        const totalMilliseconds = data.reduce((total, session) => {
          const sessionDuration = (session.endTime ?? new Date().getTime()) - session.startTime;
          const pauseDuration = session.pauses.reduce((pauseTotal, pause) => 
            pauseTotal + ((pause.endTime ?? new Date().getTime()) - pause.startTime), 0);
          return total + (sessionDuration - pauseDuration);
        }, 0);
      
        return totalMilliseconds / 1000;
    }

    return (
        <div id="subscription-plan" css={{
            '.darkColor': {
                background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${colorPicker}`,
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text !important'
            },
            '.darkColor1': {
                background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${colorPicker}`
            }
          }}>
            <div className='part1'>
                <h2>Upgrade now with our <span className="darkColor">Early Supporter Plan</span></h2>
                <h1>Your Trial expires<span className="darkColor">{data?.daysLeft === 0 ? ' Today' : ` in ${data?.daysLeft} ${data?.daysLeft > 1 ? 'days' : 'day'}`}</span></h1>
                <ul>
                    <li>Secure one of only 1,000 spots in our Early Supporter Plan</li>
                    <li>Lock in our lowest pricing forever</li>
                </ul>
                <a href={`${SITE_BASE_URL}/plan-upgrade`} className='btn' style={{background: colorPicker, color: theme === 'light' ? '#fff' : '#000'}}>Upgrade <span className="darkColor darkColor1">$6/Month</span></a>
            </div>
            <div className='part1 part2'>
                <h2 className="darkColor">Your journey with LifeTab so far ...</h2>
                <ul>
                    <li><RoundCheckbox color={colorPicker} /> <span className="darkColor" dangerouslySetInnerHTML={{__html: focusTimerFormatV3(focusTime)}}></span> Spent focused</li>
                    <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalTasks}</span> Tasks created</li>
                    <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalInCompletedTasks}</span> Incomplete tasks</li>
                    <li><RoundCheckbox color={colorPicker} /> <span className="darkColor">{totalBlockedSites}</span> <span>{totalBlockedSites > 1 ? 'Sites' : 'Site'} blocked, <span dangerouslySetInnerHTML={{__html: focusTimerFormatV4(totalBlockedSites * 5 * 60)}}></span> of time saved.</span></li>
                </ul>
                <p>Have any questions? Get in touch by sending an email to <a href="mailto:support@lifetab.co" className="darkColor">support@lifetab.co</a></p>
            </div>
        </div>
    )
}

export default PreExpire