/* eslint-disable */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import ProfileIcon from './ProfileIcon';
import { loadOutseta } from '../../Components/Outseta';
import { LinkOut } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { SITE_BASE_URL } from '../../Components/common/constants';

function OutsetaProfile({ activeTab, setActiveTab, trigger = null }) {
    const {colorPicker , theme} = useSelector(state => state.theme);
    const [onMouseEnterProfile, setOnMouseEnterProfile] = useState(false);

    const OPTIONS = {
        domain: "lifetab.outseta.com",
        load: "profile"
    };

    useEffect(() => {
        if(window?.chrome?.storage){
            return;
        }

        document.body.classList.remove('dark')
        document.body.classList.remove('light')
        document.body.classList.add(theme);
        document.body.style = `--accent--color-app: ${colorPicker}`;

        loadOutseta(OPTIONS).then((response) => {
            // Initialize Outseta with your access token
            response.setAccessToken(localStorage.getItem('token'));
            
            if(trigger === 'profile'){
                window.onload = () => {
                    document.getElementById('outseta-profile-link').click();
                };
            }
        });
    }, [])

    useEffect(() => {
        document.body.classList.remove('dark')
        document.body.classList.remove('light')
        document.body.classList.add(theme);
    }, [theme])
 
    return (
        <>
            <li className={`li-setting-tab profile ${activeTab == "profile" ? 'active' : ''}`}
            
            css={{
            '&:hover .accordion-title':{
                color:((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important"
            }
            }}
            >
                <a id="outseta-profile-link" href={window?.chrome?.storage ? `${SITE_BASE_URL}/settings#profile` : "https://lifetab.outseta.com/profile?#o-authenticated"} target='_blank' className="settings-accordion-tab" 
                    style={{background:theme == "dark" ? "black" :""}}
                    css={{
                    background: activeTab == "profile" ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` : 
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
                    '&:hover':{
                    background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`
                    }
                }}>
                    <div className="settings-accordion-heading">
                        <figure className="settings-icon">
                        {
                            <>
                            {
                                theme == "dark" ? <ProfileIcon isProfileActive={activeTab == 'profile'} onMouseEnterProfile={onMouseEnterProfile} isActiveSettings={activeTab}/> : <img src="images/profile-account.svg" className="img-default" />
                            }
                            </>
                        }
                        </figure>
                        <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }} >profile &amp; billing</h3>
                        <figure className="settings-icon" style={{
                                width: '20px',
                                height: '20px',
                                position: 'absolute',
                                right: '40px'
                            }}>
                            <LinkOut color={theme == "dark" ? "white" : "black"} className={theme == "dark" ? "" : "img-default"} />
                        </figure>
                    </div>
                </a>
            </li>
        </>
    )
}

export default OutsetaProfile