import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fectchQuoteToday } from '../../Components/action/common'
import QuoteLoader from '../../Components/SkeletonLoading/QuoteLoader'
import { useDispatch, useSelector } from 'react-redux'
import { setQuotes, setQuotesList } from '../../redux/slices/TaskSlice'
import moment from 'moment'
import ArrowUp from './ArrowUp'
import { DragHandler } from '../Tasks/DragHandler'
import { CollapseIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'


function TodayQuotes() {
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)
  const { quotes, dateChange, quotesList } = useSelector(state => state.task)
  const {theme} = useSelector(state => state.theme)

  const [collapse, setCollapse] = useState(false)


  const todayQuotes = async () => {
    let date = new Date(moment(dateChange).startOf('date')).toISOString()
    try {
      setLoader(true)
      let response = await fectchQuoteToday({ "date": date })
      setLoader(false)

      dispatch(setQuotesList(response?.data?.responseData))
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    let x = quotesList?.find((el) => moment(el?.date).isSame(moment(dateChange), 'day'));
    if (x == undefined) {
      todayQuotes()
    }
  }, [dateChange])

  const [quotecollapse, setquotecollapse] = useState(true);

  return (
    <>
      <div className='bar widgetWrapper dailyQuotesSection'>
        {
          quotecollapse ?
            <div className="dailyQuote quoteInner">
              <div className={`quoteHeader ${collapse ? 'head-option' : ''} `} style={{ background:theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000" : "" }} onClick={() => setCollapse(!collapse)}>
                
                <span className={`quotetitle ${theme == "dark" ? "quotesTitleDarkMode" : ""}`} >Today’s QUOTE</span>
                <span className={`header-arrow ${collapse ? 'active' : ''}`}  onClick={() => setCollapse(!collapse)}>
                  
                  {
                    theme == "dark" ?  <ArrowUp/> : <img src='../images/arrow-up-dn.svg' alt=''/>
                  }
                
                  </span>
              </div>
              {
                loader === true ? <QuoteLoader /> :
                  <div className="quoteContainer" style={{ display: collapse ? "none" : '' , background : theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000" : "" }}>
                    <DragHandler />
                    {
                      quotesList?.map((quotes , idx) => {
                        return (
                          <Fragment key={idx}>
                            {
                              dateChange &&  moment(quotes?.date).format('YYYY-MM-DD') == moment(dateChange).format('YYYY-MM-DD') ? <><p className="quote-text" style={{color: theme== "dark" ? "white" :""}}>{quotes?.title}</p> <span className="qt-auth" style={{color: theme== "dark" ? "white" :""}}>-{quotes?.author}</span></> : quotes?.date == null
                            }
                          </Fragment>
                        )
                      })
                    }
                  </div>
                  }

              <div className='onHoverShorts sdQuote'>
                <div className='actOnhove'>
                    <div className='hovAct ht-collapse' onClick={() => setquotecollapse(false)}> <CollapseIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                </div>
              </div>

            </div>
            :
            <div className='barContent'  onClick={() => setquotecollapse(true)}>
                <div className='barContentHeading'>
                    <h4 className='barHead'>Quote of the Day</h4>
                    <span className='headBrk'></span>
                </div>
            </div>
        }
      </div>
    </>
  )
}

export default TodayQuotes