/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import Select, {components} from 'react-select';
import Datepicker from '../../../Components/DatePicker/components/Datepicker';
import { format } from '../../../Components/action/utilities';
import { RRule } from 'rrule'
import moment from 'moment-timezone'
import { Tick } from '../../../Components/LandingHeader/Feedback/icons/Icons';

const CustomOption = (props) => {
    return (
      <components.Option {...props} className={`recurring-custom-option ${props.isFocused ? 'focused' : ''} ${props.isSelected ? 'selected' : ''}`}>
        {props.children}
      </components.Option>
    );
};

function RepeatedTaskModal({ onHide, task={}, targetRef }) {
    const dropdownRef = useRef(null);
    const {colorPicker, theme} = useSelector(state => state.theme)
    const [repeatedTaskRule, setRepeatedTaskRule] = useState({
        repeatEvery: task?.repeatEvery ?? 1,
        periodMode: task?.periodMode ?? "Weekly",
        repeatWeekDays: task?.repeatWeekDays ?? [],
        repeatStartDate: task?.date ?? new Date(),
        repeatedTaskEndsOn: task?.date ?? new Date(),
        repeatedTaskEndsOnRule: task?.repeatedTaskEndsOnRule ?? "Never",
        repeatedTaskEndsOnOccurrences: task?.repeatedTaskEndsOnOccurrences ?? 1,
        repeatOnMonth: task?.repeatOnMonth ?? 1
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const repeatEveryOptions = [
        {value: "Daily", label: "Day"},
        {value: "Weekly", label: "Week"},
        {value: "Monthly", label: "Month"},
        {value: "Yearly", label: "Year"},
    ]
    const repeatOnMonthOptions = [
        { value: 1, label: `Monthly on day ${format(repeatedTaskRule.repeatStartDate, "DD")}` },
        { value: 2, label: `Monthly on the first ${format(repeatedTaskRule.repeatStartDate, "dddd")}` },
        { value: 3, label: `Monthly on the last ${format(repeatedTaskRule.repeatStartDate, "dddd")}` },
    ]
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',];

    const renderDay = (day) => {
        const isSelected = repeatedTaskRule.repeatWeekDays.includes(day);

        return (
        <div
            key={day}
            tooltip={day}
            className={`day ${isSelected ? 'selected' : ''}`}
            css={{
            background: isSelected ? `${colorPicker} !important` : '',
            color: isSelected ? 'white' : (theme === "dark" ? '#fff' : '#000')
            }}
            onClick={() => handleWeekDaysChange(day)}
        >
            {day.charAt(0)}
        </div>
        );
    };

    const handleWeekDaysChange = day => {
        const updatedSchedule = [...repeatedTaskRule.repeatWeekDays];
        const index = updatedSchedule.indexOf(day);
    
        if (index === -1) {
          updatedSchedule.push(day);
        } else {
          updatedSchedule.splice(index, 1);
        }
    
        setRepeatedTaskRule(repeatedTaskRule => ({...repeatedTaskRule, repeatWeekDays: updatedSchedule}))
    };

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        setRepeatedTaskRule(repeatedTaskRule => ({...repeatedTaskRule, [name]: value}));
    }

    const saveRules = (e) => {
        e && e.preventDefault();
       // setIsSubmitting(true);

        const localTimezone = moment.tz.guess();

        const rruleOptions = {
            freq: RRule[repeatedTaskRule.periodMode.toUpperCase()],
            interval: repeatedTaskRule.repeatEvery,
            dtstart: moment(repeatedTaskRule?.repeatStartDate).tz(localTimezone).endOf('day').toDate()
        };

        if (repeatedTaskRule.periodMode === "Weekly") {
            rruleOptions.byweekday = repeatedTaskRule.repeatWeekDays.map(day => RRule[day.toUpperCase().slice(0, 2)]);
        }

        if (repeatedTaskRule.periodMode === "Monthly") {
            if (repeatedTaskRule.repeatOnMonth === 1) {
                rruleOptions.bymonthday = new Date(repeatedTaskRule.repeatStartDate).getDate();
            } else if (repeatedTaskRule.repeatOnMonth === 2) {
                rruleOptions.bysetpos = 1;
                rruleOptions.byweekday = RRule[format(repeatedTaskRule.repeatStartDate, "dddd").toUpperCase().slice(0, 2)];
            } else if (repeatedTaskRule.repeatOnMonth === 3) {
                rruleOptions.bysetpos = -1;
                rruleOptions.byweekday = RRule[format(repeatedTaskRule.repeatStartDate, "dddd").toUpperCase().slice(0, 2)];
            }
        }

        if (repeatedTaskRule.repeatedTaskEndsOnRule === "On") {
            rruleOptions.until = moment(repeatedTaskRule.repeatedTaskEndsOn).tz(localTimezone).endOf('day').toDate(); //repeatedTaskRule.repeatedTaskEndsOn;
        } else if (repeatedTaskRule.repeatedTaskEndsOnRule === "After") {
            rruleOptions.count = repeatedTaskRule.repeatedTaskEndsOnOccurrences;
        }

        const rule = new RRule(rruleOptions);
        const repeatValue = rule.toText();

        onHide({label: repeatValue.charAt(0).toUpperCase() + repeatValue.slice(1), repeatedTaskRule, rule: rule.toString()});
    }

    useEffect(() => {
        togglePosition();
        setTimeout(() => {
            document.addEventListener('click', toggleDropdown);
            document.getElementById('meeting-form')?.addEventListener('scroll', togglePosition);
        }, 1);
    
        return () => {
            document.removeEventListener('click', toggleDropdown)
            document.getElementById('meeting-form')?.removeEventListener('scroll', togglePosition);
        };
    }, []);

    const togglePosition = (e) => {
        const dimPicker = dropdownRef.current.getBoundingClientRect();
        const dimBtn = targetRef.current.getBoundingClientRect();

        //auto 0 auto auto
        if((window.innerHeight - dimBtn.top) > dimPicker.height + 50){
            dropdownRef.current.style.inset = '100% auto auto 0px';
        } else {
            dropdownRef.current.style.inset = 'auto auto 100% 0px';
        }
    }
    
    const toggleDropdown = (e) => {
        if (!e.target.closest('.recurring-task-setting') && !e.target.closest('.recurring-custom-option')) {
          onHide(false);
        }
    }

    useEffect(() => {
        saveRules();
    }, [repeatedTaskRule]);

    const CustomStyle = {
        control: (base, state) => {
           return {
             ...base,
             borderRadius: 50,
             border: theme === "dark" ? '1px solid rgba(255, 255, 255, 0.10) !important' : '1px solid rgba(0, 0, 0, 0.10) !important',
             boxShadow: theme === "dark" ? '0 0 0 1px rgba(255, 255, 255, 0.10) !important' : '0 0 0 1px rgba(0, 0, 0, 0.10) !important',
             background: theme === "dark" ? 'rgba(255, 255, 255, 0.10)' : 'rgba(0, 0, 0, 0.10)',
             display: 'flex',
             padding: '0px 15px',
             justifyContent: 'center',
             alignItems: 'center',
             gap: '10px',
             color: theme === "dark" ? '#fff' : '#000',
             flex: '1 0 0',
             minHeight: 'auto',
           };
        },

        menu: (base) => {
            return {
                ...base,
                background: theme === "dark" ? '#181818' : '#fff',
            }
        },

        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                background: isFocused ? (theme === "dark" ? `linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9)), ${colorPicker} !important` : `linear-gradient(0deg,rgba(0,0,0,0.9),rgba(0,0,0,0.9)), ${colorPicker} !important`) : (isSelected ? (theme === "dark" ? '#000' : '#fff') : ''),
                color: isFocused ? (theme === "dark" ? '#000' : '#fff') : (isSelected ? (theme === "dark" ? '#fff' : '#000') : (theme === "dark" ? '#fff' : '#000')),
            };
        },

        singleValue: (base, state) => {
            return {
                ...base,
                color: theme === "dark" ? `rgba(255, 255, 255, 0.5)` : `rgba(0, 0, 0, 0.5)`,
            };
        }
    } 

    const onOpen = () => {
        dropdownRef.current.classList.remove('dropDownBg');
    }

    const onClose = () => {
        dropdownRef.current.classList.add('dropDownBg');
    }

    return (
        <div ref={dropdownRef} className="box-model changePlan-updates-model md-model-updates recurring-task-setting dropDownBg"
        css={{
            'FormGroup': {
            background: `linear-gradient(123deg, ${colorPicker} 0.03%, #758bb4 99.03%) !important`,
            }
        }}
        style={{ display: 'block' }}>
            <div className="box-model-body">
                <div className="body-data">
                    <form onChange={handleFormChange}>
                        <div className='repeated-task-body' style={{color:theme== "dark" ? "white":"black"}}>
                            <div className="repeated-task-row">
                                <strong>Repeat every</strong>
                                <input type="number" min={1} placeholder='1' name="repeatEvery" value={repeatedTaskRule.repeatEvery} />
                                <Select
                                    options={repeatEveryOptions} 
                                    value={repeatEveryOptions.filter(option => option.value === repeatedTaskRule.periodMode)[0]}
                                    onChange={(value) => handleFormChange({target:{name: "periodMode", value: value.value}})}
                                    components={{ Option: CustomOption }}
                                    styles={CustomStyle}
                                />
                            </div>
                            {
                                (() => {
                                    switch(repeatedTaskRule.periodMode){
                                        case "Weekly":
                                            return (
                                                <div className="repeated-task-row2">
                                                    <strong>Repeat on</strong>
                                                    <div>
                                                        <div className="schedule">
                                                            {days.map(renderDay)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        case "Monthly":
                                            return (
                                                <div className='repeated-task-row2 monthly-options'>
                                                    {
                                                        repeatOnMonthOptions.map((option) => {
                                                            return (
                                                                <div onClick={() => handleFormChange({target:{name: "repeatOnMonth", value: option.value}})} key={option.value}>
                                                                    {option.label}
                                                                    {repeatedTaskRule.repeatOnMonth === option.value && <Tick color={colorPicker} />}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                    }
                                })()
                            }
                            
                            <div className="repeated-task-row3">
                                <strong>Ends</strong>
                                <div className="rules">
                                    <div className="rule">
                                        <input type="radio" value="Never" name="repeatedTaskEndsOnRule" checked={repeatedTaskRule.repeatedTaskEndsOnRule === "Never"}/>
                                        <span>Never</span>
                                    </div>
                                    <div className="rule">
                                        <input type="radio" value="On" name="repeatedTaskEndsOnRule" checked={repeatedTaskRule.repeatedTaskEndsOnRule === "On"}/>
                                        <span>On</span>
                                        <span className="date-picker-rule">
                                            {format(repeatedTaskRule.repeatedTaskEndsOn, 'Do MMMM, YYYY')}
                                            <Datepicker onOpen={onOpen} onClose={onClose} onChange={(value) => handleFormChange({target:{name: "repeatedTaskEndsOn", value}})} value={repeatedTaskRule.repeatedTaskEndsOn} disabled={repeatedTaskRule.repeatedTaskEndsOnRule !== "On"} />
                                        </span>
                                    </div>
                                    <div className="rule">
                                        <input type="radio" value="After" name="repeatedTaskEndsOnRule" checked={repeatedTaskRule.repeatedTaskEndsOnRule === "After"}/>
                                        <span>After</span>
                                        <div className='occurrences'>
                                            <input disabled={repeatedTaskRule.repeatedTaskEndsOnRule !== "After"} type="number" min={1} placeholder='1' name="repeatedTaskEndsOnOccurrences" value={repeatedTaskRule.repeatedTaskEndsOnOccurrences} /> 
                                            occurrences
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <PopupSubmitBtn type={"submit"} onSubmit={saveRules} isLoader={isSubmitting} /> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RepeatedTaskModal