/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React, { useRef } from 'react'
import { useState } from 'react'
import { completedTask, createTask, deleteTask, updateTaskList } from '../../Components/action/common'
import EditTaskList from '../AddTasks/EditTaskList'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAllTaskList, deleteGroupedList, deleteNoDateTaskList, deleteOverDueTaskList, deleteSetTaskList, pushAllTaskList, pushCompletedTaskList, pushGroupedList, pushNoDateTaskList, pushOverDueTaskList, pushSetTaskList, setFocusTasksList, updateAllTaskList, updateAllTaskStatus, updateFocusTasksWithRecurringTask, updateGroupedList, updateGroupedStatus, updateNoDateTaskList, updateNoDateTaskStatus, updateOverDueTaskList, updateOverDueTaskStatus, updateSetTaskList, updateStatus } from '../../redux/slices/TaskSlice'
import toast from 'react-hot-toast'
import Toaster from '../CustomToast/Toaster'
import { SortableHandle } from 'react-sortable-hoc';
import _ from "lodash";
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import { jsx } from "@emotion/core";
import ReactTooltip from 'react-tooltip';
import DragIcon from './DragIcon'
import { CalendarIcon, CalendarIconWithoutNumber, Checkbox, Clock, FolderCloseIcon, MostImportant, TagIcon, Trash } from '../../Components/LandingHeader/Feedback/icons/Icons'
import MostImportantTaskButton from './MostImportantTaskButton'
import Tooltip from '../../Components/common/Tooltip'
import { RRule, RRuleSet, rrulestr } from 'rrule';
import momentTimezone from 'moment-timezone';
import RecurringIcon from './RecurringIcon.js'
import { dateWithOverdueColor } from '../../Components/action/utilities'
import { useNavigate } from 'react-router-dom'
import { useSocket } from '../../SocketContext'
import { decodeToken } from 'react-jwt'
import ConfirmRecurringTask from './ConfirmRecurringTask'

function TaskList({ setDeleteLoader, deleteLoader, fetchTotalCount, currentPage, setSelectedDate, homePage,
  todayTaskList, setTodayTaskList, task,
  setEditTaskListData, fetchAddTaskData, groupDate, fetchTaskListData, isTodayPage = false, taskIndex = null }) {
  const taskTitleRef = useRef('');
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(new Date())
  const [editTaskId, setEditTaskId] = useState(null)

  const navigate = useNavigate();
  const socket = useSocket();

  const { dateChange, focusTasksList, taskList } = useSelector(state => state.task)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const { settings } = useSelector(state => state.settings)

  let dateFormat = settings?.dateFormat ? settings?.dateFormat : "DD/MM/YY"

  const [onDeleteActive, setOnDeleteActive] = useState(false)
  const dateTime = moment(dateChange).format('YYYY-MM-DD')
  const currentDate = moment(dateRange).format('YYYY-MM-DD')
  let taskDate = moment(task?.date).format('YYYY-MM-DD')
  const [showTooltip, setShowTooltip] = useState(false)
  const [deleteRecurringTask, setDeleteRecurringTask] = useState(false)
  const [changeDateRecurringTask, setChangeDateRecurringTask] = useState({})

  const DragHandle = SortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

  const onCompleteTask = async (taskClick, status = 1) => {
    let requestBody = {
      "id": taskClick?.id,
      status
    }
    try {
      if(taskClick?.repeat?.rule){
        const newTaskDate = groupDate ?? dateChange;
        const rruleSet = rrulestr(taskClick?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to complete the current occurrence
        rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        const updatedTask = {id: taskClick.id, date: taskClick?.date, repeat: {...taskClick?.repeat, rule: rruleSet.toString()}}
        await updateTaskList(updatedTask)
        updateTaskListLocally(updatedTask)

        // Extract relevant fields from task object
        const {
          title,
          tags,
          project,
          time,
          week,
          timeZone,
          taskSource
        } = taskClick;
  
        const response = await createTask({
          title,
          tags,
          project,
          time,
          week,
          timeZone, 
          status, 
          completedAt: newTaskDate, 
          date: newTaskDate, 
          repeat: null,
          origTaskId: taskClick?.id,
          orgRepeatId: taskClick?.orgRepeatId,
          taskSource
        })
        createTaskLocally(response?.data?.responseData?.data);
        status !== 0 && toast.custom(<Toaster undoRecurringTasks={[taskClick]} currentPage={currentPage} fetchAddTaskData={undoRecurringTask} deleteTaskData={false} undoRecurringTask={true} title={"Task Complete"} task={taskClick} completeTaskData={false} newTask={response?.data?.responseData?.data} />)
        dispatch(updateFocusTasksWithRecurringTask({oldTask: taskClick, newTask: response?.data?.responseData?.data}));
      }else{
        await completedTask(requestBody);
        status !== 0 && toast.custom(<Toaster currentPage={currentPage} title={"Task Complete"} task={taskClick} completeTaskData={true} />)
        if (currentPage === "upComing") {
          dispatch(updateGroupedStatus(requestBody));
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "home") {
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "overDue") {
          dispatch(updateOverDueTaskStatus(requestBody))
          dispatch(updateStatus(requestBody));
        } else if (currentPage === "allTask") {
          dispatch(updateAllTaskStatus(requestBody))
          dispatch(updateStatus(requestBody));
          fetchTotalCount()
        } else if (currentPage === "noDate") {
          dispatch(updateNoDateTaskStatus(requestBody))
        }
      }
    } catch ({ response }) {

    }
  };

  const undoRecurringTask = (undoRecurringTasks, newCompletedTask = null) => {
    undoRecurringTasks.map(task => {
      updateTaskListLocally({id: task.id, status: task?.status, repeat: task.repeat})
      updateTaskList({id: task.id, status: task?.status, repeat: task.repeat})
      // On undo recurring task, delete the new completed task
      if(newCompletedTask){
        deleteTask({ "id": newCompletedTask?.id });
        updateTaskListLocally({id: newCompletedTask?.id, deletedAt: new Date()});
      }
    })
  }

  const onDeleteClick = () => {
    if(task?.repeat?.rule){
      setDeleteRecurringTask(true)
    }else{
      onDeleteTask(task);
    }
  }

  const onDeleteTask = async (task, recurring = null) => {
    setDeleteRecurringTask(false)
    try {
      let undoRecurringTasks = [task];
      const deleteDate = groupDate ?? dateChange;
      setOnDeleteActive(true)
      // If this is not a recurring task or want to delete all occurrences of the recurring task
      if(recurring === null || recurring === 3){
        dispatch(deleteSetTaskList(task))
        dispatch(deleteGroupedList(task))
        dispatch(deleteOverDueTaskList(task))
        dispatch(deleteAllTaskList(task))
        dispatch(deleteNoDateTaskList(task))
        dispatch(setFocusTasksList(focusTasksList.filter(t => task?.orgRepeatId && t?.orgRepeatId ? t?.orgRepeatId !== task?.orgRepeatId : t?.id !== task?.id)))

        await deleteTask({ "id": task?.id })
        toast.custom(<Toaster currentPage={currentPage} setDeleteLoader={setDeleteLoader} deleteLoader={deleteLoader} fetchAddTaskData={fetchAddTaskData} deleteTaskData={true} task={task} title={"Task Deleted"} fetchTotalCount={fetchTotalCount} />)
        fetchTotalCount()
      }else{
        const dtstart = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).endOf('day').toDate()
        // If this is a recurring task and want to delete the current occurrence
        if(recurring === 1){
          const rruleSet = rrulestr(task?.repeat?.rule, {
            forceset: true,
          })
          rruleSet.exdate(dtstart)
          const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
          await updateTaskList(updatedTask)
          updateTaskListLocally(updatedTask)
        }else{
          undoRecurringTasks = [];
          _.filter(taskList, ["orgRepeatId", task?.orgRepeatId]).map(t => {
            let rruleSet = rrulestr(t?.repeat?.rule ? t?.repeat?.rule : (new RRule({dtstart, freq: RRule.DAILY})).toString(), {forceset: true})
            // Check if the occurrance's until less or greater than deleting date
            let ruleObj = RRule.parseString(rruleSet._rrule.toString());
            let until = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate();
            if(ruleObj?.until && moment(until).isAfter(ruleObj?.until)){
              until = ruleObj.until;
            }
            // If this is a recurring task and want to delete the current occurrence and all future occurrences of the task
            let rrule = new RRule({...ruleObj, until});
            rruleSet._rrule[0] = rrule;
            rruleSet.rrule(rrule);
            let updatedTask = {id: t.id, repeat: {...t?.repeat, rule: rruleSet.toString()}}
            updateTaskList(updatedTask)
            updateTaskListLocally(updatedTask)
            undoRecurringTasks = [...undoRecurringTasks, t]
          })
        }
        // Show undo button
        toast.custom(<Toaster undoRecurringTasks={undoRecurringTasks} fetchAddTaskData={undoRecurringTask} currentPage={currentPage} undoRecurringTask={true} deleteTaskData={false} completeTaskData={false} task={task} title={"Task Deleted"} />)
      }

      setOnDeleteActive(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  const setTaskToDateConfirm = async (task, date, type) => {
    if(task?.repeat?.rule){
      setChangeDateRecurringTask({date, type})
    }else{
      setTaskToDate(task, date);
    }
  }

  const setTaskToDate = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": date
    };
    
    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        let response = await updateTaskList({...requestBody, repeat: null})
        updateTaskListLocally(requestBody)
      }
      fetchTotalCount() 
    } catch ({ response }) {}
  }

  const updateTaskListLocally = (task) => {
    switch (currentPage) {
      case 'upComing':
        dispatch(updateGroupedList(task));
        break;

      case 'overDue':
        dispatch(updateOverDueTaskList(task))
        break;

      case 'allTask':
        dispatch(updateAllTaskList(task))
        break;

      case 'noDate':
        dispatch(updateNoDateTaskList(task))
        break;

      default:
        dispatch(updateSetTaskList(task));
    }
  }

  const createTaskLocally = (task) => {
    switch (currentPage) {
      case 'upComing':
        dispatch(pushGroupedList(task))
        break;
      case 'overDue':
        dispatch(pushOverDueTaskList(task))
        break;
      case 'allTask':
        dispatch(pushAllTaskList(task))
        break;
      case 'noDate':
        dispatch(pushNoDateTaskList(task))
        break;
      case 'completedList':
        dispatch(pushCompletedTaskList(task))
        break;
      default:
        dispatch(pushSetTaskList(task));
    }
  }

  const onDateChange = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": moment(date).startOf('date').toISOString()
    };

    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        let response = await updateTaskList(requestBody)

        if (currentPage === "upComing") {
          dispatch(updateGroupedList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "home") {
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "overDue") {
          dispatch(updateOverDueTaskList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "allTask") {
          dispatch(updateAllTaskList(requestBody))
          dispatch(updateSetTaskList(requestBody));
        } else if (currentPage === "noDate") {
          dispatch(updateNoDateTaskList(requestBody))
          fetchTotalCount()
        }
        setSelectedDate(moment(date).startOf('date').toISOString())
        setDateRange(moment(date).startOf('date').toISOString())
      }

    } catch (e) {
      console.log('error====', e)
    }
  }

  const updateRecurringTask = async (task, date, recurring) => {
    const newTaskDate = groupDate ?? dateChange;
    let rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    // If this is a recurring task and want to edit the current occurrence only
    if(recurring == 1){
      rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
      rruleSet.exdate(momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate())
    }else{
      
      if(recurring == 2){
        // If this is a recurring task and want to edit the current occurrence and all future occurrences of the task
        const rrule = new RRule({...(RRule.parseString(rruleSet._rrule.toString())), until: momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate()});
        rruleSet._rrule[0] = rrule;
        rruleSet.rrule(rrule);
      }
    }
    const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
    await updateTaskList(updatedTask)
    updateTaskListLocally(updatedTask)

    // Create new rule for new task
    rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    let rruleOptions = { dtstart: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    if(recurring == 1){
      rruleOptions = {...rruleOptions, until: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    }else{
      rruleOptions = {...(RRule.parseString(rruleSet._rrule.toString())), ...rruleOptions}
    }

    let newRuleSet = new RRuleSet();
    newRuleSet.rrule(new RRule(rruleOptions));
    const repeat = {...task?.repeat, rule: newRuleSet.toString()};
    // Extract relevant fields from task object
    const {
      title,
      tags,
      project,
      time,
      week,
      timeZone,
      status,
      completedAt,
      isMostImportant
    } = task;

    const response = await createTask({
      title,
      tags,
      project,
      time,
      week,
      timeZone,
      date,
      status,
      completedAt,
      isMostImportant,
      repeat,
      origTaskId: task?.id,
      orgRepeatId: task?.orgRepeatId
    })
    createTaskLocally(response?.data?.responseData?.data);
  }

  const onEditClick = async (task) => {
    setEditTaskId(task.id);
  }

  const addToFocusList = (e) => {
    e.preventDefault();
    const isTaskInFocus = focusTasksList.find(t => t.id === task.id);
    if(!isTaskInFocus){
      const focusTasks = [...focusTasksList, {...task, focus: 1}];
      dispatch(setFocusTasksList(focusTasks));
      triggerWebSocket({ action: "focusTasksList", tasks: focusTasks })
    }

    navigate('/focus-mode-settings');
  }

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
    }
  }

  const removeDropdowns = () => {
    deleteRecurringTask && setDeleteRecurringTask(false);
    changeDateRecurringTask && setChangeDateRecurringTask({});
  }

  const overdue = (((currentPage === "allTask" || currentPage === "overDue") && taskDate != null && taskDate < currentDate) || moment(groupDate ?? dateChange).isBefore(moment(), 'day'));
  const background = theme == "dark" ? `linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #000 !important;` : `linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.80) 100%), linear-gradient(0deg, ${colorPicker} 0%, ${colorPicker} 100%), #FFF !important`;
  const isNowTask = isTodayPage && taskIndex === 0;

  return (
    <>
      {editTaskId === task.id ? <>
        <EditTaskList
          setEditTaskId={setEditTaskId}
          setSelectedDate={setSelectedDate}
          item={task}
          groupDate={groupDate}
          currentPage={currentPage}
          setEditTaskListData={setEditTaskListData}
          fetchAddTaskData={fetchAddTaskData}
        /></> :
        <>
          <li onMouseLeave={removeDropdowns} className={`li-task-list ${isTodayPage ? 'today-page' : ''} ${isNowTask ? 'now-task-row' : ''} task-item${task.id} ${task.isMostImportant === 1 && task?.status == 1 ? 'most-important-task-completed' : ''} ${task?.status == 1 ? 'completed-task' : ''}`}

          style={{ background: task.isMostImportant === 1 && task?.status == 1 ? (theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker}`) : 'none' }}
          css={{
            '&:hover': {
              background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,

            },
            '&:hover .ut-data': {
              color: theme == "dark" ? "white !important" : "gray !important",
            },
            '&:hover span.task-title': {
              color: theme == "dark" ? "white !important" : "black !important"
            },

          }}>
            {task?.status == 0 && <DragHandle />}
            <div className="task-sections" >
              {
                task?.status == 1 &&
                <>
                  <Checkbox defaultChecked="checked" theme={theme} name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task, 0)}  colorPicker={colorPicker}/>
                  <span ref={taskTitleRef} css={{
                      color: theme == "dark" ? "white !important" : "black important",
                    }}
                    className='task-title' data-tip data-for={`longText-${task?.id}`}>{task.title} </span>
                </>
              }
              {
                task?.status == 0 &&
                <>
                  <Checkbox theme={theme} overdue={overdue} name={task?.id} defaultValue={task?.id} onClick={() => {onCompleteTask(task)}} colorPicker={colorPicker}/>
                  <span
                    ref={taskTitleRef}
                    css={{
                      color: theme == "dark" ? "white !important" : "black important",
                    }}
                    className={`task-title ${taskIndex === 0 && isTodayPage ? 'darkPrimaryColor' : ''}`} data-tip data-for={`longText-${task?.id}`} onClick={() => onEditClick(task)}>{task?.title}
                  </span>
                </>
              }
              {
                changeDateRecurringTask.type === 'datepicker' && <ConfirmRecurringTask iconComponent={<CalendarIcon />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDateChange(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
              }
              <div className='task-meta-wrapper'>
                {
                  (currentPage === "allTask" || currentPage === "overDue") && task?.date !== null 
                  && 
                  <span className="task-deta">
                    <CalendarIconWithoutNumber color={overdue ? '#D95555' : 'black'} width={12} height={13} />
                    <label className="ut-data" css={{color: theme == "dark" ? "white !important" : "gray !important"}}>
                    { dateWithOverdueColor(task?.date, dateFormat) }
                    </label>
                  </span>
                }
                {task.taskSource !== null && (
                  <Tooltip className='overDue-tooltip tooltip-mt0' text={task.taskSource} wrapperClassName="relative" color={colorPicker}> 
                    <a className="hovr-btn tooltip-hover">
                      <span
                        key={"task-" + task.id}
                        className=" flex items-center justify-center gap-2"
                      >
                        <img width={15} src={`/images/${task.taskSource}.png`} alt={task.taskSource} />
                      </span>
                    </a>
                  </Tooltip>
                )}
                <RecurringIcon task={task} overdue={overdue} colorPicker={colorPicker} />
                {task.Project && (
                  <span
                    key={"task-" + task.id}
                    className=" flex items-center justify-center gap-2"
                  >
                    <FolderCloseIcon />
                    <span className=" text-sm">
                      {_.capitalize(task.Project.title)}
                    </span>
                  </span>
                )}
                {task.Tags &&
                  task.Tags.map(({ name, tagId }) => (
                    <span
                      key={tagId}
                      className=" flex items-center justify-center gap-2"
                    >
                      <TagIcon />
                      <span className=" text-sm">
                        {_.capitalize(name)}
                      </span>
                    </span>
                  ))}
              </div>
            </div>
            {
            task.isMostImportant === 1 
            &&
            (
                task?.status === 1
                ?
                <span className="most-important-task" css={{ background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), ${colorPicker}` : `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}` }}><span className='darkPrimaryColor line-height-normal f-weight-500 font-family rl'>A successful day!</span></span>
                :
                <span className="most-important-task" css={{ background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%), ${colorPicker}` : `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${colorPicker}` }}><MostImportant color={colorPicker} fill={colorPicker} /> <span className='darkPrimaryColor line-height-normal f-weight-500'>Task of the Day</span></span>
            )
            }
            {
              showTooltip && <ReactTooltip delayShow={500} id={`longText-${task?.id}`} place="right" effect="solid" className="reactTooltipCss"> {task?.title} </ReactTooltip>
            }
            <div className="on-hover-list">
              <ul className="hover-action">
                <MostImportantTaskButton theme={theme} color={theme == "dark" ? "grey" : "grey"} task={task} colorPicker={colorPicker} newTaskDate={task?.date ? new Date(moment(groupDate ?? dateChange).startOf('date')).toISOString() : null} currentPage={currentPage} />
                
                {
                  (overdue || !moment(currentDate).isSame(moment(groupDate ?? dateChange), 'day'))
                  && 
                  <li data-tip data-for="todayTaskTooltip" className="hover-action-li" style={{ cursor: "pointer" }} onClick={() => setTaskToDateConfirm(task, new Date(moment(currentDate).startOf()).toISOString(), 'today')}> 
                    <Tooltip text="Move to today" className='overDue-tooltip' color={colorPicker}>
                      <a className="hovr-btn tooltip-hover" style={{ cursor: "pointer", background: theme == 'dark' ? '#000000' : '#ffffff' }}><Clock color="grey" /></a>
                    </Tooltip>
                    {
                      changeDateRecurringTask.type === 'today' && <ConfirmRecurringTask iconComponent={<Clock color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => setTaskToDate(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
                    }
                  </li>

                }
                {
                  !moment().startOf('date').add(1, 'days').isSame(moment(groupDate ?? dateChange), 'day') && task.status != '1' 
                  && 
                  <li data-tip data-for="tomorrowTaskTooltip" className="hover-action-li" onClick={() => setTaskToDateConfirm(task, moment().startOf('date').add(1, 'days').toISOString(), 'tomorrow')}>
                    <Tooltip text="Move to tomorrow" className='overDue-tooltip' color={colorPicker}>
                      <a className="hovr-btn tooltip-hover" style={{ cursor: "pointer", background: theme == 'dark' ? '#000000' : '#ffffff' }}><Clock color="grey" /></a>
                    </Tooltip>
                    {
                      changeDateRecurringTask.type === 'tomorrow' && <ConfirmRecurringTask iconComponent={<Clock color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => setTaskToDate(task, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
                    }
                  </li>
                }
                <li className="hover-action-li">
                  <span className='date-picker-wrapper'>
                    <button className="date-picker-icon v2" type="button" style={{ backgroundColor: theme == 'dark' ? '#000000' : '#ffffff' }}></button>
                    <Datepicker onChange={(date) => { setTaskToDateConfirm(task, date, 'datepicker') }} value={dateTime} />
                  </span>
                </li>
                <li className="hover-action-li" data-tip data-for="deleteTaskTooltip" style={{ cursor: "pointer" }} onClick={onDeleteClick}> 
                    <Tooltip className='overDue-tooltip' text="Delete task" color={colorPicker}>
                      <a style={{ background: theme == 'dark' ? '#000000' : '#ffffff' }} className={`hovr-btn ${onDeleteActive ? 'active' : ''}`}>
                        <Trash color="grey" />
                      </a>
                    </Tooltip>
                    {
                      deleteRecurringTask && <ConfirmRecurringTask iconComponent={<Trash color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDeleteTask(task, recurring)} onHide={() => setDeleteRecurringTask(false)} />
                    }
                  {/* <Tooltips/> */}
                </li>
              </ul>
            </div>
          </li>
        </>
      }
    </>
  )
}

export default TaskList