/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import BackgroundImage from "../common/BackgroundImage";
import AIAssistant from "../SideBar/AIAssistant";

function Fullscreen() {
  const { themeColor, colorPicker, theme } = useSelector(state => state.theme);

  return (
    <div className="form-content ai-assistant settingTabs" style={{filter:"drop-shadow(0px 4px 250px rgba(39, 203, 154, 0.1))"}}>
        <div className="setting-form">
            <div className="setting-tabs quoteSection ai-assistant-fullscreen">
                <AIAssistant defaultMaximize={true} />
            </div>
        </div>
    </div>
  )
}

export default Fullscreen
