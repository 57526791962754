import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
  colorPicker: "#27CB9A",
  colorPickerRGB: 'rgb(39 203 154 / 10%)',
  newBackgroundPopup: false,
  themeColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF',
  theme: 'light',
  colorPickerMode: "Light"
}

const ThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setColorPickerMode(state, action) {
      state.colorPickerMode = action.payload
    },
    setTheme(state, action) {
      state.theme = action.payload
    },

    setThemeColor(state, action) {
      state.themeColor = action.payload
    },

    setNewBackgroundColor(state, action) {
      state.newBackgroundPopup = action.payload
    },

    setColorPicker(state, action) {
      state.colorPicker = action.payload
    },
    setColorPickerRGB(state,action){
      state.colorPickerRGB=action.payload
    }


  }
})


// reducers exports
export const {
  
  setColorPickerMode,
  setTheme,
  setThemeColor,
  setNewBackgroundColor,
  setColorPicker,
  setColorPickerRGB

} = ThemeSlice.actions;

export default ThemeSlice.reducer;