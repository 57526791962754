import React from 'react'
import {useSelector} from 'react-redux'

function SortIcon() {
  const {theme} =useSelector(state=>state.theme)
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33337 3.83331H10" stroke={theme == "dark" ? "white" :""} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.33337 6.5H12" stroke={theme == "dark" ? "white" :""} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.33337 9.16669H14" stroke={theme == "dark" ? "white" :""} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 11.8333L4 13.8333L6 11.8333" stroke={theme == "dark" ? "white" :""} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 12.5V3.16669" stroke={theme == "dark" ? "white" :""} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default SortIcon