/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import RunningFocusTimer from './RunningFocusTimer';
import { pushSetTaskList, setFocusTasksList, setTaskList, updateSetTaskList, updateStatus } from "../../redux/slices/TaskSlice";
import { completedTask, createTask, updateTaskList } from "../action/common";
import { useDispatch } from "react-redux";
import BackgroundImage from "../common/BackgroundImage";
import FocusTimerBar from "./FocusTimerBar";
import moment from "moment";
import { arrayMoveImmutable } from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import DragIcon from "../../pages/Tasks/DragIcon";
import { useNavigate } from "react-router-dom";
import AddTask from "../../pages/AddTasks/AddTask";
import EditTaskList from "../../pages/AddTasks/EditTaskList";
import { useSocket } from "../../SocketContext";
import { rrulestr } from "rrule";
import momentTimezone from "moment-timezone";
import RecurringIcon from "../../pages/Tasks/RecurringIcon";
import { Checkbox } from "../LandingHeader/Feedback/icons/Icons";

const DragHandle = sortableHandle(() => <span className="dragdrop-option"><DragIcon /></span>);

const SortableItem = sortableElement(({children, task, editTaskHandler}) => (
  <li className="task-item li-task-list" onClick={e => editTaskHandler(e, task.id)}
    css={{
      '&:hover': {
        background: `none !important`,
      }
    }}
    >
    <DragHandle />
    {children}
  </li>
));

const SortableContainer = sortableContainer(({children}) => {
    
    return (
      <ul className="ul-task-list incomplete-tasks">{children}</ul>
    )
});

function FocusModeStart() {
  const [editTask, setEditTask] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);
  const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
  const {focusTasksList, taskList} = useSelector(state => state.task);
  const focusTimer = useSelector(state => state.focusTimer);
  const {focusSessions, pause, start, activeTimer, fullscreen} = focusTimer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();

  // useEffect(() => {
  //   // Trigger timer in wepapp and other clients
  //   if (socket !== null){
  //     const user = decodeToken(localStorage.getItem('token'));
  //     socket.emit('focusTimer', {room: user['outseta:accountUid'], data: {timer: focusTimer, tasks: focusTasksList}});
  //   }
  // }, [])

  useEffect(() => {
    if(pause === true){
      navigate("/focus-mode-completed");
    }
  }, [pause]);

  useEffect(() => {
    if(updatedValue !== null){
      dispatch(setFocusTasksList([...focusTasksList].map(task => task.id == updatedValue.id ? {...task, title: updatedValue.title} : task)));
    }
  }, [updatedValue])

  const onCompleteTask = async (task, status = 1) => {
    let requestBody = {
      "id": task?.id,
      status
    }
    try {
      let tasks = [...focusTasksList];
      if(task?.repeat?.rule){
        const newTaskDate = moment().startOf('date').toISOString();
        const rruleSet = rrulestr(task?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to complete the current occurrence
        rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
        await updateTaskList(updatedTask)
        dispatch(updateSetTaskList(updatedTask));

        // Extract relevant fields from task object
        const {
          title,
          tags,
          project,
          time,
          week,
          timeZone,
          taskSource
        } = task;
  
        const response = await createTask({
          title,
          tags,
          project,
          time,
          week,
          timeZone, 
          status, 
          completedAt: newTaskDate, 
          date: newTaskDate, 
          repeat: null,
          origTaskId: task?.id,
          orgRepeatId: task?.orgRepeatId,
          taskSource
        })
        dispatch(pushSetTaskList(response?.data?.responseData?.data));
        tasks = [...focusTasksList].map(t => t.id == task.id ? response?.data?.responseData?.data : t);
      }else{
        await completedTask(requestBody);
        tasks = [...focusTasksList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : null } : t);
        const todayTasks = [...taskList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : null } : t);
        dispatch(setTaskList(todayTasks));
      }
      dispatch(setFocusTasksList(tasks));
    } catch ({ response }) {

    }
    // try {
    //   await completedTask(requestBody);
    //   const tasks = [...focusTasksList].map(t => t.id == task.id ? { ...t, status, completedAt: status == 1 ? new Date() : task.completedAt } : t);
    //   dispatch(setFocusTasksList(tasks));
    // } catch ({ response }) {}
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newList = arrayMoveImmutable(focusTasksList, oldIndex, newIndex);
    dispatch(setFocusTasksList(newList))
  };

  const editTaskHandler = (e, id) => {
    if(!e.target?.closest('.check-container'))
      setEditTask(id);
  }

  console.log('editTaskeditTaskeditTask', editTask)

  return (
    <div className={`main-body-wrap break-focus-page break-timer-start focus-mode-timer-start ${theme == "dark" ? "dark-mode" : ""}`} style={{
      background: theme == 'dark' ? '#000' : '#fff'
    }}>
      <div className={`backgroundBottom ${theme}`} style={{bottom: 0}}>
        <BackgroundImage height="975" />
      </div>
      <div className="page-wrapper focus-mode-start" id="focus-mode-settings-page">
        <div className="page-container">
          <div className="quoteSection breakTime">
            <RunningFocusTimer defaultMaximizTimer={false} focusMode={true} display={false} />
          </div>
          <div className={`tasks-list-container ${theme}`}>
            <div className="focused-items-heading">
              Focus Session {focusSessions.filter(session => moment().format('L') === moment(session.startTime).format('L')).length} {focusTasksList.length > 0 ? <span style={{color: colorPicker}}>{focusTasksList.filter(t => t.status == 1).length} Completed</span> : null}<span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}}> / {focusTasksList.length}</span>
            </div>
            <div className="focus-mode-new-task">
              <div className="focus-session-tasks" style={{background: 'transparent'}}>
                <SortableContainer onSortEnd={onSortEnd} helperContainer={document.querySelector('.ul-task-list')} useDragHandle>
                  {focusTasksList.map((task, index) => (
                    task.status == 0 ?
                    (
                      editTask === task.id
                        ?
                        <EditTaskList hideActions={true} item={task} setUpdatedValue={setUpdatedValue} setEditTaskId={setEditTask} />
                        :
                        <SortableItem key={`item-${task.id}`} index={index} editTaskHandler={editTaskHandler} task={task}>
                          <>
                            <Checkbox theme={theme} name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task)} colorPicker={colorPicker}/>
                            <span
                                css={{
                                    color: theme == "dark" ? "white !important" : "black !important",
                                }}
                                className='task-title' data-tip data-for={`longText-${task?.id}`}>{task?.title}
                                <RecurringIcon task={task} colorPicker={colorPicker} />
                            </span>
                          </>
                        </SortableItem>
                    )
                    :
                    null
                  ))}
                </SortableContainer>
                <ul className="ul-task-list">
                {
                    focusTasksList.map(task => {
                        return task.status == 1 ? <li className="task-item li-task-list completed-task" key={task.id}
                        css={{
                          '&:hover': {
                            background: `none !important`,
                          }
                        }}
                        >
                          <Checkbox theme={theme} defaultChecked={true} name={task?.id} defaultValue={task?.id} onClick={e => onCompleteTask(task, 0)} colorPicker={colorPicker}/>
                          <span
                              css={{
                                  color: theme == "dark" ? "white !important" : "black !important",
                              }}
                              className='task-title' data-tip data-for={`longText-${task?.id}`}><s>{task?.title}</s>
                          </span>
                      </li> : null
                    })
                }
                </ul>
                <AddTask focusTimerState={{pause, start, activeTimer, fullscreen}} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <FocusTimerBar showSec={true} />
      </div>
    </div>
  )
}

export default FocusModeStart