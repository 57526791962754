import React from 'react'
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { createTask, toggleMostImportantFlag, updateTaskList } from '../../Components/action/common';
import Tooltip from '../../Components/common/Tooltip';
import { MostImportant } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { pushAllTaskList, pushCompletedTaskList, pushGroupedList, pushNoDateTaskList, pushOverDueTaskList, pushSetTaskList, updateAllTaskList, updateGroupedList, updateMostImportantFlag, updateNoDateTaskList, updateOverDueTaskList, updateSetTaskList } from '../../redux/slices/TaskSlice';
import { rrulestr } from 'rrule';
import momentTimezone from 'moment-timezone';

const MostImportantTaskButton = ({ task, colorPicker, theme, color = "black", fill = "none", newTaskDate, currentPage }) => {
    const dispatch = useDispatch();
    const active = task.isMostImportant === 1;

    const toggle = async (e) => {
        e.preventDefault();
        const flag = task.isMostImportant ? 0 : 1;
        
        if(task?.repeat?.rule){
            const rruleSet = rrulestr(task?.repeat?.rule, {
              forceset: true,
            })
            // If this is a recurring task and want to complete the current occurrence
            rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
            const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
            await updateTaskList(updatedTask)
            updateTaskListLocally(updatedTask)

            // Extract relevant fields from task object
            const {
                title,
                status,
                tags,
                project,
                time,
                week,
                timeZone,
                taskSource
            } = task;
            const response = await createTask({
                title,
                status,
                tags,
                project,
                time,
                week,
                timeZone, 
                isMostImportant: flag, 
                date: newTaskDate, 
                repeat: null,
                origTaskId: task?.id,
                orgRepeatId: task?.orgRepeatId,
                taskSource
            })
            
            createTaskLocally(response?.data?.responseData?.data);
            dispatch(updateMostImportantFlag(response?.data?.responseData?.data));
        }else{
            toggleMostImportantFlag({ id: task.id, flag, date: task.date }).then(response => {
                dispatch(updateMostImportantFlag({ id: task.id, isMostImportant: flag, date: task.date }));
            });   
        }
    }

    const updateTaskListLocally = (task) => {
        switch (currentPage) {
        case 'upComing':
            dispatch(updateGroupedList(task));
            break;

        case 'overDue':
            dispatch(updateOverDueTaskList(task))
            break;

        case 'allTask':
            dispatch(updateAllTaskList(task))
            break;

        case 'noDate':
            dispatch(updateNoDateTaskList(task))
            break;

        default:
            dispatch(updateSetTaskList(task));
        }
    }

    const createTaskLocally = (task) => {
        switch (currentPage) {
        case 'upComing':
            dispatch(pushGroupedList(task))
            break;
        case 'overDue':
            dispatch(pushOverDueTaskList(task))
            break;
        case 'allTask':
            dispatch(pushAllTaskList(task))
            break;
        case 'noDate':
            dispatch(pushNoDateTaskList(task))
            break;
        case 'completedList':
            dispatch(pushCompletedTaskList(task))
            break;
        default:
            dispatch(pushSetTaskList(task));
        }
    }

    return (
        <li className="hover-action-li" data-tip data-for="importantTaskTooltip" style={{ cursor: "pointer" }}>
            <Tooltip className="delete-tooltip overDue-tooltip" text={`${!active ? 'Set as' : 'Remove as'} task of the day`} color={colorPicker}>
                <a href="#" onClick={toggle} className="hovr-btn" style={{ background: active ? colorPicker : (theme == 'dark' ? '#000000' : '#ffffff') }}>
                    {/* <MostImportant color={!active ? (theme != 'dark' ? 'grey' : '#ffffff') : color} fill={!active ? (theme == 'dark' ? 'grey' : '#ffffff') : fill} /> */}
                    <MostImportant color={active ? (theme == 'dark' ? '#000000' : '#ffffff') : color} fill={active ? (theme == 'dark' ? '#000000' : '#ffffff') : fill} />
                </a>
            </Tooltip>
            {/* <ReactTooltip id="importantTaskTooltip" delayShow={500} place="bottom" effect="solid" className="reactTooltipCss" >
                {!active ? 'Add' : 'Remove'} as task of the day
            </ReactTooltip> */}
        </li>
    )
}

export default MostImportantTaskButton