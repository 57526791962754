import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PreExpire from './PreExpire';
import PostExpire from './PostExpire';
import { AuthContext } from '../../AuthProvider';

const SubscriptionPlan = () => {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
    const { subscription } = useContext(AuthContext);

    return (
        !subscription?.isFreeTrial ? null :
        <div className="main-body-wrap" css={{overflow: "auto"}}>
            <div className={`page-wrapper ${theme}`} id="admin-page">
                <div className="page-container">
                    {
                        subscription?.accountStage > 3
                        ?
                        <PostExpire colorPicker={colorPicker} theme={theme} />
                        :
                        <PreExpire data={subscription} colorPicker={colorPicker} theme={theme} />
                    }
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlan;
