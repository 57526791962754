// SignupForm.js
import React, { useEffect } from 'react';
import { loadOutseta } from '../Outseta';

const ExperimentalSignupForm = () => {
    const OPTIONS = {
        domain: "lifetab.outseta.com",
        load: "auth",
        auth: {
          widgetMode: "register",
          planUid: "dQGAK0W4", // Experimental Group
          planPaymentTerm: "month",
          skipPlanOptions: true,
          id: "signup_embed",
          mode: "embed",
          selector: "#outseta-signup-form"
        }
    };

    useEffect(() => {
        sessionStorage.clear();
        loadOutseta(OPTIONS);
    }, []);

    return (
        <div id="outseta-signup-form-wrapper">
            <div id="outseta-signup-form" className="experimental"><h3 style={{textAlign: 'center'}}>Loading ... kindly wait.</h3></div>
            <input type="hidden" id="outseta-plan-uid" value="dQGAK0W4" />
            <a href="/login">Already have an account? Click here to login.</a>
        </div>
    );
};

export default ExperimentalSignupForm;

