import { SITE_BASE_URL } from "../common/constants"

const PostExpire = ({colorPicker, theme}) => {
    return (
        <div id="subscription-plan" className="post">
            <div className='part1 post'>
                <h2>Upgrade and lock in our Early Supporter pricing</h2>
                <h1>Your trial has expired.</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Massa gravida sed ornare mauris sit. Integer dolor mattis eget quis odio.</p>
                <a href={`${SITE_BASE_URL}/plan-upgrade`} className='btn' style={{background: colorPicker, color: theme === 'light' ? '#fff' : '#000', marginTop: "30px"}}>Upgrade <span className="darkColor darkColor1">$6/Month</span></a>
                <div className='feature'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" fill="none">
                        <path d="M0 8L7.5 0L15 8L0 8Z" fill="#D9D9D9"/>
                    </svg>
                    <p>Early Supporter Plan</p>
                    <p>Our lowest pricing</p>
                </div>
            </div>
        </div>
    )
}

export default PostExpire