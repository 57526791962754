import React from 'react'
import { useCallback } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import AddTask from '../AddTasks/AddTask'
import TaskListDetails from './TaskListDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setDateChange, setNoDateTaskList, setTotalCount } from '../../redux/slices/TaskSlice'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import { Accordion } from 'react-accessible-accordion'
import InfiniteScroll from 'react-infinite-scroll-component' 
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader'
import _ from 'lodash'

function NoDate() {
  const dispatch=useDispatch()

  // global state
  const { noDataTaskList} = useSelector(state=>state.task)
  const {theme} = useSelector(state=>state.theme)
  const [list,setList]=useState([])
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes));
  const [isLoader,setIsLoader]=useState(true)

  const fetchAddTaskData = async () => {
    setIsLoader(true)
    try {
      let response = await fetchTaskList({"type":4})
      const noDataTaskList = _.chunk(response?.data?.responseData.filter(t => t.status == 0 && t.date === null), 15);
      const chunk = noDataTaskList.splice(0, 1);
      setList(noDataTaskList)
      dispatch(setNoDateTaskList(chunk?.[0] ?? []))
      setIsLoader(false)
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const fetchTotalCount=async()=>{
    try {
      let response=await totalTaskCount({"date":currentTime.toISOString()})
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({response}) {
      
    }
  }


  useEffect(() => {
    dispatch(setDateChange(null))
    fetchAddTaskData()
    fetchTotalCount()
  }, [])

  const fetchMoreData = () => {
    const chunk = list.splice(0, 1);
    if(chunk.length > 0){
      setList(list)
      dispatch(setNoDateTaskList([...noDataTaskList, ...chunk[0]]))
    }
  }

  return (
    <>
      {
        isLoader ? <AllLoader/> :  <>
        <div id="tasks-list-wrapper" className="overdue-form task-list-page" style={{background:theme == "dark" ? "black" :"white"}}>
          <AddTask noDate={true} noDataTaskList={noDataTaskList}  fetchTotalCount={fetchTotalCount}  currentPage="noDate"/>
          <div className="task-list" >
            <div className="task-list-data">
              <div className="ul-task-list">
                <div className="task-list" style={{background:theme == "dark" ? "black" :"white"}}>
                <InfiniteScroll
                    dataLength={noDataTaskList?.length ?? 0}
                    next={fetchMoreData}
                    hasMore={list.length > 0}
                    loader={<UpcomingLoader  />}
                    scrollableTarget="tasks-list-wrapper"
                  >
                  <Accordion preExpanded={['accitem0']}>
                    <TaskListDetails 
                    fetchAddTaskData={fetchAddTaskData}
                    noDataTaskLists={noDataTaskList}
                    currentPage="noDate"
                    fetchTotalCount={fetchTotalCount}
                    />
                  </Accordion>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }
   
  </>
  )
}

export default NoDate