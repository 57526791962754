/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import { useDispatch } from 'react-redux';
import { setCurrentPersona, setCurrentPrompt } from '../../redux/slices/AiAssistant';
import { ChatIcon, DrawerIcon, HistoryIcon, Trash } from '../LandingHeader/Feedback/icons/Icons';
import { CHATGPT } from '../common/constants';
import HistoryTab from './HistoryTab';
import { useSelector } from 'react-redux';

export default function ChatTabs({chatInputRef, theme, colorPicker}) {
    const [ChatulTabs, setchatulTabs] = useState(0);
    const dispatch = useDispatch();
    const {persona} = useSelector(state =>  state.aiAssistant);

    const setPrompt = (id, prompt) => {
        chatInputRef.current.value = prompt.description;
        chatInputRef.current.style.height = '20px';
        chatInputRef.current.style.height = chatInputRef.current.scrollHeight + "px";
        chatInputRef.current.focus();
        dispatch(setCurrentPrompt(prompt.description));
        document.querySelector(id).classList.add('msgadded');

        setTimeout(() => {
            document.querySelector(id)?.classList?.remove('msgadded')
        }, 1000)
    }

    const setPersona = (persona) => {
        dispatch(setCurrentPersona(persona));
    }

    return (
        <>
            <div className='chatSidebar '>
                <div className='chatHeader-tabs'>
                    <ul className='chat-tabs-ul'>
                        <li  className={`chat-tabs-li ${ChatulTabs == 0 ? 'active' : ''}`} onClick={() => setchatulTabs(0)}>
                            <div className='chat-tabs'>
                                <i className='tabIco'> <img src='/images/clock-8.svg' className='tabIcoimg' /> </i>
                                <span className='tabText'>History</span>
                            </div>
                        </li>
                        <li  className={`chat-tabs-li ${ChatulTabs == 1 ? 'active' : ''}`} onClick={() => setchatulTabs(1)}>
                            <div className='chat-tabs'>
                                <i className='tabIco'> <img src='/images/book-open.svg' className='tabIcoimg' /> </i>
                                <span className='tabText'>Prompts</span>
                            </div>
                        </li>
                        <li  className={`chat-tabs-li ${ChatulTabs == 2 ? 'active' : ''}`} onClick={() => setchatulTabs(2)}>
                            <div className='chat-tabs'>
                                <i className='tabIco'> <img src='/images/personas.svg' className='tabIcoimg' /> </i>
                                <span className='tabText'>Personas</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='tabContent'>
                    {
                        ChatulTabs == "0" ? 
                        <HistoryTab theme={theme} colorPicker={colorPicker} chatInputRef={chatInputRef} />
                        :  ChatulTabs == "1" ?
                        <>
                            <div className='allNotes' id="promptsList">
                                {
                                    CHATGPT.PROMPTS.map((prompt, index) => {
                                        return (
                                            <div id={`prompt${index}`} onClick={e => setPrompt(`#prompt${index}`, prompt)} key={index} className={`notes-cards`} css={{
                                                '&.msgadded': {
                                                    background: theme == 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #000;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;`
                                                },
                                                '&:hover': {
                                                    background: theme == 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #000;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;`
                                                }
                                            }}>
                                                <div className='noteTitle'>
                                                    {prompt.label}
                                                </div>
                                                <div className='notesContent'>
                                                    <p>{prompt.role}</p>
                                                </div>
                                                <div className='whileAdd'>
                                                    <span className='addedMsg' style={{background: colorPicker}}>Added!</span>
                                                </div>
                                            </div> 
                                        )
                                    })
                                }
                            </div>
                            
                        </>
                        :  ChatulTabs == "2" ?
                        <div className='allNotes'>
                            {
                                CHATGPT.PERSONAS.map((person, index) => {
                                    return (
                                        <div onClick={e => setPersona(person)} key={index} className={`notes-cards ${persona && persona.value == person.value ? 'msgadded' : ''}`} css={{
                                            '&.msgadded': {
                                                background: theme == 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #000;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;`
                                            },
                                            '&:hover': {
                                                background: theme == 'dark' ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #000;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF;`
                                            }
                                        }}>
                                            <div className='noteTitle'>
                                                {
                                                    persona && persona.value == person.value
                                                    ?
                                                    <strong>Talking to<span style={{color: colorPicker, marginLeft: '5px'}}>{persona.label}</span></strong>
                                                    :
                                                    person.label
                                                }
                                            </div>
                                            <div className='notesContent'>
                                                <p>{person.role}</p>
                                            </div>
                                        </div> 
                                    )
                                })
                            }
                        </div>
                        : ''
                    }
                   
                </div>
            </div>
        </>
    )
}
