/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { fetchSetting, setSetting } from '../../Components/action/common'
import { useDispatch, useSelector } from 'react-redux'
// import { pushToSetting, setSettings } from '../../redux/slices/TaskSlice'
import { setColorPicker, setColorPickerMode, setNewBackgroundColor, setTheme, setThemeColor } from '../../redux/slices/ThemeSlice'
import Select from 'react-select';

import { GithubPicker } from 'react-color';
import { useNavigate } from 'react-router-dom'
import { jsx } from "@emotion/core";
import GeneralIcon from './GeneralIcon'
import { pushToSetting, setAddQuickLinkClick } from '../../redux/slices/SettingSclice'
import {TODOIST} from '../../Components/common/constants';
import { LinkIcon } from '../../Components/LandingHeader/Feedback/icons/Icons'
// import { setAddQuickLinkClick } from '../../redux/slices/CardSlice'

function General({ activeTab, setActiveTab }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const todoistEmailId = useRef('');

  // global state
  const { settings } = useSelector(state => state.settings)
  const { colorPicker, theme, colorPickerMode } = useSelector(state => state.theme)
  const [gernalSection, setGernalSection] = useState(false)
  const [onMouseEnterGeneral, setOnMouseEnterGeneral] = useState(false)
  const timeFormatOptions = [
    { value: 'LT', label: '1:00 PM' },
    { value: 'HH:mm', label: '13:00' }
  ];
  const dateFormatOptions = [
    { value: 'DD/MM/YY', label: 'DD/MM/YY' },
    { value: 'MM/DD/YY', label: 'MM/DD/YY' }
  ]
  const weekStartOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
  ]
  const themeOptions = [
    { value: 'Light', label: 'Light' },
    { value: 'Dark', label: 'Dark' }
  ]
  const dailyTaskLimitOptions = [
    { value: 'No Limit', label: 'No Limit' }
  ]
  const transferTaskOptions = [
    { value: 'Transfer to Next Day', label: 'Transfer to Next Day' },
    { value: 'Transfer to Next Week', label: 'Transfer to Next Week' },
    { value: 'Transfer to Next Month', label: 'Transfer to Next Month' }
  ]
  const popover = {
    position: 'absolute',
    zIndex: '99999',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onChangeHandler = async (e) => {
    const { value, name } = e.target;
    if (name == "theme" && value == "Dark") {
      dispatch(setTheme("dark"));
      dispatch(setThemeColor("linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;"))
      dispatch(setColorPickerMode(value));
    } else if (name == "theme" && value == "Light") {
      dispatch(setTheme("light"));
      dispatch(setColorPickerMode(value));
      dispatch(setThemeColor("linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #fffff;"))
    }

    const newSettings = { ...settings, [name]: value };
    dispatch(pushToSetting(newSettings));
    try {
      let response = await setSetting({ "settings": newSettings })
    } catch ({ response }) { }
  }

  const fetchSettingData = async () => {
    try {
      let response = await fetchSetting()
      dispatch(pushToSetting(response?.data?.responseData?.settings));
      dispatch(setColorPicker(response?.data?.responseData?.settings?.accentColor ?? colorPicker))
      if (response?.data?.responseData?.settings?.theme == "dark") {
        dispatch(setColorPickerMode("Dark"));
      } else if (response?.data?.responseData?.settings?.theme == "light") {
        dispatch(setColorPickerMode("Light"));
      }

    } catch ({ response }) {

    }
  }

  const onColorChange = () => {
    dispatch(setNewBackgroundColor(true))
    navigate(process.env.REACT_APP_ROOT)
  }

  const handleChange = async (value, name) => {
    console.log('value=====', value)
    if (name == "theme" && value?.value == "Dark") {
      dispatch(setTheme("dark"));
      dispatch(setThemeColor("linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;"))
      dispatch(setColorPickerMode(value));
    } else if (name == "theme" && value?.value == "Light") {
      dispatch(setTheme("light"));
      dispatch(setColorPickerMode(value));
      dispatch(setThemeColor("linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #fffff;"))
    }
    const newSettings = { ...settings, [name]: value?.value, utcOffset: new Date().getTimezoneOffset() };
    dispatch(pushToSetting(newSettings));
    // console.log(name, value.value, { ...settings, 'testing': value?.value }); return;
    try {
      let response = await setSetting({ "settings": newSettings })
    } catch ({ response }) { }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      // background: 'papayawhip',
      padding: "0px"
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'none'
        : isSelected
          ? 'linear-gradient(0deg, #27CB9A, #27CB9A), #ffffff !important;'
          : undefined,
      zIndex: 1,
      border: "none",
      boxShadow: "none",
      height: "51px",
      borderRadius: "10px",
      // background:"linear-gradient(0deg, #27CB9A, #27CB9A), #ffffff !important"
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }


  useEffect(() => {
    fetchSettingData()
  }, [])

  const disconnectTodoist = (e) => {
    e.preventDefault();

    handleChange({value: ''}, 'todoist_verification_token');
  }

  const connectTodoist = (e) => {
    e.preventDefault();
    const api = `${TODOIST.API}?client_id=${TODOIST.CLIENT_ID}&scope=${TODOIST.SCOPE}&state=${localStorage.getItem('token')}`;
    const popup = popupWindow(api, 600, 600);
    const interval = setInterval(() => {
      if (popup.closed) {
          clearInterval(interval);
          console.log('Popup Closed');
          fetchSettingData();
      }
    }, 500);
  }

  const popupWindow = (url, w, h) => {
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

    return window.open(url, 'Todoist Auth', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  }

  return (
    <li
      css={{
        '&:hover .accordion-title': {
          color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c")) ? "white !important" : (theme == "dark" && colorPicker == "#f8961e") ? "black !important" : (theme == "dark" && colorPicker == "#ffca3a") ? "black !important" : (theme == "dark" && colorPicker == "#27cb9a") ? "black !important" : (theme == "dark" && colorPicker == "#4cc9f0") ? "black !important" : "white !important") : "white !important"
        },
        '.accordion-title': {
          color: theme == "dark" ? (((theme == "dark" && colorPicker == "#ef233c") && (activeTab == "general")) ? "white !important" : ((theme == "dark" && colorPicker == "#f8961e") && (activeTab == "general")) ? "black !important" : ((theme == "dark" && colorPicker == "#ffca3a") && (activeTab == "general")) ? "black !important" : ((theme == "dark" && colorPicker == "#27cb9a") && (activeTab == "general")) ? "black !important" : ((theme == "dark" && colorPicker == "#4cc9f0") && (activeTab == "general")) ? "black !important" : "white !important") : "white"
        }
      }}
      className={`li-setting-tab ${activeTab == "general" ? 'active' : ''}`}>
      <div className="settings-accordion-tab"
        onClick={() => { setActiveTab(activeTab => activeTab == 'general' ? null : 'general'); dispatch(setAddQuickLinkClick(false)) }}
        style={{ background: theme == "dark" ? "black" : "" }}
        css={{
          background: activeTab == 'general' ? `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important` :
            'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff',
          '&:hover': {
            background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important`,
          }
        }}>
        <div className="settings-accordion-heading">
          <figure className="settings-icon">
            {
              theme == "dark" ? <GeneralIcon isActiveSettings={activeTab == 'general'} isActiveGeneral={activeTab} onMouseEnterGeneral={onMouseEnterGeneral} /> : <img src="images/general.svg" className="img-default" />
            }
          </figure>
          <h3 className="accordion-title text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>General</h3>
        </div>
        <div className="settings-accordion-arrow">
          <img src="images/arrow-down.svg" />
        </div>
      </div>
      {

        activeTab == 'general' && <div className="settings-accordion-content">
          <div className="inner-form" style={{ background: theme == "dark" ? "black" : "" }}>
            <form className="formProfile" >
              {/* <div className="field-block">
                <h3 className="form-heading text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.3 : '' }}>Date &amp; Time</h3>
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Time zone</label>
                <div className="field-data">
                  <div className="filed-data-block">
                    <select className="select-field" name="timezone" defaultValue={settings?.timezone ?? null}>
                      <option value="UTC-12:00 International Date Line West">UTC-12:00 International Date Line West</option>
                      <option value="UTC-11:00 Coordinated Universal Time-11">UTC-11:00 Coordinated Universal Time-11</option>
                      <option value="UTC-10:00 Aleutian Islands">UTC-10:00 Aleutian Islands</option>
                      <option value="UTC-10:00 Hawaii">UTC-10:00 Hawaii</option>
                      <option value="UTC-09:30 Marquesas Islands">UTC-09:30 Marquesas Islands</option>
                      <option value="UTC-09:00 Alaska">UTC-09:00 Alaska</option>
                      <option value="UTC-09:00 Coordinated Universal Time-09">UTC-09:00 Coordinated Universal Time-09</option>
                      <option value="UTC-08:00 Baja California">UTC-08:00 Baja California</option>
                      <option value="UTC-08:00 Coordinated Universal Time-08">UTC-08:00 Coordinated Universal Time-08</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="field-block mt-30" >
                <h3 className="form-heading text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Appearance </h3>
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Theme</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }} >
                    <Select
                      onChange={(value) => handleChange(value, "theme")}
                      options={themeOptions}
                      defaultValue={
                        settings?.theme ? themeOptions.filter(option =>
                          option.value?.toLowerCase() === settings?.theme?.toLowerCase()
                        )?.[0] : themeOptions[0]
                      }

                      className="react-select"
                      classNamePrefix={"general-setting" || ""}
                    />

                    {/* <select className="select-field" name='theme' defaultValue={colorPickerMode ?? null}>
                      <option value="Light">Light</option>
                      <option value="Dark">Dark</option>
                    </select> */}
                  </div>
                </div>
              </div>

              <div className="field-block mt-30" onClick={onColorChange}>
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Accent Color</label>
                <div className="field-data">
                  <div className="filed-data-block">
                    <input id="profile_name" type="text" className="input-field" name="colorPicker" value={colorPicker} style={{ backgroundColor: colorPicker, color: "white" }} />

                  </div>
                  <div className="field-button">
                    <button className="changeName"

                      css={{
                        background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                        // background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                        color: `${colorPicker} !important`

                      }}
                      onClick={onColorChange}>Change Color</button>

                  </div>
                </div>
              </div>
              <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Time format</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }}>
                    <Select
                      onChange={(value) => handleChange(value, "timeFormat")}
                      options={timeFormatOptions}
                      value={
                        settings?.timeFormat ? timeFormatOptions.filter(option =>
                          option.value === settings?.timeFormat) : timeFormatOptions[0]
                      }
                      className="react-select"
                      // menuIsOpen={true}
                      // styles={colourStyles}
                      constainerClass=""
                      classNamePrefix={"general-setting" || ""}

                    />

                    {/* <select className="select-field" name='timeFormat' defaultValue={settings?.timeFormat ?? null}>
                      <option value="12">1:00 PM</option>
                      <option value="24">13:00</option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Date format</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }}>

                    <Select
                      onChange={(value) => handleChange(value, "dateFormat")}
                      options={dateFormatOptions}
                      value={
                        settings?.dateFormat ? dateFormatOptions.filter(option =>
                          option.label === settings?.dateFormat) : dateFormatOptions[0]
                      }
                      className="react-select"
                      classNamePrefix={"general-setting" || ""}
                    />

                    {/* <select className="select-field" name='dateFormat' defaultValue={settings?.dateFormat ?? null}>
                      <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                      <option value="YY/MM/DD">DD/MM/YYYY</option>
                    </select> */}
                  </div>
                </div>
              </div>

              <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Week Start</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }}  >

                    <Select
                      onChange={(value) => handleChange(value, "weekStart")}
                      options={weekStartOptions}
                      value={
                        settings?.weekStart ? weekStartOptions.filter(option =>
                          option.value === settings?.weekStart) : weekStartOptions[0]
                      }
                      selectedValue={settings?.weekStart}
                      className="react-select"
                      classNamePrefix={"general-setting" || ""}
                    />


                    {/* <select className="select-field" name='weekStart' defaultValue={settings?.weekStart ?? null}>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select> */}
                  </div>
                </div>
              </div>

              <div className="field-block mt-30">
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Early Feature Access Code</label>
                <div className="field-data">
                  <input defaultValue={settings?.testingCode ?? ''} type="text" onInput={(e) => handleChange(e.target, "testingCode")} className='input-field filed-data-block testing-code-field' />
                </div>
              </div>

              <div className="field-block mt-30" >
                <h3 className="form-heading text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}> Integrations </h3>
                <div className='d-flex align-items-center'>
                  <img src="images/logos_todoist-icon.svg" />
                  <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '', margin: 0, marginLeft: '10px', marginRight: '10px' }}>Todoist Integration</label>
                  {settings?.todoist_verification_token?.length > 0 && <span><LinkIcon color={colorPicker} /> <span style={{color: colorPicker,fontWeight: 700, fontSize: '16px', lineHeight: '19px'}}>Connected</span></span>}
                </div>
                <div className="field-data">
                  {/* <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }} >
                     <input defaultValue={settings?.todoist_verification_token} type="text" className="input-field" onInput={(e) => handleChange(e.target, "todoist_verification_token")} />
                  </div> */}
                </div>
                <div className="field-info" style={{color: theme == "dark" ? "white" : "#909090"}}>Enjoy a 2-way sync between your scheduled Todoist tasks and LifeTab.</div>
              </div>   
              <div className="field-button" style={{marginTop:"10px"}}>
                {
                  settings?.todoist_verification_token?.length > 0
                  ?
                  <button className="cancel"
                  onClick={disconnectTodoist}
                  style={{
                    background:theme == "dark" && ((theme == "dark" && colorPicker == "#ef233c") ?
                      `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #3A86FF` :
                      (theme == "dark" && colorPicker == "#f8961e") ?
                        `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #27CB9A`
                        : (theme == "dark" && colorPicker == "#27cb9a") ?
                          `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                          (theme == "dark" && colorPicker == "#4cc9f0") ?
                            `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                            (theme == "dark" && colorPicker == "#3a86ff") ?
                              `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D95555` :
                              (theme == "dark" && colorPicker == "#9b5de5") ?
                                `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #F8961E` :
                                (theme == "dark" && colorPicker == "#ef476f") ?
                                  `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #3A86FF` :
                                  `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), rgb(217, 85, 85)`),
                    color:theme == "dark" && ((theme == "dark" && colorPicker == "#ef233c") ? "#3A86FF" : (theme == "dark" && colorPicker == "#f8961e") ? "#27CB9A" : (theme == "dark" && colorPicker == "#9b5de5") ? "#F8961E" : (theme == "dark" && colorPicker == "#ef476f") ? "#3A86FF" : "rgb(217, 85, 85)")
                  }}
                  >Disconnect</button>
                  :
                  <button className="changeName"
                  onClick={connectTodoist}
                  css={{
                    background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                    // background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                    color: `${colorPicker} !important`

                  }}
                  >Connect Todoist</button>
                }
              </div>

              {/* <div className="field-block mt-30" >
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Project Id</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }} >
                     <input defaultValue={settings?.todoist_project_id} type="text" className="input-field" onInput={(e) => handleChange(e.target, "todoist_project_id")} />
                  </div>
                </div>
                <div className="field-info">E.g., https://todoist.com/app/project/<span style={{ background: colorPicker, color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>123456789</span></div>
              </div>  */}

              {/* <div className="field-block mt-30" >
                <h3 className="form-heading text-uppercase" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}> Shortcuts </h3>
                <label className="field-label" style={{ color: theme == "dark" ? "white" : "", opacity: theme == "dark" ? 0.7 : '' }}>Sync</label>
                <div className="field-data">
                  <div className="filed-data-block" css={{
                    '.general-setting__option--is-selected': {
                      background: `linear-gradient(0deg, ${colorPicker}, ${colorPicker}), #ffffff !important;`
                    }
                  }} >
                     <input ref={todoistEmailId} defaultValue={settings?.todoist_email_id} type="text" className="input-field" />                     
                  </div>
                  <div className="field-button">
                    <button className="changeName"
                      onClick={(e) => {
                        e.preventDefault();
                        handleChange(todoistEmailId.current, "todoist_email_id");
                      }}
                      css={{
                        background: theme == "dark" ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000 !important` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                        // background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, ${colorPicker}, ${colorPicker}), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff !important`,
                        color: `${colorPicker} !important`
                      }}
                     >Change</button>
                  </div>
                </div>
              </div>      */}
            </form>
          </div>
        </div>
      }
    </li>
  )
}

export default General