// SignupForm.js
import React, { useEffect } from 'react';
import { loadOutseta } from '../Outseta';

const LoginForm = ({upgrade = false}) => {
    const OPTIONS = {
        domain: "lifetab.outseta.com",
        load: "auth",
        auth: {
            widgetMode: "login",
            id: "login_embed",
            mode: "embed",
            selector: "#outseta-login-form"
        }
    };

    const removeWarning = (e) => {
        if(e.target.closest('.o--Button--btn')){
            document.querySelector('.warning')?.remove();
        }
    }

    useEffect(() => {
        loadOutseta(OPTIONS).then(() => {
            if(upgrade){
                const intervalId = setInterval(() => {
                    if(document.querySelector('.o--Button--btn')){
                        clearInterval(intervalId);
                        document.addEventListener('click', removeWarning);
                    }
                }, 1000);
            }
        });

        return () => {
            upgrade && document.removeEventListener('click', removeWarning);
        };
    }, []);

    return (
        <div id="outseta-login-form">
            <h3 style={{textAlign: 'center'}}>Loading ... kindly wait.</h3>
        </div>
    );
};

export default LoginForm;
