import React from 'react'
import { Route, Routes } from 'react-router-dom';
import FocusModeCompleted from '../Components/FocusTimer/FocusModeCompleted';
import FocusModeStart from '../Components/FocusTimer/FocusModeStart';
import BreakTimerStart from '../Components/FocusTimer/BreakTimerStart';
import TimerCompleted from '../Components/FocusTimer/TimerCompleted';
import BreakCompleted from '../Components/FocusTimer/BreakCompleted';
import TodoistAuth from '../Components/TodoistAuth';
import DashboardLayout from '../Components/common/DashboardLayout';
import Logout from './Logout';
import PlanUpgrade from './PlanUpgrade';

const PrivateRoutes = React.memo(({activeTimer, pause, start, location, fullscreen}) => {

    return (
        <Routes>
            <Route path='/logout' element={<Logout />} />
            <Route path='/plan-upgrade' element={<PlanUpgrade />} />
            {
                (activeTimer === 'focusMode') && fullscreen === true
                ?
                <>
                    <Route path='/focus-mode-completed' element={<FocusModeCompleted />}/>
                    <Route path='/*' element={<FocusModeStart />}/>
                </>
                :
                (
                    pause === true && start === true && fullscreen === true
                    ?
                    <Route path='/*' element={<BreakTimerStart/>}/>
                    :
                    <>
                        <Route path='/focus-completed' element={<TimerCompleted/>}/>
                        <Route path='/break-completed' element={<BreakCompleted/>}/>
                        <Route path='/auth/todoist' element={<TodoistAuth />}/>
                        <Route path='/*' element={<DashboardLayout />} />
                    </>
                )
            }
        </Routes>
        
    )
});

export default PrivateRoutes