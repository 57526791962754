import React, { useEffect, useState } from 'react'
import { Trash } from '../LandingHeader/Feedback/icons/Icons'

const DeletedChat = ({theme, trigger, undoChat, c}) => {
    
    useEffect(() => {
        const obj = setTimeout(() => {
            trigger(c.id);
        }, 4000);

        return () => clearTimeout(obj);
    }, [])

    return (
        <>
            {
                <div className="task-delete-popup bottom-conformation-popup " style={{ position: "fixed", zIndex: 1 , border:theme == "dark" ? "1px solid white" :""}}>
                <div className="task-undo">
                    <button className="delete leftWrap" type="button">
                    <img src="/images/trash-2-white.svg" alt='' /> <span className="task-delete">Chat Deleted</span>
                    </button>
                    <button className="undo" type="button" onClick={e => undoChat(e, c.id)}>
                    <span className="task-archive">Undo</span>
                    </button>
                </div>
                </div>
            }
        </>
    )
}

export default DeletedChat