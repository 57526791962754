/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React, { useMemo } from 'react'
import { jsx } from "@emotion/core";
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AddTask from '../AddTasks/AddTask'
import TaskList from './TaskList'
import _ from "lodash"
import {AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { setAllTaskList, setGroupedList, setNoDateTaskList, setOverDueTaskList, setTaskList } from '../../redux/slices/TaskSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { taskPosition } from '../../Components/action/common'
import ArrowUpDn from './ArrowUpDn'
import { DownArrowIcon } from '../../Components/LandingHeader/Feedback/icons/Icons';
import { useSearchParams } from 'react-router-dom';
import { verifyRepeatedTask } from '../../Components/action/utilities';

function TaskListDetails({ setDeleteLoader, deleteLoader, fetchTotalCount, currentPage, selectedDate, setSelectedDate, allTaskList, homePage, overDue, groupDate, onAddTaskHome, setOnAddTaskHome, todayTaskList, upComing, setDeleteTasks, upComingtaskList, showAddTask = false, title = null, setEditTaskListData, setAddTaskInputValue, fetchAddTaskData, setTodayTaskList, fetchTaskListData, index = 0,  currentTaskData, isTodayPage = false, HighlightedTaskSortableItem = null }) {

  const dispatch = useDispatch()

  // global state
  const { taskList, overDueTaskList, dateChange, noDataTaskList, taskGroupedList, completedTaskList, tasksGroupBy } = useSelector(state => state.task)
  
  const {theme , colorPicker} = useSelector(state => state.theme)

  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const date = moment(dateChange).format('YYYY-MM-DD')
  const currentDate = moment(currentTime).format('YYYY-MM-DD')
  const [tasksArray, setTasksArray] = useState([]);
  const [allDatesTasks, setAllDatesTasks] = useState([]);
  const [allHomePageTasks, setAllHomePageTasks] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  const [todayTask,setTodayTask]=useState([])
  const [showAllCompletedTasks, setShowAllCompletedTasks] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // sort function
  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    let newList = [];
    const newGroupDate = moment(groupDate).startOf('date').toISOString();

    if (currentPage == "home") {
      //const groupTaskListData = groupedTasks(_.filter(allHomePageTasks, ["date", moment(dateChange).startOf('date').toISOString()]));
      const allHomePageTasksPending = _.filter(allHomePageTasks, t => !t.deletedAt && t.status == 0);
      const allHomePageTasksComplete = _.filter(allHomePageTasks, t => !t.deletedAt && t.status == 1); 
      const groupTaskListData = groupedTasks(_.filter(allHomePageTasksPending, (t) => verifyRepeatedTask(t, dateChange, moment(t?.date).startOf('date').toISOString() == moment(dateChange).startOf('date').toISOString())));
      const groupKey = Object.keys(groupTaskListData)[collection];
      newList = arrayMoveImmutable(allHomePageTasksPending, _.findIndex(allHomePageTasksPending, ["id", groupTaskListData[groupKey][oldIndex].id]), _.findIndex(allHomePageTasksPending, ["id", groupTaskListData[groupKey][newIndex].id]));
      newList = [...newList, ...allHomePageTasksComplete];
      setTasksArray(newList?.filter(t => verifyRepeatedTask(t, dateChange, moment(t?.date).startOf('date').toISOString() == moment(dateChange).startOf('date').toISOString())));
      setAllHomePageTasks(newList);
      dispatch(setTaskList(newList));

    } else if (currentPage == "upComing") {
      // const groupTaskListData = groupedTasks(_.filter(allDatesTasks, ["date", newGroupDate]));
      const groupTaskListData = groupedTasks(_.filter(allDatesTasks, (t) => verifyRepeatedTask(t, groupDate, moment(t?.date).startOf('date').toISOString() == newGroupDate)));
      const groupKey = Object.keys(groupTaskListData)[collection];
      newList = arrayMoveImmutable(allDatesTasks, _.findIndex(allDatesTasks, ["id", groupTaskListData[groupKey][oldIndex].id]), _.findIndex(allDatesTasks, ["id", groupTaskListData[groupKey][newIndex].id]));
      setTasksArray(newList?.filter(t => verifyRepeatedTask(t, groupDate, moment(t?.date).format('L') == groupDate)));
      setAllDatesTasks(newList)
      dispatch(setGroupedList(newList))

    } else if (currentPage == "overDue") {
      const groupTaskListData = groupedTasks(overDueTaskList);
      const groupKey = Object.keys(groupTaskListData)[collection];
      newList = arrayMoveImmutable(overDueTaskList, _.findIndex(overDueTaskList, ["id", groupTaskListData[groupKey][oldIndex].id]), _.findIndex(overDueTaskList, ["id", groupTaskListData[groupKey][newIndex].id]))
      dispatch(setOverDueTaskList(newList))

    } else if (currentPage == "allTask") {
      const groupTaskListData = groupedTasks(allTaskList);
      const groupKey = Object.keys(groupTaskListData)[collection];
      newList = arrayMoveImmutable(allTaskList, _.findIndex(allTaskList, ["id", groupTaskListData[groupKey][oldIndex].id]), _.findIndex(allTaskList, ["id", groupTaskListData[groupKey][newIndex].id]))
      dispatch(setAllTaskList(newList))

    } else {
      const groupTaskListData = groupedTasks(noDataTaskList);
      const groupKey = Object.keys(groupTaskListData)[collection];
      newList = arrayMoveImmutable(noDataTaskList, _.findIndex(noDataTaskList, ["id", groupTaskListData[groupKey][oldIndex].id]), _.findIndex(noDataTaskList, ["id", groupTaskListData[groupKey][newIndex].id]))
      dispatch(setNoDateTaskList(newList))
    }

    currentPage != "upComing" && currentPage != "home" && setTasksArray(newList);
    let list = newList.map((item) => {
      return item?.id
    })
    taskPosition({ "positions": list })
  };

  const SortableItem = sortableElement(({ task }) => {
    setTodayTask(task)
    return (
      <TaskList
        setCurrentDateTime={setCurrentDateTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        upComing={upComing}
        groupDate={groupDate}
        onAddTaskHome={onAddTaskHome}
        setOnAddTaskHome={setOnAddTaskHome}
        taskComplete={true}
        homePage={homePage}
        currentPage={currentPage}
        fetchTaskListData={fetchTaskListData}
        todayTaskList={todayTaskList}
        setTodayTaskList={setTodayTaskList}
        setDeleteTasks={setDeleteTasks}
        key={task.id}
        task={task}
        isDate={false}
        taskList={taskList}
        setAddTaskInputValue={setAddTaskInputValue}
        setEditTaskListData={setEditTaskListData}
        fetchAddTaskData={fetchAddTaskData}
        fetchTotalCount={fetchTotalCount}
        setDeleteLoader={setDeleteLoader}
        deleteLoader={deleteLoader}
      />
    )
  });

  const groupedTasks = (items) => {
    
    let taskItems = items;
      const projectFilter = searchParams.get('project');

      // Check the projects/tags filter
      if(projectFilter){
        taskItems = taskItems.filter(task => task?.Project?.title === projectFilter);
      }

      if(!showAllCompletedTasks && isTodayPage && items.length > 6 && items.filter(i =>  i.status == 1).length > 3){
        let count = items.length - items.filter(i =>  i.status == 1).length;
        count = count > 5 ? count + 1 : 6;
        taskItems = items.slice(0, count) 
      }

      let groupTaskListData = {};
      if(tasksGroupBy !== 'none'){
        if(tasksGroupBy === 'project'){
          groupTaskListData = _.groupBy(taskItems.filter(task => task?.Project?.title?.length > 0), (task) => {
            return task?.Project?.title
          });

          groupTaskListData['@'] = taskItems.filter(task => !task?.Project?.title?.length);

          groupTaskListData['@'].length === 0 && (delete groupTaskListData['@']);
        }

        if(tasksGroupBy === 'tag'){
          const fn = _.spread(_.union);
          let taskTagsList = fn(taskItems.map(task => task?.Tags?.length > 0 ? task.Tags.map(tag => tag.name) : []));
          taskTagsList = [...taskTagsList, '#'];
          taskTagsList.forEach((tag) => {
            if(tag === '#'){
              groupTaskListData[tag] = taskItems.filter(task => task.Tags.length === 0);
            }else{
              const tagTasks = taskItems.filter((task) =>
                task.Tags.some((taskTag) => taskTag.name === tag)
              );
              if (tagTasks.length) {
                groupTaskListData[tag] = tagTasks;
              }
            }
          });
        }
      }

      if(Object.keys(groupTaskListData).length === 0){
        groupTaskListData['@'] = taskItems;
      }

      return groupTaskListData;
  }

  const SortableContainer = sortableContainer(({children}) => {
    return <div>{children}</div>;
  });

  const SortableList = ({ items }) => {
    const groupTaskListData = groupedTasks(items);
    
    return (
      Object.keys(groupTaskListData).map((key, index) => {
        
        return (
          <div className={`grouped-tasks-list ${isTodayPage ? 'custradius' : ''}`} key={index} style={{ background: theme == "dark" ? "black" : "white" }}>
            {key === '@' || key === '#' ? '' : <h3 className="task-meta-key">{tasksGroupBy === 'project' ? '@' : tasksGroupBy === 'tag' ? '#' : ''}{_.capitalize(key)}</h3>}
                  
            <ul className="ul-task-list">
            {
              isTodayPage && index === 0
              &&
              <>
                <li className='list-tag-head nextUp'><HighlightedTaskSortableItem  currentTaskData={groupTaskListData[key][0]} /></li>
                <li className='list-tag-head todyTasks'>
                  <div className="list-tag-name">
                    <label className="task-tag text-uppercase" style={{ color: theme == "dark" ? "white" : "" , opacity:theme == "dark" ? 0.3:""}}></label>
                  </div>
                </li>
              </>
            }
            {
              groupTaskListData[key].map((task, i) => {
                let taskDate = moment(task.date).format('YYYY-MM-DD');
                if(isTodayPage && i === 0){
                  return null;
                }
                return (
                  currentPage === 'noDate'
                    ?
                    (!task.date ? <SortableItem disabled={task.status == 1} key={`item-${task.id}`} index={i} collection={index} task={task} /> : null)
                    :
                    (
                      currentPage === 'overDue'
                        ?
                        (moment(taskDate).isBefore(currentDate, 'day') && <SortableItem disabled={task.status == 1} key={`item-${task.id}`} index={i} collection={index} task={task} />)
                        :  
                        currentPage !== 'home' 
                        || 
                        (verifyRepeatedTask(task, date, ((date === currentDate && taskDate === currentDate) || (taskDate !== currentDate && date !== currentDate))))
                          ?
                          <SortableItem disabled={task.status == 1} key={`item-${task.id}`} index={i} collection={index} task={task} />
                          :
                          null
                    )
                )
              })
            }
            </ul>
            {
              currentPage == 'home' && isTodayPage === true && items.length > 6 && items.filter(i =>  i.status == 1).length > 3
              &&
              <div className={`toggle-completed-tasks-btn ${showAllCompletedTasks ? 'hide' : 'show'}`}><a href="#" onClick={toggleCompletedTasks}>{showAllCompletedTasks ? 'Hide Completed Tasks': 'Show Completed Tasks'} <DownArrowIcon color={theme == 'dark' ? '#ffffff' : '#000000'} /> </a></div>
            }
            {(tasksGroupBy !== 'none' || isTodayPage) && <AddTask setCurrentDateTime={setCurrentDateTime} isActive={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} upComingtaskList={upComingtaskList} overDue={overDue} upComing={upComing} setTodayTaskList={setTodayTaskList} fetchTaskListData={fetchTaskListData} />}
          </div>
        )
      })
      )
  };

  useEffect(() => {
    let tasksGroup = [];
    
    switch (currentPage) {
      case 'home':
        tasksGroup = [...(_.sortBy(taskList.filter(t => t.status != '1'), ['status'])), ...(_.orderBy(taskList.filter(t => t.status == '1'), ['isMostImportant', 'completedAt'], ['desc', 'desc']))];
        break;

      case 'upComing':
        tasksGroup = upComingtaskList;
        break;

      case 'overDue':
        tasksGroup = overDueTaskList;
        break;

      case 'allTask':
        tasksGroup = [...(_.sortBy(allTaskList.filter(t => t.status != '1'), ['status', 'date'])), ...(_.orderBy(allTaskList.filter(t => t.status == '1'), ['isMostImportant', 'completedAt'], ['desc', 'desc']))];
        break;

      case 'noDate':
        tasksGroup = noDataTaskList
        break;
    }

    setTasksArray(tasksGroup.filter(t => t.deletedAt || (currentPage === 'home' && !verifyRepeatedTask(t, dateChange, !(moment(t.date).startOf('date').toISOString() != moment(dateChange).startOf('date').toISOString()))) ? false : true));
  }, [currentPage, dateChange, upComingtaskList, taskList, noDataTaskList, overDueTaskList, allTaskList])

  useEffect(() => {
    setAllDatesTasks(taskGroupedList);
  }, [taskGroupedList])

  useEffect(() => {
    setAllHomePageTasks(taskList);
  }, [taskList])

  const toggleCompletedTasks = (e) => {
    setShowAllCompletedTasks(flag => !flag);
  }

  return (
    <AccordionItem uuid={`accitem${index}`} className={`accordion-item accitem${index} ${!tasksArray.length ? 'no-task' : ''}`}>
      <AccordionItemHeading>
        <AccordionItemButton>
          {
            currentPage == "upComing" && <div className={`accordion_tab `} style={{ background: theme == "dark" ? "black" : "white" }}>
              <div className="heading">
                <h3 className="accordion-title" dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              {title !== null && <div  className={`accordion_arrow ${index === 0 ? 'active' : ''}`}
               style={{
                border:theme == "dark" ? "1px solid white":"1px solid rgba(0, 0, 0, 0.15)"
               }}
              >
                {
                  theme == "dark" ? <ArrowUpDn/> : <img src="/images/arrow-up-dn.svg" alt="arrow" />
                }
               
              </div>}
            </div>
          }
        </AccordionItemButton>
      </AccordionItemHeading>

      <AccordionItemPanel>
        {
            <div className={`accordion_content ${tasksGroupBy !== 'none' ? 'grouped-tasks' : 'non-grouped-tasks'} ${currentPage == 'home' && isTodayPage === true && tasksArray.length > 6 && tasksArray.filter(i =>  i.status == 1).length > 3 && !showAllCompletedTasks ? 'show-all-tasks' : ''}`} >
              {
                ((tasksArray?.length == 0) || (allDatesTasks?.length == 0 && overDueTaskList?.length == 0 && allTaskList?.length == 0 && noDataTaskList?.length == 0 && completedTaskList?.length == 0)) 
                ?
                  <>
                  {
                    currentPage == 'home' && <AddTask setCurrentDateTime={setCurrentDateTime} isActive={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} upComingtaskList={upComingtaskList} overDue={overDue} upComing={upComing} setTodayTaskList={setTodayTaskList} fetchTaskListData={fetchTaskListData} />
                  }
                  {
                    <div className="task-list" style={{ borderRadius: '0 0 20px 20px', background: theme == "dark" ? "black" : "white" }}>
                      <div className="task-list-data">
                        <div className='emptyData' style={{ color: theme == "dark" ? "white" : "gray" , opacity : theme == "dark" ? 0.7 : 0.1}}>
                          {
                            tasksArray?.length == 0 ? <span>No tasks  :)</span> :""
                          }
                        </div>
                      </div>
                    </div>
                  }
                  </>
                : 
                <>
                  {
                    isTodayPage === false && currentPage === 'home' && tasksGroupBy === 'none'
                    &&
                    <AddTask setCurrentDateTime={setCurrentDateTime} isActive={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} upComingtaskList={upComingtaskList} overDue={overDue} upComing={upComing} setTodayTaskList={setTodayTaskList} fetchTaskListData={fetchTaskListData} />
                  }
                  <div className="task-list">
                    <div className="task-list-data" css={{
                      '.sortable-helper':{
                        background: `${colorPicker}`
                      }
                    }}>
                    {
                      tasksArray.length > 0 
                      && 
                      <SortableContainer useDragHandle={true} onSortEnd={onSortEnd} helperClass="sortable-helper">
                        <SortableList items={tasksArray} />
                      </SortableContainer>
                    }
                    </div>
                    
                    {/* {
                      currentPage == 'home' && isTodayPage === true && <AddTask setCurrentDateTime={setCurrentDateTime} isActive={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} upComingtaskList={upComingtaskList} overDue={overDue} upComing={upComing} setTodayTaskList={setTodayTaskList} fetchTaskListData={fetchTaskListData} />
                    } */}
                  </div>
                </>
                  
              }
              {
                useMemo(() => {
                  return showAddTask ? <AddTask setCurrentDateTime={setCurrentDateTime} isActive={currentPage === 'home'} currentPage={currentPage} groupDate={groupDate} upComingtaskList={upComingtaskList} overDue={overDue} upComing={upComing} setTodayTaskList={setTodayTaskList} fetchTaskListData={fetchTaskListData} /> : ''
                }, [groupDate])
              }
            </div>
        }
      </AccordionItemPanel>
    </AccordionItem>
  )
}

export default TaskListDetails