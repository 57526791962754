import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash"
import {useSelector} from 'react-redux'

function QuoteLoader({ loop }) {
  const {theme} =useSelector(state=>state.theme)
  return (
    <>
      {_.times(loop !== undefined ? loop : 1, (key) => {
        return (
          <div className="quoteContainer" style={{background: theme == "dark" ? "black" :"white"}}>
            <p className="quote-text"><Skeleton height={"40px"} width= "250px" /></p>           
          </div>
        );
      })}
    </>
  );
}

export default QuoteLoader


