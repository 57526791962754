/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import LandingHeader from '../LandingHeader/LandingHeader'
import SideBar from '../SideBar/SideBar'
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../Header/Header'
import { useEffect } from 'react'
import BackgroundImage from './BackgroundImage'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import { fetchSetting } from '../action/common'
import SettingSclice, { setSettings } from '../../redux/slices/SettingSclice'
import SettingsHeader from '../LandingHeader/SettingsHeader'
import HomePage from '../../pages/HomePage/HomePage'
import Tasks from '../../pages/Tasks/Tasks'
import OverDue from '../../pages/Tasks/OverDue'
import All from '../../pages/Tasks/All'
import Completed from '../../pages/Tasks/Completed'
import Trash from '../../pages/Tasks/Trash'
import NoDate from '../../pages/Tasks/NoDate'
import Settings from '../../pages/Settings/Settings'
import Sidebar from './Sidebar'
import AIAssistantFullScreen from '../ChatGPT/Fullscreen'
import { clearChat } from '../../redux/slices/AiAssistant'
import TasksLayout from '../../pages/Tasks/TasksLayout'
import FocusModeSettings from '../FocusTimer/FocusModeSettings'
import Admin from '../Admin/Admin'
import SubscriptionPlan from '../Outseta/SubscriptionPlan'
import { AuthContext } from '../../AuthProvider'
import DaemonTimer from '../FocusTimer/DaemonTimer'

function DashboardLayout(props) {
  const dispatch = useDispatch()
  //const { search } = useLocation()
  const urlPath = window.location.pathname
  //let queries = queryString.parse(search);
  // const navigate = useNavigate();
  // let access_token = localStorage.getItem("token")
  const { settings, settingsFetched } = useSelector(state => state.settings)
  const { themeColor, theme, colorPicker } = useSelector(state => state.theme)
  //const { istodayQuotes, isFoucsActive, quicksLinksActive } = useSelector(state => state.card)
  const innerPages = urlPath?.includes("tasks") || urlPath?.includes("settings") || urlPath?.includes("ai-assistant") || urlPath?.includes("admin") || urlPath?.includes("subscription-plan");
  //console.log(window.location.pathname,"path:::")
  const { subscription } = useContext(AuthContext);

  const isSubscriptionExpired = subscription?.isFreeTrial && subscription?.accountStage > 3;

  const fetchSettinValue = async () => {
    try {
      let response = await fetchSetting()
      dispatch(setSettings(response?.data?.responseData?.settings ?? {}));
    } catch ({ response }) {

    }
  }

  useEffect(() => {
    (urlPath?.includes("tasks") || urlPath?.includes("settings")) && dispatch(clearChat());
  }, [urlPath])

  useEffect(() => {
    fetchSettinValue()
    document.addEventListener('click', closeInputBox);
  }, [])

  const closeInputBox = (e) => {
    // If click next/prev/today month arrow button in datepicker
    if (e.target?.parentNode?.classList?.contains('datepicker-month-nav') || e.target?.closest('.datepicker-month-nav') || e.target?.closest('.suggestions-list')) {
      return;
    }

    const inputElem = e.target?.classList?.contains('add-task-input') ?? null;

    if (inputElem) {
      document.querySelectorAll('.repetions-block').forEach(elem => {
        elem.style.display = 'none'
        elem.closest('.type-header') !== null && elem.closest('.type-header')?.classList?.remove('bg-light-addTask');
      });
      e.target.closest('.type-header').querySelector('.repetions-block').style.display = 'block'
      e.target.closest('.type-header').classList.add('bg-light-addTask');
    }

    document.querySelectorAll('.add-task-input').forEach(el => {
      el?.value?.length === 0 && el.classList.remove('focus-input')
    });

    if (e.target.closest('.filed-type')) {
      const inputElem = e.target?.classList?.contains('add-task-input') ?? null;
      if (inputElem) {
        e.target.closest('.add-task-input').classList.add('focus-input')
      }
    }

    // cancel-btn

    if (!e.target.closest('.advancedDatePicker') && !e.target.closest('.navBtn') && !e.target.closest('.datePickerButton')) {
      document.querySelectorAll('.advancedDatePicker.active').forEach(el => {
        el.classList.remove('active');
      })
    }
  }
  
  return (
      urlPath.match(/\/(signup|login|welcome|focus-mode-completed)$/) !== null
      ?
      <Navigate to={process.env.REACT_APP_ROOT} />
      :
      (
        isSubscriptionExpired && urlPath.match(/\/subscription\-plan$/) === null
        ?
        <Navigate to="/subscription-plan" />
        :
        <div className={`main-body-wrap ${theme == "dark" ? "dark-mode" : ""} ${settings?.focus === false && settings?.quotes === false && settings?.quickLink === false && settings?.aiAssistant === false ? 'no-sidebar' : ''} ${urlPath?.includes("subscription-plan") ? `subscription-plan-page ${isSubscriptionExpired ? 'post-expired' : ''}` : ''}`} 
      css={{
        background: themeColor,
        '.darkPrimaryColor': {
              background: theme == "dark" ? "#fff" : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${colorPicker}`,
          '-webkit-text-fill-color': 'transparent',
          '-webkit-background-clip': 'text !important'
        },
        '.darkOverDueColor': {
              background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), #D95555`,
          '-webkit-text-fill-color': 'transparent',
          '-webkit-background-clip': 'text !important'
        },
        '.overdueTaskColor':{
          color: '#D95555'
        }
      }}>
        {
          urlPath?.includes("tasks") ? <Header /> : (urlPath?.includes("settings") || urlPath?.includes("ai-assistant") || urlPath?.includes("admin") || urlPath?.includes("subscription-plan") ? <SettingsHeader /> : <LandingHeader />)
        }
        <div className={`page-wrapper ${urlPath?.includes("ai-assistant") && 'ai-assistant-page' || 'pages' } `} >
        {/* <div className="page-wrapper" > */}
          <div className="page-container">
            <SideBar />
            <div className={`contentquoteSection mainHomepage ${innerPages ? 'inner-page' : 'home-page'}`}
              css={{
                '&::before': {
                  background: theme == "dark" ? `${colorPicker} !important` : ""
                }
              }} id="uniqueClose">
              <Routes>
                {
                  !isSubscriptionExpired
                  &&
                  <>
                    <Route path={process.env.REACT_APP_ROOT} element={<HomePage />} />
                    <Route element={<TasksLayout />}>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path='/tasks/upcoming' element={<Tasks />} />
                      <Route path="/tasks/inbox" element={<All />} />
                      <Route path='/tasks/nodate' element={<NoDate />} />
                      <Route path="/tasks/overdue" element={<OverDue />} />
                      <Route path='/tasks/complete' element={<Completed />} />
                      <Route path='/tasks/trash' element={<Trash />} />
                    </Route>
                    <Route path='/focus-mode-settings' element={<FocusModeSettings/>}/>
                    <Route path='/settings' element={<Settings/>}/>
                    <Route path='/ai-assistant' element={<AIAssistantFullScreen/>}/>
                    <Route path='/admin' element={<Admin/>}/>
                  </>
                }
                <Route path='/subscription-plan' element={<SubscriptionPlan/>}/>
                {/* <Route path='/payment' element={<StripeContainer/>}/> */}
              </Routes>
              {
                innerPages
                ? 
                <>
                </> 
                : 
                (settingsFetched ? <Sidebar systemSettings={settings} /> : null)
              }
            </div>
          </div>
        </div>
        <div className='backgroundBottom'>
          <BackgroundImage />
        </div>
        <DaemonTimer />
        </div>
      )
      
  )
}

export default DashboardLayout