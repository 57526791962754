// App.js
import React from 'react';
import ExperimentalSignupForm from '../../Components/Outseta/ExperimentalSignup';

const ExperimentalSignUpOutseta = () => {
    return (
        <div className="page-wrapper">
            <ExperimentalSignupForm />
        </div>
    );
};

export default ExperimentalSignUpOutseta;
