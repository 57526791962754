import React from 'react'
import { useSelector } from 'react-redux'

function TaskMenuIcon({activeTasks , isMouseEnterTasks}) {
  const {theme , colorPicker}=useSelector(state=>state.theme)
  // let strokeColorCondition = (((theme == "dark") && activeStyleHome == false)) ? "#4C4C4C" : "white"

  let strokeColorCondition = ((theme == "dark" && activeTasks == false)) ? "#4C4C4C" : "white"
  let strCol = ((theme == "dark" && (colorPicker == "#27cb9a" || colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" )) && activeTasks) ? "black" : ""

  let onMouseEnterCondition= ((isMouseEnterTasks )) && "black"
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        strCol ? <path d="M13.3335 3.33333H15.0002C15.4422 3.33333 15.8661 3.50892 16.1787 3.82148C16.4912 4.13404 16.6668 4.55797 16.6668 4.99999V16.6667C16.6668 17.1087 16.4912 17.5326 16.1787 17.8452C15.8661 18.1577 15.4422 18.3333 15.0002 18.3333H5.00016C4.55813 18.3333 4.13421 18.1577 3.82165 17.8452C3.50909 17.5326 3.3335 17.1087 3.3335 16.6667V4.99999C3.3335 4.55797 3.50909 4.13404 3.82165 3.82148C4.13421 3.50892 4.55813 3.33333 5.00016 3.33333H6.66683"  stroke={strCol}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />:  <path d="M13.3335 3.33333H15.0002C15.4422 3.33333 15.8661 3.50892 16.1787 3.82148C16.4912 4.13404 16.6668 4.55797 16.6668 4.99999V16.6667C16.6668 17.1087 16.4912 17.5326 16.1787 17.8452C15.8661 18.1577 15.4422 18.3333 15.0002 18.3333H5.00016C4.55813 18.3333 4.13421 18.1577 3.82165 17.8452C3.50909 17.5326 3.3335 17.1087 3.3335 16.6667V4.99999C3.3335 4.55797 3.50909 4.13404 3.82165 3.82148C4.13421 3.50892 4.55813 3.33333 5.00016 3.33333H6.66683"  stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M13.3335 3.33333H15.0002C15.4422 3.33333 15.8661 3.50892 16.1787 3.82148C16.4912 4.13404 16.6668 4.55797 16.6668 4.99999V16.6667C16.6668 17.1087 16.4912 17.5326 16.1787 17.8452C15.8661 18.1577 15.4422 18.3333 15.0002 18.3333H5.00016C4.55813 18.3333 4.13421 18.1577 3.82165 17.8452C3.50909 17.5326 3.3335 17.1087 3.3335 16.6667V4.99999C3.3335 4.55797 3.50909 4.13404 3.82165 3.82148C4.13421 3.50892 4.55813 3.33333 5.00016 3.33333H6.66683"  stroke={strCol}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :       <path d="M13.3335 3.33333H15.0002C15.4422 3.33333 15.8661 3.50892 16.1787 3.82148C16.4912 4.13404 16.6668 4.55797 16.6668 4.99999V16.6667C16.6668 17.1087 16.4912 17.5326 16.1787 17.8452C15.8661 18.1577 15.4422 18.3333 15.0002 18.3333H5.00016C4.55813 18.3333 4.13421 18.1577 3.82165 17.8452C3.50909 17.5326 3.3335 17.1087 3.3335 16.6667V4.99999C3.3335 4.55797 3.50909 4.13404 3.82165 3.82148C4.13421 3.50892 4.55813 3.33333 5.00016 3.33333H6.66683"  stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
       {
        strCol ?  <path d="M12.4998 1.66667H7.49984C7.27882 1.66667 7.06686 1.75447 6.91058 1.91075C6.7543 2.06703 6.6665 2.27899 6.6665 2.50001V4.16667C6.6665 4.38769 6.7543 4.59965 6.91058 4.75593C7.06686 4.91221 7.27882 5.00001 7.49984 5.00001H12.4998C12.7209 5.00001 12.9328 4.91221 13.0891 4.75593C13.2454 4.59965 13.3332 4.38769 13.3332 4.16667V2.50001C13.3332 2.27899 13.2454 2.06703 13.0891 1.91075C12.9328 1.75447 12.7209 1.66667 12.4998 1.66667V1.66667Z"  stroke={strCol}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :       <path d="M12.4998 1.66667H7.49984C7.27882 1.66667 7.06686 1.75447 6.91058 1.91075C6.7543 2.06703 6.6665 2.27899 6.6665 2.50001V4.16667C6.6665 4.38769 6.7543 4.59965 6.91058 4.75593C7.06686 4.91221 7.27882 5.00001 7.49984 5.00001H12.4998C12.7209 5.00001 12.9328 4.91221 13.0891 4.75593C13.2454 4.59965 13.3332 4.38769 13.3332 4.16667V2.50001C13.3332 2.27899 13.2454 2.06703 13.0891 1.91075C12.9328 1.75447 12.7209 1.66667 12.4998 1.66667V1.66667Z"  stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M10 9.16667H13.3333"   stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M10 9.16667H13.3333"   stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M10 13.3333H13.3333"   stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M10 13.3333H13.3333"   stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M6.6665 9.16667H6.6765"   stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M6.6665 9.16667H6.6765"   stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
      {
        strCol ?  <path d="M6.6665 13.3333H6.6765"   stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :  <path d="M6.6665 13.3333H6.6765"   stroke={(isMouseEnterTasks && activeTasks) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      }
    </svg>
  )
}

export default TaskMenuIcon