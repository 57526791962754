import React from 'react'
import { useSelector } from 'react-redux'

function RightArrow() {
  const {colorPicker , theme}=useSelector(state=>state.theme)
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 9L1 5L5 1" stroke={theme== "dark" ? "white" :"black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default RightArrow