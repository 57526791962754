import moment from 'moment'
import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchTaskList, totalTaskCount } from '../../Components/action/common'
import AddTask from '../AddTasks/AddTask'
import TaskListDetails from './TaskListDetails'
import { useDispatch, useSelector } from 'react-redux'
import { setDateChange, setOverDueTaskList, setTotalCount } from '../../redux/slices/TaskSlice'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import { Accordion } from 'react-accessible-accordion'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import UpcomingLoader from '../../Components/SkeletonLoading/UpcomingLoader'
import { verifyRepeatedTask } from '../../Components/action/utilities'

function OverDue() {
  // dispatch
  const dispatch = useDispatch()
  // global state
  const {theme} = useSelector(state => state.theme)
  const {overDueTaskList} = useSelector(state => state.task)
  const currentTimes = moment().startOf('date')
  const [list, setList] = useState([])
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const [isLoader, setIsLoader] = useState(true)

  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })
      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {}
  }

  const fetchAddTaskData = useCallback(async () => {
    setIsLoader(true)
    let requestBody = {
      "status": 0,
      "type": 2,
      "date": currentTime.toISOString()
    }
    try {
      const response = await fetchTaskList(requestBody)

      const overDueTaskList = _.chunk(_.orderBy(response?.data?.responseData.filter(item => item.status == 0 && item.date !== null && item.date < currentTime.toISOString()), ['date'], ['desc']), 20);
     const chunk = overDueTaskList.splice(0, 1);
      setList(overDueTaskList)
      dispatch(setOverDueTaskList(chunk?.[0] ?? []))
      setIsLoader(false)
    } catch ({ response }) {
      setIsLoader(false)
    }
  }, [currentTime, dispatch])

  useEffect(() => {
    dispatch(setDateChange(new Date()))
    fetchAddTaskData()
  }, [])

  const fetchMoreData = () => {
    const chunk = list.splice(0, 1);
    if(chunk.length > 0){
      setList(list)
      dispatch(setOverDueTaskList([...overDueTaskList, ...chunk[0]]))
    }
  }

  return (
    <>
      {
        isLoader ? <AllLoader/> : <>
        <div id="tasks-list-wrapper" className="overdue-form task-list-page common-form" style={{background:theme == "dark" ? "black" :"white"}}>
          <AddTask isAddTask={true} fetchAddTaskData={fetchAddTaskData} currentPage="overDue" fetchTotalCount={fetchTotalCount} />
          <div className="task-list">
            <div className="task-list-data">
              <div className="ul-task-list">
                <div className="task-list">
                  <InfiniteScroll
                    dataLength={overDueTaskList?.length ?? 0}
                    next={fetchMoreData}
                    hasMore={list.length > 0}
                    loader={<UpcomingLoader  />}
                    scrollableTarget="tasks-list-wrapper"
                  >
                    <Accordion preExpanded={['accitem0']}>
                      <TaskListDetails 
                      fetchTotalCount={fetchTotalCount}
                      currentPage="overDue"
                      />
                    </Accordion>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      }
    </>
  )
}

export default OverDue