import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash"
import { ChatIcon } from '../LandingHeader/Feedback/icons/Icons';

function AiChatHistory({ loop }) {
  return (
    <>                 
      {_.times(loop !== undefined ? loop : 7, (key) => {
        return (
            <li className={`timeline-li`}>
                {
                    (
                        <>
                            <div className='time-slot'>
                                <span className='time'><s><Skeleton height={"12px"} width= "47px" /></s></span> 
                                <span className='date'><s><Skeleton height={"12px"} width= "47px" /></s></span>  
                            </div>
                            <div className='histry-icon'>
                            <div className='his-icon'><ChatIcon /></div> 
                            </div>
                            <div className='histry-title'>
                                <span className='histry-title-name'><s><Skeleton height={"20px"} width= "167px" /></s></span>
                            </div>
                            <div className='histry-tag'>
                                <div className='hs-tag-name'>
                                    <span className='tag-name'><s><Skeleton height={"20px"} width= "95px" /></s></span>
                                </div>
                            </div>
                        </>
                    )
                }
            </li>
        );
      })}
    </>
  );
}

export default AiChatHistory