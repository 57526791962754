/** @jsxRuntime classic */
/** @jsx jsx */
import moment from 'moment'
import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { completedTask, deleteTask, fectchJournalToday, fetchSetting, fetchTaskList, totalTaskCount, updateJournalToday, updateTaskList } from '../../Components/action/common'
import momentTimezone from 'moment-timezone';
import AddTask from '../AddTasks/AddTask'
import TaskListDetails from '../Tasks/TaskListDetails'
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { useDispatch } from 'react-redux'
import { deleteSetTaskList, pushSetTaskList, pushToSetting, setActiveTask, setFocusTasksList, setHeaderName, setTaskList, setTotalCount, updateFocusTasksWithRecurringTask, updateSetTaskList, updateStatus } from '../../redux/slices/TaskSlice'
import { setColorPicker, setColorPickerMode, setColorPickerRGB, setNewBackgroundColor, setTheme, setThemeColor } from '../../redux/slices/ThemeSlice'
import { useSelector } from 'react-redux'
import _ from "lodash"
import { authentication, hexToRgb } from '../../Components/action/utilities'
import { Navigate } from 'react-router-dom'
import EditTaskList from '../AddTasks/EditTaskList'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import toast from 'react-hot-toast'
import Toaster from '../CustomToast/Toaster'
import AllLoader from '../../Components/SkeletonLoading/AllLoader'
import JournalSection from './JournalSection'
import ColorPickerSetting from './ColorPickerSetting'
import Datepicker from '../../Components/DatePicker/components/Datepicker'
import { Accordion } from 'react-accessible-accordion'
import { jsx } from "@emotion/core";
import CalendarClock from './CalendarClock'
import ReactTooltip from 'react-tooltip'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import DragIcon from '../Tasks/DragIcon'
import { setSettings } from '../../redux/slices/SettingSclice'
import { CalendarIcon, Clock, CollapseIcon, FolderCloseIcon, FullscreenIcon, MostImportant, MoveTomorrowIcon, SettingsIcon, TagIcon, Trash } from '../../Components/LandingHeader/Feedback/icons/Icons'
import MostImportantTaskButton from '../Tasks/MostImportantTaskButton'
import Tooltip from '../../Components/common/Tooltip'
import RecurringIcon from '../Tasks/RecurringIcon'
import ConfirmRecurringTask from '../Tasks/ConfirmRecurringTask'
// import { setAudioPlay } from '../../redux/slices/timer'

function HomePage() {
  // use hooks
  const dispatch = useDispatch()

  // global states
  const taskTitleRef = useRef('');
  const { taskList, dateChange, notes, activeTask, tasksGroupBy, focusTasksList } = useSelector(state => state.task)
  const { newBackgroundPopup, colorPicker, theme, colorPickerRGB } = useSelector(state => state.theme)
  const task = taskList?.filter(t => t.deletedAt || t.status == '1' || moment(t?.date).startOf('date').toISOString() != moment().startOf('date').toISOString() ? false : true)?.[0] ?? {};
  const [currentTaskData, setCurrentTaskData] = useState(task)
  const [list, setList] = useState([])
  const [isCurrentTaskCompleted, setIsCurrentTaskCompleted] = useState(false);
  const [editTaskListData, setEditTaskListData] = useState(false)
  const [addTaskInputValue, setAddTaskInputValue] = useState("")
  const [deleteTasks, setDeleteTasks] = useState(false)
  const [createTask, setCreateTask] = useState(false)
  const currentTimes = moment().startOf('date')
  const [currentTime, setCurrentTime] = useState(new Date(currentTimes))
  const [onAddTaskHome, setOnAddTaskHome] = useState(false)
  const date = moment(dateChange).format('YYYY-MM-DD')
  const currentDate = moment(currentTime).format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState()
  const [isCurrentValueEditable, setIsCurrentValueEditable] = useState(false)
  const [dateRange, setDateRange] = useState(new Date(currentTimes))
  const [isLoader, setIsLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [applyClor, setApplyColor] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [deleteRecurringTask, setDeleteRecurringTask] = useState(false)
  const [onDeleteActive, setOnDeleteActive] = useState(false)
  const [changeDateRecurringTask, setChangeDateRecurringTask] = useState({})

  const fetchAddTaskData = async (showLoader = true) => {
    // showLoader && setIsLoader(true)
    const currentTimes = moment(dateChange).startOf('date')
    const todayDate = new Date(currentTimes)

    let requestBody = {
      "date": moment(todayDate).toISOString() ? moment(todayDate).toISOString() : currentTime.toISOString(),
      "type": 1
    }
    try {
      let response = await fetchTaskList(requestBody)
      // showLoader && setIsLoader(false)
      dispatch(setTaskList(response?.data?.responseData))
      const pendingTasks = response?.data?.responseData.filter(t => t.status != 1 && moment(t?.date).startOf('date').toISOString() == moment().startOf('date').toISOString());
      pendingTasks?.length > 0 && setCurrentTaskData(pendingTasks[0])
      setOnAddTaskHome(false)
      setDeleteTasks(false)
      setList(response?.data?.responseData)
    } catch (error) {
      // showLoader && setIsLoader(false)
    }
  }

  const findNextIndex = () => {
    const index = currentTaskData?.id ? _.findIndex(taskList, ["id", currentTaskData?.id]) : 0;
    return taskList?.[index + 1] && taskList?.[index + 1].status != 1 ? index + 1 : null;
  }

  const onNextUpClick = () => {
    const index = findNextIndex();

    if (index)
      setCurrentTaskData(taskList[index]);
    else {
      setCurrentTaskData(null);
    }
  }


  const onCompleteTask1 = async (taskClick) => {
    let requestBody = {
      "id": taskClick?.id,
      "status": 1,
    }

    try {
      await completedTask(requestBody);
      toast.custom(<Toaster completeTaskData={true} currentPage="home" task={currentTaskData} title={"Task Complete"} />)
      setCurrentTaskData(null)
      dispatch(updateStatus(requestBody));
      onNextUpClick();
      setIsCurrentTaskCompleted(false);

    } catch ({ response }) {
    }
  };

  const onCompleteTask = async (taskClick, status = 1) => {
    let requestBody = {
      "id": taskClick?.id,
      status
    }
    try {
      if(taskClick?.repeat?.rule){
        const newTaskDate = new Date();
        const rruleSet = rrulestr(taskClick?.repeat?.rule, {
          forceset: true,
        })
        // If this is a recurring task and want to complete the current occurrence
        rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
        const updatedTask = {id: taskClick.id, date: taskClick?.date, repeat: {...taskClick?.repeat, rule: rruleSet.toString()}}
        await updateTaskList(updatedTask)
        dispatch(updateSetTaskList(updatedTask));

        // Extract relevant fields from task object
        const {
          title,
          tags,
          project,
          time,
          week,
          timeZone,
          taskSource
        } = taskClick;
  
        const response = await createTask({
          title,
          tags,
          project,
          time,
          week,
          timeZone, 
          status, 
          completedAt: newTaskDate, 
          date: newTaskDate, 
          repeat: null,
          origTaskId: taskClick?.id,
          orgRepeatId: taskClick?.orgRepeatId,
          taskSource
        })
        dispatch(pushSetTaskList(response?.data?.responseData?.data));
        status !== 0 && toast.custom(<Toaster undoRecurringTasks={[taskClick]} currentPage="home" fetchAddTaskData={undoRecurringTask} deleteTaskData={false} undoRecurringTask={true} title={"Task Complete"} task={taskClick} completeTaskData={false} newTask={response?.data?.responseData?.data} />)
        dispatch(updateFocusTasksWithRecurringTask({oldTask: taskClick, newTask: response?.data?.responseData?.data}));
      }else{
        await completedTask(requestBody);
        status !== 0 && toast.custom(<Toaster currentPage="home" title={"Task Complete"} task={taskClick} completeTaskData={true} />)
        dispatch(updateStatus(requestBody));
      }

      setCurrentTaskData(null)
      onNextUpClick();
      setIsCurrentTaskCompleted(false);
    } catch ({ response }) {

    }
  };

  const setTaskToDateConfirm = async (task, date, type) => {
    if(task?.repeat?.rule){
      setChangeDateRecurringTask({date, type})
    }else{
      setTaskToDate(task, date);
    }
  }

  const setTaskToDate = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": date
    };
    
    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        let response = await updateTaskList({...requestBody, repeat: null})
        dispatch(updateSetTaskList(requestBody))
      }
      fetchTotalCount() 
    } catch ({ response }) {}
  }

  const onDateChage = async (date) => {
    setDateRange(date)
    let requestBody = {
      "id": currentTaskData?.id,
      "title": currentTaskData?.title,
      "date": moment(date).startOf('date').toISOString()
    };
    try {
      let response = await updateTaskList(requestBody)
      dispatch(updateSetTaskList(requestBody));
      // fetchAddTaskData()
    } catch ({ response }) {

    }
  }

  const onDateChange = async (task, date, recurring = null) => {
    setChangeDateRecurringTask({});
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": moment(date).startOf('date').toISOString()
    };

    try {
      if(recurring !== null && recurring !== 3){
        await updateRecurringTask(task, date, recurring)
      }else{
        let response = await updateTaskList(requestBody)

        dispatch(updateSetTaskList(requestBody));
        setSelectedDate(moment(date).startOf('date').toISOString())
        setDateRange(moment(date).startOf('date').toISOString())
      }

    } catch (e) {
      console.log('error====', e)
    }
  }

  const updateRecurringTask = async (task, date, recurring) => {
    const newTaskDate = new Date();
    let rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    // If this is a recurring task and want to edit the current occurrence only
    if(recurring == 1){
      rruleSet.exdate(momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).endOf('day').toDate())
      rruleSet.exdate(momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate())
    }else{
      
      if(recurring == 2){
        // If this is a recurring task and want to edit the current occurrence and all future occurrences of the task
        const rrule = new RRule({...(RRule.parseString(rruleSet._rrule.toString())), until: momentTimezone(newTaskDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate()});
        rruleSet._rrule[0] = rrule;
        rruleSet.rrule(rrule);
      }
    }
    const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
    await updateTaskList(updatedTask)
    dispatch(updateSetTaskList(updatedTask));

    // Create new rule for new task
    rruleSet = rrulestr(task?.repeat?.rule, {
      forceset: true,
    })
    let rruleOptions = { dtstart: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    if(recurring == 1){
      rruleOptions = {...rruleOptions, until: momentTimezone(date).tz(momentTimezone.tz.guess()).endOf('day').toDate()}
    }else{
      rruleOptions = {...(RRule.parseString(rruleSet._rrule.toString())), ...rruleOptions}
    }

    let newRuleSet = new RRuleSet();
    newRuleSet.rrule(new RRule(rruleOptions));
    const repeat = {...task?.repeat, rule: newRuleSet.toString()};
    // Extract relevant fields from task object
    const {
      title,
      tags,
      project,
      time,
      week,
      timeZone,
      status,
      completedAt,
      isMostImportant
    } = task;

    const response = await createTask({
      title,
      tags,
      project,
      time,
      week,
      timeZone,
      date,
      status,
      completedAt,
      isMostImportant,
      repeat,
      origTaskId: task?.id,
      orgRepeatId: task?.orgRepeatId
    })
    dispatch(pushSetTaskList(response?.data?.responseData?.data));
  }

  useEffect(() => {
    fetchTotalCount();
    dispatch(setColorPickerRGB('rgb(39 203 154 / 10%)'))

    fetchSettingDetail()
    dispatch(setHeaderName('Upcoming'))
    //fetchAddTaskData();
    // if (!_.find(taskList, ["date", moment().startOf('date').toISOString()])) {
    //   fetchAddTaskData()
    // }else{
    //   fetchAddTaskData(false)
    // }
    document.body.classList.add('homepage')

    return () => document.body.classList.remove('homepage')
  }, [])

  useEffect(() => {
   // if (!_.find(taskList, ["date", moment(dateChange).startOf('date').toISOString()])) {
      fetchAddTaskData(false)
   // }
  }, [dateChange])

  useEffect(() => {
    const pendingTasks = taskList.filter(task => task.status != '1' && task.deletedAt && moment(task?.date).startOf('date').toISOString() != moment().startOf('date').toISOString() ? false : true);
    if (!pendingTasks.length) {
      setCurrentTaskData({})
    } else {
      setCurrentTaskData(pendingTasks.filter(t => t.deletedAt || t.status == '1' || moment(t?.date).startOf('date').toISOString() != moment().startOf('date').toISOString() ? false : true)?.[0] ?? {})
    }
  }, [taskList])


  const fetchTotalCount = async () => {
    try {
      let response = await totalTaskCount({ "date": currentTime.toISOString() })

      dispatch(setTotalCount(response?.data?.responseData))
    } catch ({ response }) {

    }
  }

  const fetchSettingData = async () => {
    if (applyClor == false) {
      try {
        let response = await fetchSetting()
        dispatch(setSettings(response?.data?.responseData?.settings ?? {}));

        if ((response?.data?.responseData?.settings?.theme == "dark") || (response?.data?.responseData?.settings?.theme == "dark")) {
          dispatch(setColorPickerMode("Dark"))
          dispatch(setTheme('dark'));
        } else {
          dispatch(setColorPickerMode("Light"))
          dispatch(setTheme('light'));
        }

        dispatch(setColorPicker(response?.data?.responseData?.settings?.accentColor ?? colorPicker))
      } catch ({ response }) {

      }
    }
  }

  // useEffect(()=>{
  //   fetchSettingData()
  // },[])

  const fetchSettingDetail = async () => {

    try {
      let response = await fetchSetting()
      dispatch(setSettings(response?.data?.responseData?.settings));
      dispatch(setColorPicker(response?.data?.responseData?.settings?.accentColor ?? colorPicker))
      // dispatch(setTheme(response?.data?.responseData?.settings?.theme));
      if ((response?.data?.responseData?.settings?.theme == "light") || (response?.data?.responseData?.settings?.theme == "Light")) {
        dispatch(setTheme("light"));
        dispatch(setColorPickerMode("Light"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #fffff;"))
      } else if ((response?.data?.responseData?.settings?.theme == "dark") || (response?.data?.responseData?.settings?.theme == "Dark")) {
        dispatch(setTheme("dark"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;"))
        dispatch(setColorPickerMode("Dark"));
      }
    } catch ({ response }) {

    }
  }

  const onDeleteClick = (currentTaskData) => {
    if(currentTaskData?.repeat?.rule){
      setDeleteRecurringTask(true)
    }else{
      onDeleteTask(currentTaskData);
    }
  }

  const onDeleteTask1 = async (currentTaskData) => {
    try {
      let response = await deleteTask({ "id": currentTaskData?.id })
      dispatch(deleteSetTaskList(currentTaskData))
      dispatch(setFocusTasksList(focusTasksList.filter(t => t?.id !== task?.id)))
      toast.custom(<Toaster fetchAddTaskData={fetchAddTaskData} currentPage="home" deleteTaskData={true} task={currentTaskData} data={response?.data?.message} title={"Task Deleted"} />)
    } catch ({ response }) {

    }
  }

  const onDeleteTask = async (task, recurring = null) => {
    setDeleteRecurringTask(false)
    try {
      let undoRecurringTasks = [task];
      const deleteDate = new Date();
      setOnDeleteActive(true)
      // If this is not a recurring task or want to delete all occurrences of the recurring task
      if(recurring === null || recurring === 3){
        dispatch(deleteSetTaskList(task))
        dispatch(setFocusTasksList(focusTasksList.filter(t => task?.orgRepeatId && t?.orgRepeatId ? t?.orgRepeatId !== task?.orgRepeatId : t?.id !== task?.id)))

        await deleteTask({ "id": task?.id })
        toast.custom(<Toaster currentPage="home" setDeleteLoader={setDeleteLoader} deleteLoader={deleteLoader} fetchAddTaskData={fetchAddTaskData} deleteTaskData={true} task={task} title={"Task Deleted"} fetchTotalCount={fetchTotalCount} />)
        fetchTotalCount()
      }else{
        const dtstart = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).endOf('day').toDate()
        // If this is a recurring task and want to delete the current occurrence
        if(recurring === 1){
          const rruleSet = rrulestr(task?.repeat?.rule, {
            forceset: true,
          })
          rruleSet.exdate(dtstart)
          const updatedTask = {id: task.id, repeat: {...task?.repeat, rule: rruleSet.toString()}}
          await updateTaskList(updatedTask)
          dispatch(updateSetTaskList(updatedTask));
        }else{
          undoRecurringTasks = [];
          _.filter(taskList, ["orgRepeatId", task?.orgRepeatId]).map(t => {
            let rruleSet = rrulestr(t?.repeat?.rule ? t?.repeat?.rule : (new RRule({dtstart, freq: RRule.DAILY})).toString(), {forceset: true})
            // Check if the occurrance's until less or greater than deleting date
            let ruleObj = RRule.parseString(rruleSet._rrule.toString());
            let until = momentTimezone(deleteDate).tz(momentTimezone.tz.guess()).add(-1, 'days').endOf('day').toDate();
            if(ruleObj?.until && moment(until).isAfter(ruleObj?.until)){
              until = ruleObj.until;
            }
            // If this is a recurring task and want to delete the current occurrence and all future occurrences of the task
            let rrule = new RRule({...ruleObj, until});
            rruleSet._rrule[0] = rrule;
            rruleSet.rrule(rrule);
            let updatedTask = {id: t.id, repeat: {...t?.repeat, rule: rruleSet.toString()}}
            updateTaskList(updatedTask)
            dispatch(updateSetTaskList(updatedTask));
            undoRecurringTasks = [...undoRecurringTasks, t]
          })
        }
        // Show undo button
        toast.custom(<Toaster undoRecurringTasks={undoRecurringTasks} fetchAddTaskData={undoRecurringTask} currentPage="home" undoRecurringTask={true} deleteTaskData={false} completeTaskData={false} task={task} title={"Task Deleted"} />)
      }

      setOnDeleteActive(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  const undoRecurringTask = (undoRecurringTasks, newCompletedTask = null) => {
    undoRecurringTasks.map(task => {
      dispatch(updateSetTaskList({id: task.id, status: task?.status, repeat: task.repeat}))
      updateTaskList({id: task.id, status: task?.status, repeat: task.repeat})
      // On undo recurring task, delete the new completed task
      if(newCompletedTask){
        deleteTask({ "id": newCompletedTask?.id });
        dispatch(updateSetTaskList({id: newCompletedTask?.id, deletedAt: new Date()}));
      }
    })
  }

  const onTomorrowClickSet = async (task) => {
    let requestBody = {
      "id": task?.id,
      "title": task?.title,
      "date": moment().startOf('date').add(1, 'days').toISOString()
    }
    try {
      let response = await updateTaskList(requestBody)
      dispatch(updateSetTaskList(requestBody));
      fetchTotalCount()
    } catch ({ response }) {

    }
  }

  const hideDatePickerOnHoverOut = (e) => {
    e.target.closest('.top-header-list')?.querySelector('.advancedDatePicker')?.classList?.remove('active');
  }

  const removeDropdowns = () => {
    deleteRecurringTask && setDeleteRecurringTask(false);
    changeDateRecurringTask && setChangeDateRecurringTask({});
  }

  // useEffect(() => {
  //   setShowTooltip(taskTitleRef.current.offsetWidth < taskTitleRef.current.scrollWidth)
  // }, [currentTaskData.title])

  // useEffect(()=>{
  //   if(window.location.path == process.env.REACT_APP_ROOT) dispatch(setAudioPlay(false))
  // },[])

  const [quotecollapse, setquotecollapse] = useState(true);


  if (!authentication())
    return <Navigate to={'/welcome'} />

  const DragHandle = SortableHandle(() => <span className="dragdrop-option"><DragIcon color="#fff" /></span>);

  const HighlightedTaskSortableItem = SortableElement(({currentTaskData}) => {
    //console.log('currentTaskDatacurrentTaskData', currentTaskData)
    let currentTaskSection = document.getElementById("currentTaskSection")
    const textColor = { color: theme == "dark" && ((colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" || colorPicker == "#EFB00F" || colorPicker == "#27cb9a" || colorPicker == "#23AFDB")) ? "black" : "white" };
    return (
      <div className="block-content top-header-list onHoverActive" onMouseLeave={removeDropdowns}>
        <DragHandle />
        {
          (currentTaskData?.status === 0) && <> <label className="check-container header-title" onMouseLeave={hideDatePickerOnHoverOut} css={{
            '&:hover input ~ .checkmark': {
              background: `${colorPicker} !important`
            }
          }}>
            <span className={`current-task-overflow ${theme} `} style={textColor} onClick={() => dispatch(setActiveTask(currentTaskData.id))} data-for={`longText-${currentTaskData?.id}`}>{currentTaskData?.title}
            </span>
            {currentTaskData.isMostImportant === 1 && <span class="most-important-task" style={{ margin: '0', top: 0, padding: 0, ...textColor }}><MostImportant color={textColor.color} fill={textColor.color} /> Task of the Day</span>}
            {/* <span className='currentTask-tooltip'>{currentTaskData?.title}</span> */}
            <input type="radio" name="currentTask" checked={isCurrentTaskCompleted} onChange={() => onCompleteTask(currentTaskData)} />
            <span className={`checkmark ${theme}`}
              style={{ border: theme == "dark" && ((colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#EFB00F" || colorPicker == "#4cc9f0" || colorPicker == "#27cb9a" || colorPicker == "#23AFDB")) ? "1px solid black" : "1px solid white" }}
            />
          </label>
          {
            changeDateRecurringTask.type === 'datepicker' && <ConfirmRecurringTask iconComponent={<CalendarIcon color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDateChange(currentTaskData, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
          }
          <div className='task-meta-wrapper maintask-meta' style={{marginTop: 0}}>
            {currentTaskData.taskSource !== null && (
              <Tooltip className='overDue-tooltip tooltip-mt0' text={currentTaskData.taskSource} wrapperClassName="relative" color={colorPicker}> 
                <a className="hovr-btn tooltip-hover">
                  <span
                    key={"task-" + currentTaskData.id}
                    className=" flex items-center justify-center gap-2"
                  >
                    <img width={15} src={`/images/${currentTaskData.taskSource}.png`} alt={currentTaskData.taskSource} />
                  </span>
                </a>
              </Tooltip>
            )}
            <RecurringIcon task={currentTaskData} overdue={false} colorPicker={"#ffffff"} />
            {currentTaskData.Project && (
              <span
                key={"task-" + currentTaskData.id}
                className=" flex items-center justify-center gap-2"
              >
                <FolderCloseIcon strokeColor={textColor.color} />
                <span className="text-sm"  style={textColor}>
                  {_.capitalize(currentTaskData.Project.title)}
                </span>
              </span>
            )}
            {currentTaskData.Tags &&
              currentTaskData.Tags.map(({ name, tagId }) => (
                <span
                  key={tagId}
                  className=" flex items-center justify-center gap-2"
                >
                  <TagIcon strokeColor={textColor.color} />
                  <span className="text-sm"  style={textColor}>
                    {_.capitalize(name)}
                  </span>
                </span>
              ))}
          </div> 
            <div className="on-hover-list" css={{
              background: colorPicker,
              boxShadow: `-8px 0px 24px 23px ${colorPicker}`
            }}>
              <ul className="hover-action">
                <MostImportantTaskButton theme={theme} color="grey" task={currentTaskData} colorPicker={colorPicker} newTaskDate={currentTaskData?.date ? new Date(moment().startOf('date')).toISOString() : null} currentPage="home" />

                <li className="hover-action-li" style={{ cursor: "pointer" }} onClick={() => setTaskToDateConfirm(currentTaskData, moment().startOf('date').add(1, 'days').toISOString(), 'tomorrow')} >
                  <Tooltip text="Move to tomorrow" className='overDue-tooltip' color={colorPicker}>
                      <a className="hovr-btn tooltip-hover" style={{ cursor: "pointer", background: theme == 'dark' ? '#000000' : '#ffffff' }}><Clock color="grey" /></a>
                  </Tooltip>
                  {
                    changeDateRecurringTask.type === 'tomorrow' && <ConfirmRecurringTask iconComponent={<Clock color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => setTaskToDate(currentTaskData, changeDateRecurringTask.date, recurring)} onHide={() => setChangeDateRecurringTask({})} />
                  }
                </li>

                <li className="hover-action-li">
                  <span style={{ position: 'relative' }}>
                    <button className="date-picker-icon v2" type="button" style={{ backgroundColor: theme == 'dark' ? '#000000' : '#ffffff' }}></button>
                    <Datepicker onChange={(date) => { setTaskToDateConfirm(currentTaskData, date, 'datepicker') }} value={dateRange} />
                  </span>
                </li>

                <li className="hover-action-li" data-tip data-for="deleteTaskTooltipCurrentTask" onClick={() => onDeleteClick(currentTaskData)} >
                  <Tooltip className='overDue-tooltip' text="Delete task" color={colorPicker}>
                    <a style={{ background: theme == 'dark' ? '#000000' : '#ffffff' }} className={`hovr-btn ${onDeleteActive ? 'active' : ''}`}>
                      <Trash color="grey" />
                    </a>
                  </Tooltip>
                  {
                    deleteRecurringTask && <ConfirmRecurringTask iconComponent={<Trash color="grey" />} colorPicker={colorPicker} theme={theme} onConfirm={(recurring) => onDeleteTask(currentTaskData, recurring)} onHide={() => setDeleteRecurringTask(false)} />
                  }
                </li>
              </ul>
            </div>

            {/* <div className='onHoverShorts taskBar'>
              <div className='actOnhove'>
                <div className='hovAct ht-collapse' onClick={() => setquotecollapse(false)}> <CollapseIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                <div className="hovAct ht-scale"> <FullscreenIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                <div className='hovAct ht-settings'> <SettingsIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
              </div>
            </div> */}

            {/* <div className='barContent'  onClick={() => setquotecollapse(true)}>
              <div className='barContentHeading'>
                  <h4 className='barHead'>Quick Links</h4>
                  <span className='headBrk'></span>
              </div>
            </div> */}

          </>

        }

      </div>
    )
  })

  const HighlightedTask = (({currentTaskData} ) => {
    return (
      <div key={`item-${currentTaskData.id}`}>
        {
          activeTask == currentTaskData.id && <EditTaskList
            item={currentTaskData}
            setEditTaskId={id => dispatch(setActiveTask(id))}
            currentPage="home"
            homePage={true}
          />
        }
        {
          activeTask != currentTaskData.id && <>
            {
              ((currentTaskData?.title) && (currentTaskData?.status != 1)) &&
              <>
                {
                  showTooltip && <ReactTooltip id={`longText-${currentTaskData?.id}`} place="right" effect="solid" className="reactTooltipCss"> {currentTaskData?.title} </ReactTooltip>
                }
                {
                  ((taskList?.length > 0) && (date == currentDate)) && <div className="form-header" style={{ backgroundColor: colorPicker }}>
                    <div className="form-header-block">
                      {
                        <label className="top-level" style={{ color: theme == "dark" && ((colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#EFB00F" || colorPicker == "#27cb9a" || colorPicker == "#4cc9f0" || colorPicker == "#23AFDB")) ? "black" : "white" }}>Next up </label>}

                      <HighlightedTaskSortableItem index={0} currentTaskData={currentTaskData} />
                    </div>
                  </div>
                }
              </>

            }
          </>
        }
        {/* {date == currentDate && <AddTask isActive={true} currentPage="home" selectedDate={selectedDate} setSelectedDate={setSelectedDate} setCreateTask={setCreateTask} onAddTaskHome={onAddTaskHome} setOnAddTaskHome={setOnAddTaskHome} />} */}
      </div>
    )
  })

  return (
    // <div className="form-content todayPage" style={{ boxShadow: `0px 4px 250px ${hexToRgb(colorPicker)}` }}>
    
    <div className="form-content todayPage" style={{ filter: `drop-shadow(0px 4px 250px ${hexToRgb(colorPicker)})` }}>
      <div className="metting-form common-form" id="meeting-form">
        {
          //isLoader || true ? <AllLoader /> :
          false && isLoader ? <AllLoader customClassName="form-content" /> :
            <>
              {/* {
                date != currentDate && tasksGroupBy === 'none'
                &&
                <AddTask isActive={true} currentPage="home" selectedDate={selectedDate} setSelectedDate={setSelectedDate} setCreateTask={setCreateTask} onAddTaskHome={onAddTaskHome} setOnAddTaskHome={setOnAddTaskHome} />
              } */}
              {/* <span style={{height: '0'}} className={`current-task-overflow`} ref={taskTitleRef}>{currentTaskData?.title}
              </span> */}
              <div className='task-list' style={{ background: theme == "dark" ? "black" : "" }}>
                <div className='task-list-data'>
                  <div className='ul-task-list'>
                    <Accordion preExpanded={['accitem0']}>
                      <TaskListDetails
                        HighlightedTaskSortableItem={HighlightedTask}
                        selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                        taskList={list}
                        currentTaskData={currentTaskData}
                        homePage={true}
                        currentPage="home"
                        isTodayPage={date == currentDate}
                        setAddTaskInputValue={setAddTaskInputValue}
                        setEditTaskListData={setEditTaskListData}
                        setDeleteTasks={setDeleteTasks}
                        fetchAddTaskData={fetchAddTaskData}
                        taskComplete={true}
                        onAddTaskHome={onAddTaskHome}
                        setOnAddTaskHome={setOnAddTaskHome}
                        setDeleteLoader={setDeleteLoader}
                        deleteLoader={deleteLoader}
                        fetchTotalCount={fetchTotalCount}
                        onDateChage={onDateChage}
                        onTomorrowClickSet={onTomorrowClickSet}
                        onCompleteTask={onCompleteTask}
                        dateRange={dateRange}
                      />
                    </Accordion>
                  </div>

                </div>
              </div>
              <JournalSection />
            </>
        }
      </div>
      {
        newBackgroundPopup && (
          <ColorPickerSetting
            show={newBackgroundPopup}
            onHide={() => { dispatch(setNewBackgroundColor(false)); fetchSettingData() }}
            setApplyColor={setApplyColor}
          />
        )
      }
    </div>
  )
}

export default HomePage