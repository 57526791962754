import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { focusTimerFormatV4, hexToRgb } from '../../../Components/action/utilities';
import { useSelector } from 'react-redux';

const FocusTimerCompleteModal = ({ onHide, show, todaySessions, lastSessionCompletionTime = 0 }) => {
    const { theme, colorPicker } = useSelector(state => state.theme);

    return (
        <Modal show={show} onHide={onHide} className={`focus-timer-complete-modal task-modal ${theme}`} style={{filter: `drop-shadow(0px 10px 25px ${hexToRgb(colorPicker)})`}}>
            <Modal.Body>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                        <div className='focus-timer-complete-body'>
                            {
                                <>
                                    <p style={{color: colorPicker}}><span dangerouslySetInnerHTML={{__html: lastSessionCompletionTime}}></span> done</p>
                                    <h1 style={{color: theme === 'dark' ? '#fff' : '#000'}}>Focus Session {todaySessions.length} Complete</h1>
                                </>
                            }
                            
                            <p style={{fontFamily: 'sans serif', color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}}>Checkout the summary of your session or add more time if needed.</p>
                            <a style={{background: colorPicker}} href="#" className='btn' onClick={onHide}>Okay</a>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default FocusTimerCompleteModal