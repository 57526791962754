/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { GithubPicker, CirclePicker } from 'react-color';
import { setSetting } from '../../Components/action/common';
// import { pushToSetting } from '../../redux/slices/TaskSlice';
import { setColorPicker, setColorPickerMode, setTheme, setThemeColor } from '../../redux/slices/ThemeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import { pushToSetting } from '../../redux/slices/SettingSclice';

function ColorPickerSetting({ show, onHide, setApplyColor }) {
  const dispatch = useDispatch()

  // global state
  const { settings } = useSelector(state => state.settings)
  const { colorPicker, colorPickerMode, theme } = useSelector(state => state.theme)

  const popover = {
    position: 'absolute',
    zIndex: '99999',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const color = ["#EF233C", "#F8961E", "#FFCA3A", "#27CB9A", "#4CC9F0", "#3A86FF", "#9B5DE5", "#EF476F"]
  const lightModeColorPicker = ["#EF233C", "#F8961E", "#EFB00F", "#27CB9A", "#23AFDB", "#3A86FF", "#9B5DE5", "#EF476F"]

  const [colorValue, setColorValue] = useState(null);

  const handleChangeComplete = async (color, event) => {
    dispatch(setColorPicker(color?.hex))
    setColorValue(color?.hex)
  }

  const onApplyColorPicker = async () => {
    setApplyColor(true)
    dispatch(setColorPicker(colorValue))
    try {
      const newSettings = { ...settings, accentColor: colorValue, theme: theme };
      dispatch(pushToSetting(newSettings));
      let response = await setSetting({ settings: newSettings });
      // Update color settings in chrome storage for use in extension background script
      // if(window?.chrome?.storage){
      //   window?.chrome.runtime.sendMessage({ action: 'getColor'});
      // }
      console.log('ok done')
      onHide()

    } catch (error) {
      console.log('errorerrorerror', error)
    }

  }

  useEffect(() => {
    let switchLight = document.getElementById("switchLight")
    let switchDark = document.getElementById("switchDark")
    if (colorPickerMode == "Light") {
      switchLight.checked = true
      switchDark.checked = false
    }
    if (colorPickerMode == "Dark") {
      switchDark.checked = true
      switchLight.checked = false
    }

  }, [])

  const onThemeModeChange = async (value) => {
    if (value == "Light") {
      try {
        const newSettings = { ...settings, theme: value };
        let response = await setSetting({ "settings": newSettings })
        dispatch(setTheme("light"));
        dispatch(setColorPickerMode("Light"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #fffff;"))

        if(lightModeColorPicker.indexOf(colorPicker.toUpperCase()) === -1){
          const newColor = lightModeColorPicker[color.indexOf(colorPicker.toUpperCase())];
          if(newColor){
            dispatch(setColorPicker(newColor))
            setColorValue(newColor)
          }
        }
      } catch ({ response }) { }

    } else if (value == "Dark") {
      try {
        const newSettings = { ...settings, theme: value };
        let response = await setSetting({ "settings": newSettings })
        dispatch(setTheme("dark"));
        dispatch(setColorPickerMode("Dark"));
        dispatch(setThemeColor("linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;"))
        if(color.indexOf(colorPicker.toUpperCase()) === -1){
          const newColor = color[lightModeColorPicker.indexOf(colorPicker.toUpperCase())];
          if(newColor){
            dispatch(setColorPicker(newColor))
            setColorValue(newColor)
          }
        }
      } catch ({ response }) {

      }

    }

  }

  return (
    <Modal show={show} onHide={onHide} className="colorPicker-model">
      <Modal.Body>
        <div className="cont-ainer">
          <div className="switches-container">
            <input type="radio" id="switchLight" name="switchPlan" defaultValue="Monthly" className="tg-input" />
            <input type="radio" id="switchDark" name="switchPlan" className="tg-input" defaultValue="Yearly" />

            <label htmlFor="switchLight" onClick={() => {
              onThemeModeChange("Light")

            }}
              className="tg-label"  ><img src="/images/today-menu.svg" alt="" />
            </label>

            <label htmlFor="switchDark" onClick={() => {
              onThemeModeChange("Dark")

            }}
              className="tg-label" ><img src="/images/night.svg" alt="" />
            </label>


            <div className="switch-wrapper">
              <div className="switch">

                <div className="tg-data" css={{
                  background: colorPicker,
                  border: `1px solid ${colorPicker}`
                }}><img src="/images/today-menu.svg" alt="" /></div>

                <div className="tg-data" css={{
                  background: colorPicker,
                  border: `1px solid ${colorPicker}`
                }}><img src="/images/night.svg" alt="" /></div>

              </div>
            </div>
          </div>
        </div>
        <div className="field-block m-5">
          <CirclePicker onChangeComplete={handleChangeComplete} colors={theme == "dark" ? color : lightModeColorPicker} />

        </div>
        <button className="colorPickerButton" onClick={onApplyColorPicker} css={{
          background: colorPicker,
          border: `1px solid ${colorPicker}`,
          color:(theme == "dark" && (colorPicker == "#27cb9a" || colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" || colorPicker == "#3a86ff"))? "black !important" :"white !important"

        }}>Apply</button>
      </Modal.Body>
    </Modal>
  )
}

export default ColorPickerSetting