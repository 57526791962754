import React from 'react'
import { useSelector } from 'react-redux'

function PlanMenuIcon({activeSetting , isMouseEnterSetting}) {
  const {theme , colorPicker}=useSelector(state=>state.theme)

  const strokeColorCondition = ((theme == "dark" && activeSetting ==  false )) ? "#4C4C4C" : "white"
  const onMouseEnterCondition= isMouseEnterSetting && "black"
  const strCol = ((theme == "dark" && (colorPicker == "#27cb9a" || colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" )) && activeSetting) ? "black" : ""
  const strCol1 = (isMouseEnterSetting && activeSetting) ? onMouseEnterCondition : strokeColorCondition
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="lucide/arrow-up-from-dot">
            {
                strCol ? 
                <path id="Vector" d="M4.16675 7.49996L10.0001 1.66663M10.0001 1.66663L15.8334 7.49996M10.0001 1.66663V13.3333M10.8334 17.5C10.8334 17.9602 10.4603 18.3333 10.0001 18.3333C9.53984 18.3333 9.16675 17.9602 9.16675 17.5C9.16675 17.0397 9.53984 16.6666 10.0001 16.6666C10.4603 16.6666 10.8334 17.0397 10.8334 17.5Z" stroke={strCol} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                : 
                <path id="Vector" d="M4.16675 7.49996L10.0001 1.66663M10.0001 1.66663L15.8334 7.49996M10.0001 1.66663V13.3333M10.8334 17.5C10.8334 17.9602 10.4603 18.3333 10.0001 18.3333C9.53984 18.3333 9.16675 17.9602 9.16675 17.5C9.16675 17.0397 9.53984 16.6666 10.0001 16.6666C10.4603 16.6666 10.8334 17.0397 10.8334 17.5Z" stroke={strCol1} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            }
        </g>
    </svg>
  )
}

export default PlanMenuIcon