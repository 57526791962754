/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { deleteAccount } from '../../../Components/action/common'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { jsx } from "@emotion/core";
import { AuthContext } from '../../../AuthProvider'
import { EXTENSION, SITE_BASE_URL } from '../../../Components/common/constants'

function LogoutPopup({ show, onHide }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setIsAuthenticated } = useContext(AuthContext);
  
  const {  colorPicker } = useSelector(state => state.theme)
  const { settings } = useSelector(state => state.settings)


  const onLogout = () => {
    // Clear chrome local storage in case of extension
    if(window?.chrome?.storage){
      window?.chrome.runtime.sendMessage({ action: 'logout-all-tabs', siteBase: SITE_BASE_URL});
      // Logout from the webapp
      window.open(`${SITE_BASE_URL}/logout`, '_blank');
      window?.chrome.storage.local.clear(function () {}); 
    }else{
      try{
        if(window?.chrome){
          // Logout of lifetab main chrome extension
          window?.chrome.runtime.sendMessage(EXTENSION.ID.LIFETAB, { action: 'logout-extension' }, (response) => {
            console.log('Logout of all extension tabs');
          });

          // Logout of lifetab time tracker chrome extension
          window?.chrome.runtime.sendMessage(EXTENSION.ID.TIMETRACKER, { action: 'logoutTimeTracker' }, (response) => {
            console.log('Logout of time tracker', response);
          });
        }
      }catch(e){
        console.log('ERROR: Logout of all extension tabs', e);
      }
    }

    clearStorage();
  }

  const clearStorage = () => {
    document.cookie = 'authToken=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.clear();
    dispatch({ type: "DESTROY_SESSION" });
    setIsAuthenticated(false);
    navigate('/welcome')
  }

  return (
    <Modal show={show} onHide={onHide} className='deleteAccount'
      css={{
        background: `${colorPicker} !important`,
      }}
      style={{ backgroundColor: `${colorPicker} !important` }}>

      <Modal.Body>
        <div className='confirmation-popup'>
          <div className='popup-wrap'>
            <div className='popup-container'>
              <label className='sm-label'>Logout Account</label>
              <div className='popup-action mt-30'>
                <label className='pl-label'> Are you sure? </label>
                {/* <p className='popup-content'>This will immediately delete all of your data including tasks, projects, comments, and more. This can’t be undone.</p> */}
                <div className="field-data">
                  <div className="field-button">
                    <button className="changeName pop-cancel" 
                    css={{
                      color: `${colorPicker} !important`,
                    }}
                    onClick={onHide}>Cancel</button>
                    <button className="changeName pop-delete" onClick={() => onLogout()}>Logout </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LogoutPopup

