import React from 'react'

function HabbitMenuIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99984 4.99999C10.4601 4.99999 10.8332 4.6269 10.8332 4.16666C10.8332 3.70642 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70642 9.1665 4.16666C9.1665 4.6269 9.5396 4.99999 9.99984 4.99999Z" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 16.6667L10 11.6667L12.5 16.6667" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 6.66667L10 8.33334L15 6.66667" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 8.33333V11.6667" stroke="#4C4C4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default HabbitMenuIcon