/** @jsxRuntime classic */
/** @jsx jsx */
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { jsx } from "@emotion/core";
import { ChatIcon, CopyIcon, MaximizeIcon, MinimizeIcon, SendIcon, StopIcon } from '../LandingHeader/Feedback/icons/Icons';
import './style.css';
import {getChatTitle, saveConversation, sendPrompt, stopGeneratingCompletion} from '../action/chatGPT';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { clearChat, setChat, setCurrentPersona, setCurrentPrompt } from '../../redux/slices/AiAssistant';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { CHATGPT } from '../common/constants';
import { DragHandler } from '../../pages/Tasks/DragHandler';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

const Chat = forwardRef(({defaultMaximize = false}, promptInput) => {
    const [focus, setFocus] = useState(defaultMaximize ? true : false);
    const [copyMessageId, setCopyMessageId] = useState(null);
    const {id, chatData, persona, prompt, title} = useSelector(state =>  state.aiAssistant);
    const [completions, setCompletions] = useState(chatData.length > 0 ? chatData : [{"role": "system", "content": "You are a helpful assistant."}]);
    const [progress, setProgress] = useState(false);
    const chatContainerRef = useRef('');
    const personaRef = useRef('');
    const {theme, colorPicker} = useSelector(state => state.theme)
    const [personaSelection, setPersonaSelection] = useState(null);
    const [promptSelection, setPromptSelection] = useState(null);
    const [responseDone, setResponseDone] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        setCompletions(chatData.length > 0 ? chatData : [{"role": "system", "content": "You are a helpful assistant."}]) 
        !chatData.length && defaultMaximize && promptInput.current.focus();
    }, [chatData])

    useEffect(() => {
        defaultMaximize && promptInput.current.focus();
    }, [])

    useEffect(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [completions])

    useEffect(() => {
        personaSelection !== null && dispatch(setCurrentPersona(personaSelection));
    }, [personaSelection])

    useEffect(() => {
        if(persona?.description){
            setCompletions(completions => {
                const c = JSON.parse(JSON.stringify(completions));
                c[0].content = persona.description;
                return c;
            })
        }
    }, [persona])

    useEffect(() => {
        if(prompt.length > 0){
            promptInput.current.style.height = '18px';
            promptInput.current.style.height = promptInput.current.scrollHeight + "px";
            promptInput.current.focus();
            setFocus(true);
        }
    }, [prompt])

    useEffect(() => {
        if(promptSelection?.description){
            dispatch(setCurrentPrompt(promptSelection.description));
        }
    }, [promptSelection])
    
    const toggleSendBtn = (e) => {
        e.target.style.height = '18px';
        e.target.style.height = e.target.scrollHeight + "px";
        promptInput.current.value = e.target.value.replace(/^[\s]+/, '');
        dispatch(setCurrentPrompt(promptInput.current.value));
    }
    
    const send = (e, text = null) => {
        e.preventDefault();
        //const prompt = promptInput.current.value;
        let prmt = text !== null ? text : prompt;
        if(prmt.length > 0){
            let data = [...completions, {id: `chatgpt-${Date.now()}`, "role": "user", "content": prmt, sender: 'You', created: Math.floor((new Date()).getTime() / 1000)}].filter(t => t.role != 'error');
            promptInput.current.value = '';
            promptInput.current.style.height = '20px';
            setTimeout(() => {
                promptInput.current.focus();
            }, 100)
            dispatch(setCurrentPrompt(''));
            setCompletions(data);
            setProgress(true);
            setResponseDone(false);
            sendPrompt(data.map(d => ({role: d.role, content: d.content}))).then(async (reader) => {
                while (true) {
                    const {value, done} = await reader.read();
                    if (done) {
                        setResponseDone(true);
                        break;
                    }

                    let prompts = value.match(/\{([^\n]*)/g);
                    if(prompts){
                        prompts.map(pmt => {
                            let p = JSON.parse(pmt);
                            if(p.choices[0].finish_reason == 'stop'){
                                // Get chat title
                                if(title !==  null){
                                    saveConversation({id, title, conversation: JSON.stringify(data)}).then(response => {
                                        dispatch(setChat({data, title, id: response.data.responseData?.id ?? null}));
                                    });
                                }else{
                                    getChatTitle([...(data.map(d => ({role: d.role, content: d.content}))), {role: 'user', content: `Very short title for the following question:
                                    
                                    ${data[1].content}`}]).then(response => {
                                        // Save the chat
                                        const chatTitle = response?.choices?.[0]?.message?.content ?? 'New Chat';
                                        saveConversation({id, title: chatTitle, conversation: JSON.stringify(data)}).then(response => {
                                            dispatch(setChat({data, title: chatTitle, id: response.data.responseData?.id ?? null}));
                                        });
                                    })
                                }
                                
                                return;
                            }
                                
                            let index = _.findIndex(data, ["id", p.id]);
                            if(index > -1){
                               data[index] = {...data[index], content: `${data[index].content}${p.choices[0].delta.content}`, sender: persona?.label, created: p.created} 
                               setCompletions([...data]);
                              // dispatch(setChat({data, title, id: p.id}));
                            }else{
                                data.push({id: p.id, role: p.choices[0].delta.role, content: '', sender: persona?.label, created: p.created});
                                setTimeout(() => setProgress(false), 100)
                            }
                        })
                    }
                }
            }).catch(e => {
                setResponseDone(true);
                if(e.name == 'AbortError')
                    return;

                data.push({id: `chatgpt-error-${Date.now()}`, prompt: prmt, role: 'error', content: 'Oops! Something went wrong.', sender: persona?.label, created: Math.floor((new Date()).getTime() / 1000)});
                setTimeout(() => setProgress(false), 100);
                const chatTitle = title ?? 'New Chat';
                saveConversation({id, title: chatTitle, conversation: JSON.stringify(data)}).then(response => {
                     dispatch(setChat({data, title: chatTitle, id: response.data.responseData?.id ?? null}));
                });
            });

            setTimeout(() => {
                document.getElementById('container_aiAssistant')?.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }, 100)
        }
    }
    function scrollToBottom() {    
        const element = chatContainerRef.current;
        if(!element.classList.contains('dnt-scroll')){
            element.scrollTop = element.scrollHeight;
        }
    }

    const startNewChat = (e) => {
        e.preventDefault(); 
        dispatch(clearChat());
        promptInput.current.focus();
        setFocus(true);
        setTimeout(() => {
            promptInput.current.style.height = '18px';
        }, 100)
    }

    const toggleMinMax = (e) => {
        e.preventDefault();

        if(defaultMaximize){
            navigate(process.env.REACT_APP_ROOT)
        }else{
            navigate('/ai-assistant')
        }
    }

    const chatBoxClickHandler = (e) => {
        const gptContainer = document.querySelector('.gptContainer');
        if(gptContainer && !gptContainer.classList.contains('mounted')){
            gptContainer.classList.add('mounted')
            return;
        }
        if(e.target.closest('.timeline-li') || e.target.closest('.newChat') || e.target.closest('.chatTextarea') || e.target.closest('.chat-action') || e.target.closest('.cstmGetoption') || e.target.closest('.customSelect__menu__option')){
            setFocus(true);
        }else{
            setFocus(false);
        }
    }

    useEffect(() => {
        const element = chatContainerRef.current;
        element.addEventListener('DOMSubtreeModified', scrollToBottom);
        document.addEventListener('click', chatBoxClickHandler);

        return () => {
            element.removeEventListener('DOMSubtreeModified', scrollToBottom);
            document.removeEventListener('click', chatBoxClickHandler);
        };
    }, []);

    const copyMessageToClipboard = (e, text, id) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        chatContainerRef.current.classList.add('dnt-scroll')
        setCopyMessageId(id)
    }

    const removeActivePersona = (e) => {
        dispatch(setCurrentPersona(CHATGPT.PERSONAS[0]));
    }

    const stopGenerating = (e) => {
        e.preventDefault();
        stopGeneratingCompletion();
        setResponseDone(true);
    }

    return (
        <div className={`gptContainer widgetWrapper quoteInner ${completions?.length > 1 ? 'activeChat' : ''} ${focus || prompt?.length > 0 ? 'onActive' : ''} pb-20`}>
            <div className='ctTitle'>{title ? title : 'New Chat . . .'}</div>
            {!defaultMaximize && <DragHandler />}
            {
                completions?.length > 1 
                && 
                <div className='forHome'>
                    <div className='chatHeader'>
                        <button className="newChat smBtn" style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}} onClick={startNewChat}><i className="chatIco"><ChatIcon color = {theme == 'dark' ? '#000' : '#fff'} /></i> <span className="btnText">New Chat</span></button>

                        <button className="btnExpend smBtn" style={{background : theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000" : "", color: theme== "dark" ? "#8C8C8C" :""}} onClick={toggleMinMax} color={theme== "dark" ? '#5E5E5E' :"#A0A0A0"}><i className="chatIco">{!defaultMaximize ? <MaximizeIcon color = {theme == 'dark' ? '#5E5E5E' : '#A0A0A0'} /> : <MinimizeIcon color = {theme == 'dark' ? '#5E5E5E' : '#A0A0A0'} />}</i> <span className="btnText">Expand</span></button>  
                    </div>
                </div>
            }
            <div ref={chatContainerRef}  className='completions-wrapper' id="completion" style={{color: theme== "dark" ? "white" :"black"}} css={{
                height: completions?.length > 1 ? `calc(100vh - 30vh - ${promptInput.current.clientHeight + 60}px) !important` : 'auto'
            }}>
                <div className='main-completion-wrapper'>
            {
                completions.map(completion => {
                    return completion.role == "system" 
                    ? 
                    null 
                    : 
                    <div key={completion.id} className={`completions ${completion.id} ${completion.role}`} style={{background: completion.role == 'assistant' || completion.role == 'error' ? (theme== "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgb(0, 0, 0)" : (defaultMaximize ? "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF" : '#fff')) : 'inherit'}}>
                        {
                            completion.role != 'error'
                            &&
                            <span href="#" className='copyMessage' onClick={e => copyMessageToClipboard(e, completion.content, completion.id)} css={{
                                '&:hover svg rect': {
                                   fill: colorPicker
                                },
                                '&:hover svg path': {
                                    stroke: '#fff'
                                 }
                            }}>{copyMessageId == completion.id ? <span className='copied' style={{background: colorPicker}}>Copied</span> : <CopyIcon fill={theme == 'dark' ? 'gray' : '#E5E5E5'} />}</span>
                        }
                        <div className={completion.role} css={{
                            '&.assistant': {
                                backgroundColor: `${colorPicker} !important`
                            },
                            '&.error': {
                                backgroundColor: `${colorPicker} !important`
                            },
                            '&.user': {
                                background: theme== "dark" ? '#000' : '#fff'
                            }
                        }}>
                            <div className='persona-details'><span>{completion.sender}</span><span className='time'>{completion.created ? moment(parseInt(completion.created) * 1000).format('LT') : ''}</span></div>

                            {
                                completion.role == 'assistant' 
                                ? 
                                <ReactMarkdown 
                                    children={completion.content} 
                                    remarkPlugins={[remarkGfm, remarkMath]} 
                                    rehypePlugins={[rehypeMathjax, rehypeKatex]}
                                    components={{
                                        code({node, inline, className, children, ...props}) {
                                            const match = /language-(\w+)/.exec(className || '');

                                            return !inline ? (
                                                <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={dark}
                                                language={(match && match[1]) || ''}
                                                />
                                            ) : (
                                                <pre><code {...props} className={className}>
                                                {children}
                                                </code></pre>
                                            )
                                        }
                                      }} 
                                /> 
                                : 
                                completion.content
                            }

                            {
                                completion.role == 'error'
                                &&
                                <div><a style={{color: colorPicker}} href="#" onClick={e => send(e, completion.prompt)}>Try again</a></div>
                            }
                        </div>
                    </div>
                })
            }
            {
                progress && <div className='progress' style={{background: theme== "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgb(0, 0, 0)" : (defaultMaximize ? "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF" : '#fff')}} css={{
                    'span':{
                        background: `${colorPicker} !important`
                    },
                    'ellipse': {
                        background: `${colorPicker} !important`
                    }
                }}><span></span><ellipse className='dot1'></ellipse><ellipse className='dot2'></ellipse><ellipse className='dot3'></ellipse></div>
            }
            {
                !responseDone && <div className='stop-generating' style={{color: colorPicker, backgroundColor: colorPicker}} css={{
                    '&:hover a': {
                        color: colorPicker
                     }
                }}>
                   <a href="#" style={{color: colorPicker}} onClick={stopGenerating}><StopIcon color={colorPicker} /> Stop Generating</a>
                </div>
            }
            </div>
            </div>
            <div className='post-prompt-wrapper'>
                {persona && persona.value != 'Default' && <div onClick={removeActivePersona} className='hasOptions has-Prompts' ref={personaRef}>
                    <span className='pmt-text active-persona' style={{color: colorPicker}}>Talking to {persona?.label}</span>
                    <span className='pmt-text remove'>Remove</span>
                </div>}

                <textarea onKeyDown={e => {
                    if(e.key == 'Enter') {
                        e.shiftKey === false && send(e);
                    }
                    }} style={{color: theme== "dark" ? "#fff" :"#000"}} value={prompt} ref={promptInput} placeholder={focus ? 'Type here ...' : 'Ask AI ...'} type="text" className="prompt-input chatTextarea" onInput={toggleSendBtn} onFocus={e => setFocus(true)} css={{
                        textIndent: defaultMaximize && persona && persona.value != 'Default' ? `${(personaRef?.current?.offsetWidth ?? 0) + 10}px !important` : '0',
                        backgroundPositionX: persona && persona.value != 'Default' ? `${(personaRef?.current?.offsetWidth ?? 0) + 5}px !important` : '0',
                        '&::placeholder': {
                            color: theme== "dark" ? "#565656" :"#000"
                        }
                    }}>
                </textarea>
                {
                    prompt.length > 0
                    ?
                    <div className='sendPrompt'>
                        <a href="#" onClick={send} className="send-prompt" style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}}>{chatData.length > 0 ? 'Send' : 'Start'} <SendIcon color={theme== "dark" ? "black" : "white"} /></a>
                    </div>
                    :
                    <div className='sendPrompt'>
                        <a href="#" onClick={send} className="send-prompt">{chatData.length > 0 ? 'Send' : 'Start'} <SendIcon color={theme== "dark" ? "gray" : "#737373"} /></a>
                    </div>
                }

                <div className='helpingSearch chat-action'>
                    <div className='hl-slect' css={{
                        '.customSelect__menu__single-value .sl-name': {
                            color: `${colorPicker} !important`
                        },
                        '.customSelectcontainer .customSelect__menu__menu .customSelect__menu__option:hover': {
                            background: `${colorPicker} !important`
                        },
                        '.customSelectcontainer .customSelect__menu__menu .customSelect__menu__option.customSelect__menu__option--is-selected': {
                            background: `${colorPicker} !important`
                        }
                    }}>
                        <Select 
                            placeholder="Personas"
                            value={persona}
                            onChange={setPersonaSelection}
                            options={CHATGPT.PERSONAS}
                            onMenuOpen = {
                                () => {
                                    document.querySelector('#container_aiAssistant').classList.add('menu-opened')
                                }
                            }
                            onMenuClose = {
                                () => {
                                    document.querySelector('#container_aiAssistant').classList.remove('menu-opened')
                                }
                            }
                            getOptionLabel={e => (
                                <div className='cstmGetoption'>
                                    <span className='sl-name'>{e.label}</span>
                                    <img src={e.icon} className='slectCheck' /> 
                                </div>
                            )}
                            menuPlacement={'auto'}
                            className="customSelectcontainer"
                            classNamePrefix="customSelect__menu"
                        />
                    </div>
                    <div className='hl-slect' css={{
                        '.customSelect__menu__single-value .sl-name': {
                            color: `${colorPicker} !important`
                        },
                        '.customSelectcontainer .customSelect__menu__menu .customSelect__menu__option:hover': {
                            background: `${colorPicker} !important`
                        },
                        '.customSelectcontainer .customSelect__menu__menu .customSelect__menu__option.customSelect__menu__option--is-selected': {
                            background: `${colorPicker} !important`
                        }
                    }}>
                        <Select 
                            placeholder="Prompts"
                            value={null}
                            onChange={setPromptSelection}
                            options={CHATGPT.PROMPTS}
                            onMenuOpen = {
                                () => {
                                    document.querySelector('#container_aiAssistant').classList.add('menu-opened')
                                }
                            }
                            onMenuClose = {
                                () => {
                                    document.querySelector('#container_aiAssistant').classList.remove('menu-opened')
                                }
                            }
                            getOptionLabel={e => (
                                <div className='cstmGetoption'>
                                    <span className='sl-name chat-dd-option'>{e.label}</span>
                                    <img src={e.icon} className='slectCheck' /> 
                                </div>
                            )}
                            menuPlacement={'auto'}
                            className="customSelectcontainer promptsOptions"
                            classNamePrefix="customSelect__menu"
                        />   
                    </div>
                    <div className='sm-Send'>
                        {
                            prompt.length > 0 ? <button style={{background: colorPicker, color: theme == 'dark' ? '#000' : '#fff'}} onClick={send} className='btnSend'>{chatData.length > 0 ? 'Send' : 'Start'}</button> : <button onClick={send} className='btnSend'>{chatData.length > 0 ? 'Send' : 'Start'}</button>
                        }
                    </div>   
                </div>
            </div>
        </div>
    )
})

export default Chat