import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ArrowUp from '../../pages/Quotes/ArrowUp'
import { DragHandler } from '../../pages/Tasks/DragHandler'
import { clearChat } from '../../redux/slices/AiAssistant'
import Chat from '../ChatGPT/Chat'
import History from '../ChatGPT/History'
import { ChatIcon, CollapseIcon, FullscreenIcon, MaximizeIcon, MinimizeIcon, SettingsIcon } from '../LandingHeader/Feedback/icons/Icons'
import ChatTabs from '../ChatGPT/ChatTabs'

const AIAssistant = ({defaultMaximize = false}) => {
    const {theme, colorPicker} = useSelector(state => state.theme)
    const [collapse, setCollapse] = useState(false)
    const {chatData, id} = useSelector(state =>  state.aiAssistant);
    const [quoteCollapse, setQuoteCollapse] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chatInputRef = useRef('');

    const toggleMinMax = (e) => {
        e.preventDefault();

        if(defaultMaximize){
            navigate(process.env.REACT_APP_ROOT)
        }else{
            navigate('/ai-assistant')
        }
    }

    const toggle = (e) => {
        if(!e.target.classList.contains('new-ai-chat'))
            !defaultMaximize && setCollapse(!collapse)
    }

    const startNewChat = (e) => {
        e.preventDefault(); 
        dispatch(clearChat());
        chatInputRef.current.focus();
    }

    return (
        <div className='bar aiAssistant'>
            {
                quoteCollapse 
                ?
                <div className="dailyQuote">
                    <div className={`quoteHeader ${collapse ? 'head-option' : ''} `} style={{ background:theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000" : "" }} onClick={toggle}>
                        {!defaultMaximize && <DragHandler />}
                        <span className={`quotetitle ${theme == "dark" ? "quotesTitleDarkMode" : ""}`} >AI Assistant</span>
                        {chatData.length > 1 && <button style={{background : theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000" : "", color: theme== "dark" ? "#8C8C8C" :""}} onClick={startNewChat} className='new-ai-chat' color={theme== "dark" ? '#5E5E5E' :"#A0A0A0"}><ChatIcon color = {theme == 'dark' ? '#5E5E5E' : '#A0A0A0'} /> New Chat</button>}
                        <span onClick={toggleMinMax} className="min-max-btn">{!defaultMaximize ? <MaximizeIcon color = {theme == 'dark' ? '#5E5E5E' : '#A0A0A0'} /> : <MinimizeIcon color = {theme == 'dark' ? '#5E5E5E' : '#A0A0A0'} />}</span>
                        {!defaultMaximize && <span className={`header-arrow ${collapse ? 'active' : ''}`}  onClick={() => setCollapse(!collapse)}>
                        {theme == "dark" ?  <ArrowUp /> : <img src='../images/arrow-up-dn.svg' alt=''/>}
                        </span>}
                    </div>
                    <div className={defaultMaximize ? 'chatWrap' : ''} id="chatWrap">
                        {
                            defaultMaximize === true
                            &&
                            //  <History colorPicker={colorPicker} chatInputRef={chatInputRef} theme={theme}/>

                                <ChatTabs theme={theme} colorPicker={colorPicker} chatInputRef={chatInputRef} />
                        }
                        <div id="chat-gpt-widget" className="quoteContainer" style={{ display: collapse ? "none" : '' , background : theme == "dark" ? "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000" : "" }}>
                            <Chat ref={chatInputRef} defaultMaximize={defaultMaximize} />
                        </div>
                    </div>

                    <div className='onHoverShorts aiAssist'>
                        <div className='actOnhove'>
                            <div className='hovAct ht-collapse' onClick={() => setQuoteCollapse(false)}> <CollapseIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                            <div className='hovAct ht-scale' onClick={toggleMinMax}> <FullscreenIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                            <div className='hovAct ht-settings' onClick={() => navigate(`/settings#cards:ai`) }> <SettingsIcon color={theme == 'dark' ? 'gray' : 'black'} /> </div>
                        </div>
                    </div>
                </div> 
                : 
                <div className='barContent' onClick={() => setQuoteCollapse(true)}>
                    <div className='barContentHeading'>
                        <h4 className='barHead'>AI Assistant</h4>
                        <span className='headBrk'></span>
                    </div>
                </div>
            }
        </div>
    )
}

export default AIAssistant