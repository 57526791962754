/* eslint-disable */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {
    useState,
  } from "react";
  import "./style.css";
  import { useSelector } from "react-redux";
  import { useDispatch } from "react-redux";
  import {
    pauseTimer,
    resumeTimer,
    setTimerFullscreen,
    tmpStopTimer,
  } from "../../redux/slices/timer";
  import {
    focusTimerFormatV2,
    focusTimerFormatV3,
    hexToRgb,
    planTimeFormat,
  } from "../action/utilities";
  import { useNavigate } from "react-router-dom";
import { MaximizeIcon, PauseIcon, PlayIcon, StopIcon } from "../LandingHeader/Feedback/icons/Icons";
import moment from "moment";
import { decodeToken } from "react-jwt";
import { useSocket } from "../../SocketContext";

function RunningFocusTimerV2({ defaultMaximizTimer = false, focusMode = false, display = true }) {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const focusTimer = useSelector((state) => state.focusTimer);
  const {
    timer,
    currentTimer,
    additionalTimer,
    activeTimer,
    focusSessions
  } = focusTimer;
  const [time, setTime] = useState(currentTimer);
  const dispatch = useDispatch();
  const { theme, colorPicker } = useSelector((state) => state.theme);
  const socket = useSocket();

  const pauseAction = (e) => {
      e.preventDefault();
      dispatch(pauseTimer('FocusModePause'));
      triggerWebSocket({ action: "focusModePause" })
  }

  const resumeAction = (e) => {
      e.preventDefault();
      dispatch(resumeTimer('focusMode'));
      triggerWebSocket({ action: "resumeTimer" })
  }

  const stop = (e) => {
      e.preventDefault();
      dispatch(tmpStopTimer());
      triggerWebSocket({ action: "tmpStopTimer" })
      navigate('/focus-mode-completed');
  }

  const maximize = (e) => {
      e.preventDefault();
      dispatch(setTimerFullscreen(true));
      triggerWebSocket({ action: "fullscreen", fullscreenValue: true })
  }

  const triggerWebSocket = (data) => {
    // Trigger timer in wepapp and other clients
    if (socket !== null){
        const user = decodeToken(localStorage.getItem('token'));
        socket.emit('focusTimer', {room: user['outseta:accountUid'], data});
    }
  }

  return (
      <div
      className={`dailyQuote focus-timer running ${theme ?? ""}`}
      style={{
        boxShadow:
          theme == "dark" && defaultMaximizTimer
            ? `0px 4px 250px ${hexToRgb(colorPicker)}`
            : "none",
      }}
    >
      <div
        className={`quoteHeader ${collapse ? "head-option focusRunning" : ""} `}
        style={{
          background:
            theme == "dark"
              ? "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #000000"
              : activeTimer == "focus"
              ? "#F2F2F2"
              : "#F2F2F2",
          position: "relative",
          overflow: "hidden",
        }}
        onClick={() => setCollapse(!collapse)}
      >
        {collapse === true && (
          <span
            className="running-timer-collapse"
            dangerouslySetInnerHTML={{
              __html: planTimeFormat(
                time + additionalTimer,
                theme == "dark" ? "#fff" : "#000"
              ),
            }}
          ></span>
        )}
        
      </div>
      {collapse === false && (
        <div
          className={`quoteContainer focus-timer-widget focus-timer-container ${theme} focusMode`}
          css={{
              border: `2px solid ${theme == 'dark' ? '#000' : '#fff'}`,
              '&:before' : {
                  background: `linear-gradient(180deg, rgba(39, 203, 154, 0) 0%, ${colorPicker} 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)`,
                  width: `${100 - (currentTimer * 100) / timer}% !important`
              }
          }}
        >
          <div className="focused-items-heading">
                  Focus Session {focusSessions.filter(session => moment().format('L') === moment(session.startTime).format('L')).length}
              </div>
              <div id="focus-timer-bar" className={theme}>
                  <div className='timer-calc'>
                      <div className='timer-calc-display' style={{color: 'rgba(0, 0, 0, 0.8)'}}>
                          <span style={{color: colorPicker}} dangerouslySetInnerHTML={{ __html: `${focusTimerFormatV3(currentTimer, true)} left...` }} />
                          <span style={{color: theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}} dangerouslySetInnerHTML={{ __html: `/ ${focusTimerFormatV2(timer)}` }} />
                      </div>
                  </div>
                  <div className='timer-actions'>
                      <a href="#" onClick={stop} className='end-session tooltip-link' style={{color: theme == 'dark' ? '#fff' : '#000'}} css={{
                          '&:after': {
                              background: `${colorPicker} !important`
                          }
                      }}>
                          <StopIcon color={theme === 'dark' ? '#fff' : '#000'} />
                          <span className="tooltip-hover"></span>
                          <span 
                              className="block-on-hover"
                              css={{
                                  color: theme == 'dark' ? '#fff' : 'grey'
                              }}>
                              Stop
                          </span>
                      </a>
                      {
                          activeTimer === 'focusMode'
                          ?
                          <a href="#" className="tooltip-link" onClick={pauseAction}>
                              <PauseIcon color={theme === 'dark' ? '#fff' : '#000'} opacity={theme === 'dark' ? 0.5 : 1} />
                              <span className="tooltip-hover"></span>
                              <span 
                                  className="block-on-hover"
                                  css={{
                                      color: theme == 'dark' ? '#fff' : 'grey'
                                  }}>
                                  Pause
                              </span>
                          </a>
                          :
                          <a href="#" className="tooltip-link" onClick={resumeAction}>
                              <PlayIcon fill={colorPicker} color={colorPicker} />
                              <span className="tooltip-hover"></span>
                              <span 
                                  className="block-on-hover"
                                  css={{
                                      color: theme == 'dark' ? '#fff' : 'grey'
                                  }}>
                                  Resume
                              </span>
                          </a>
                      }
                      <a href="#" className="tooltip-link" onClick={maximize}>
                          <MaximizeIcon color={theme === 'dark' ? '#fff' : '#000'} />
                          <span className="tooltip-hover"></span>
                          <span 
                              className="block-on-hover"
                              css={{
                                  left: '-130% !important',
                                  color: theme == 'dark' ? '#fff' : 'grey'
                              }}>
                              Maximise
                          </span>
                      </a>
                  </div>
              </div>
        </div>
      )}
    </div>
  );
}

export default RunningFocusTimerV2;