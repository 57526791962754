/** @jsxRuntime classic */
/** @jsx jsx */
import { useRef, useContext } from "react";
import { useDay } from "@datepicker-react/hooks";
import { jsx } from "@emotion/core";
import DatepickerContext from "./datepickerContext";
import getColor from "../utils/getColor";
import { useSelector } from "react-redux";

function Day({ dayLabel, date }) {
  const { colorPicker } = useSelector(state => state.theme)
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate
  );

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      css={{
        padding: "8px",
        border: 0,
        height: '40px',
        width: '40px',
        borderRadius: '40pc',
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        color: getColorFn({
          selectedFirstOrLastColor: "#000",
          normalColor: "#fff",
          selectedColor: "#000",
          rangeHoverColor: "#FFFFFF",
          disabledColor: "#808285"
        }),
        background: getColorFn({
          selectedFirstOrLastColor: colorPicker,
          normalColor: "#000",
          selectedColor: "#27CB9A",
          rangeHoverColor: "#71c9ed",
          disabledColor: "#FFFFFF"
        }),
        '&:hover':{
          background: colorPicker,
          color: "#000"
        }
      }}
    >
      {dayLabel}
    </button>
  );
}

export default Day;
