import axios from "axios";
import { BASE_URL, OUTSETA_FREE_TRIAL_PLAN_ID } from "../common/constants";
import moment from "moment";
let deleteUndoSource = axios.CancelToken.source();
let completeUndoSource = axios.CancelToken.source();

export const createTask = (props) => {
  return axios({
    method: 'POST',
    url: '/task',
    data: props
  })
}

export const fetchTaskList = (props) => {
  return axios({
    method: 'GET',
    url: '/tasks',
    params: props
  })
}

export const fetchInboxTaskList = (props) => {
  return axios({
    method: 'GET',
    url: '/inbox-tasks',
    params: props
  })
}

export const updateTaskList = (props) => {
  return axios({
    method: 'PATCH',
    url: '/task',
    data: props
  })
}

export const deleteTask = (props) => {
  props.undo == '1' && deleteUndoSource.cancel("Operation is cancelled");

  deleteUndoSource = axios.CancelToken.source()

  return axios({
    method: 'DELETE',
    url: '/task',
    cancelToken: deleteUndoSource.token,
    data: props
  })
}

export const completedTask = (props) => {
  props.status == 0 && completeUndoSource.cancel("Operation is cancelled");

  completeUndoSource = axios.CancelToken.source()

  return axios({
    method: 'PATCH',
    url: '/task-status',
    cancelToken: completeUndoSource.token,
    data: props
  })
}

export const fectchQuoteToday = (props) => {
  return axios({
    method: 'GET',
    url: '/quote-today',
    params: props
  })
}

export const fectchJournalToday = (props) => {
  return axios({
    method: 'GET',
    url: '/journal-today',
    params: props
  })
}

export const updateJournalToday = (props) => {
  return axios({
    method: 'POST',
    url: '/journal',
    data: props
  })
}

// total count
export const totalTaskCount = (props) => {
  return axios({
    method: 'GET',
    url: '/tasks-count',
    params: props
  })
}

// temperature
export const temperatureToday = (props) => {
  return axios({
    method: 'GET',
    url: '/temperature-today',
    params: props
  })
}

// signup
export const signUp = (props) => {
  return axios({
    method: 'POST',
    url: '/signup',
    data: props
  })
}

// login

export const login = (props) => {
  return axios({
    method: 'POST',
    url: '/login',
    data: props
  })
}

// email exist
export const emailExist = (props) => {
  return axios({
    method: 'POST',
    url: '/email-exist',
    data: props
  })
}

// sorted list
export const taskPosition = (props) => {
  return axios({
    method: 'PATCH',
    url: '/task-position',
    data: props
  })
}

// /user-profile

export const updateUserProfile = (props) => {
  return axios({
    method: 'PATCH',
    url: '/user-profile',
    data: props
  })
}

// fetch user profile
export const fetchUserProfile = (props = {}) => {
  return axios({
    method: 'GET',
    url: '/user-profile',
    params: {...props, todayDate: new Date(moment().startOf('date')).toISOString()}
  })
}

// /user/forgot-password
export const forgotPassword = (props) => {
  return axios({
    method: 'POST',
    url: '/user/forgot-password',
    data: props
  })
}

// /user/update-password
export const verifyCode = (props) => {
  return axios({
    method: 'POST',
    url: '/user/verify-code',
    data: props
  })
}

// /user-account
export const deleteAccount = (props) => {
  return axios({
    method: 'DELETE',
    url: '/user-account',
    data: props
  })
}

// /user/update-password
export const updatePassword = (props) => {
  return axios({
    method: 'POST',
    url: '/user/update-password',
    data: props
  })
}


// uploadimage
export const uploadImage = (props) => {
  return axios({
    method: 'POST',
    url: '/attachment/upload',
    data: props
  })
}

// update setting

export const setSetting = (props) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/settings',
      data: props
    }).then(response => {
      if(window?.chrome?.storage){
        window?.chrome.runtime.sendMessage({ action: 'getColor'});
      }
      console.log('HEREREcolor')
      resolve('done');
    }).catch(e => {
      reject(e);
    })
  })
}

// get general data
export const fetchSetting = (props) => {
  return axios({
    method: 'GET',
    url: '/settings',
    params: props
  })
}

// remove image
export const deleteImage = (props) => {
  return axios({
    method: 'DELETE',
    url: '/attachment/upload',
    data: props
  })
}

// plans
export const fetchPlansDetails = (props) => {
  return axios({
    method: 'GET',
    url: '/plans',
    params: props
  })
}

// /plan-subscribe

export const fetchPlanSubscribeDetails = (props) => {
  return axios({
    method: 'GET',
    url: '/plan-subscribe',
    params: props
  })
}

export const subscriptionDetails = (props) => {
  return new Promise((resolve, reject) => {
    fetchPlanSubscribeDetails().then(response => {
      let data = {isFreeTrial: false};
      try{
        const {Plan, Account, RenewalDate, StartDate} = response.data.responseData.items?.[response.data.responseData.items?.length - 1] ?? {};
        if(Plan){
          data = {...data, plan: {name: Plan?.Name, uid: Plan?.Uid}, accountStage: Account?.AccountStage, accountStageLabel: Account?.AccountStageLabel, renewalDate: RenewalDate, startDate: StartDate}
          if(Plan?.Uid === OUTSETA_FREE_TRIAL_PLAN_ID){
            data = {...data, isFreeTrial: true}
          }
        }
      }catch(e){
        console.log(e)
      }
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

// /plan-subscribe-preview

export const fetchPlanSubscribePreview = (props) => {
  return axios({
    method: 'GET',
    url: '/plan-subscribe-preview',
    params: props
  })
}

// /plan-subscribe
export const setPlanSubscribe = (props) => {
  return axios({
    method: "POST",
    url: '/plan-subscribe',
    data: props
  })
}

// /payment-cards
export const cardDetails = (props) => {
  return axios({
    method: 'GET',
    url: '/payment-cards',
    params: props
  })
}

// /plan-subscribe-cancel
export const cancelCardSubscribe = (props) => {
  return axios({
    method: 'POST',
    url: '/plan-subscribe-cancel',
    data: props
  })
}

// /payment-plan-subscribe-status

export const planSubscribeStatus = (props) => {
  return axios({
    method: 'GET',
    url: '/payment-plan-subscribe-status',
    params: props
  })
}

export const toggleMostImportantFlag = (props) => {
  return axios({
    method: 'PATCH',
    url: '/task/toggleMostImportantFlag',
    data: props
  })
}

export const exchangeTodoistAccessToken = async (code, state) => {
  const url = `${BASE_URL}task/todoist/getAccessToken?code=${code}&utcoffset=${new Date().getTimezoneOffset()}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': state
    }
  });

  return response.json();

  // return axios({
  //   method: 'GET',
  //   url: '/task/todoist/getAccessToken',
  //   headers: {'Authorization': state},
  //   params: {code}
  // })
}

export const getBlockedSites = (props) => {
  /* eslint-disable no-undef */

  if (chrome.storage !== undefined) {
    chrome.storage.local.get('sites', function (result) {
      return result.sites;
    });
  }

  return fetchBlockedSitesData();
}

export const fetchBlockedSitesData = (props) => {
  return axios({
    method: 'GET',
    url: '/blockedSites',
    params: props
  }).then((response) => {
    var responseData = {};

    if (response.data.responseData != null) {
      responseData = {
        blockedSites: typeof response.data.responseData.blockedSites == 'string' ? JSON.parse(response.data.responseData.blockedSites) : response.data.responseData.blockedSites,
        dailyUsage: typeof response.data.responseData.dailyUsage == 'string' ? JSON.parse(response.data.responseData.dailyUsage) : response.data.responseData.dailyUsage,
        id: response.data.responseData.id,
        intervals: typeof response.data.responseData.intervals == 'string' ? JSON.parse(response.data.responseData.intervals) : response.data.responseData.intervals,
        usageDays: typeof response.data.responseData.usageDays == 'string' ? JSON.parse(response.data.responseData.usageDays) : response.data.responseData.usageDays,
        status: response.data.responseData.status,
        visitCount: response.data.responseData.visitCount,
        blockCount: response.data.responseData.blockCount
      };
    }
    return responseData;
  })
}

export const postBlockedSites = async (props, id, isDeleted) => {
  /* eslint-disable no-undef */
  if (chrome.storage !== undefined) {
    var newSites = props;
    chrome.storage.local.get('sites').then((result) => {
      var storedSites = result.sites;
      if (storedSites !== undefined && storedSites.hasOwnProperty('usage')) {
        newSites['usage'] = storedSites.usage;
      } else {
        newSites['usage'] = 0;
      }
      chrome.storage.local.set({ sites: newSites }).then(() => {
        console.log("set")
      });
    })
 
    if (isDeleted) {
      chrome.storage.local.remove(['continue'], function () {
        console.log('Key successfully removed');
      });
    }
  }

  if (props.hasOwnProperty('usage')) {
    delete props.usage;
  }

  props.id = id;
  return axios({
    method: 'POST',
    url: '/blockedSites',
    data: props
  }).then((response) => {
    var responseData = {};
    if (response.data.responseData != null) {

      responseData = {
        blockedSites: typeof response.data.responseData.blockedSites == 'string' ? JSON.parse(response.data.responseData.blockedSites) : response.data.responseData.blockedSites,
        dailyUsage: typeof response.data.responseData.dailyUsage == 'string' ? JSON.parse(response.data.responseData.dailyUsage) : response.data.responseData.dailyUsage,
        id: response.data.responseData.id,
        intervals: typeof response.data.responseData.intervals == 'string' ? JSON.parse(response.data.responseData.intervals) : response.data.responseData.intervals,
        usageDays: typeof response.data.responseData.usageDays == 'string' ? JSON.parse(response.data.responseData.usageDays) : response.data.responseData.usageDays,
        status: response.data.responseData.status,
        visitCount: response.data.responseData.visitCount,

      };

    }
    return responseData;
  })
}

