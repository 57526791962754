/** @jsxRuntime classic */
/** @jsx jsx */
import moment from "moment";
import { jsx } from "@emotion/core";
import { RRule, rrulestr } from "rrule";
import momentTimezone from 'moment-timezone';
export const dateToFromNowDaily = ( myDate , color , theme) => {
  return myDate == moment().format('MM/DD/YYYY') 
                    ? 
                    moment( myDate ).format(`[<span class="ac-today" style="color: ${theme == "dark" ? color: color}">Today</span> <span class="ac-date" style="color: ${theme == "dark" ? "white" :""}">]D MMMM[</span> <span classe="ac-day" style="color: ${theme == "dark" ? "white" :""}">]dddd[</span>]`) 
                    : 
                    moment( myDate ).format(`[<span class="ac-date" style="color: ${theme == "dark" ? "white" :""}">]D MMMM[</span> <span class="ac-day" style="color: ${theme == "dark" ? "white" :""}">]dddd[</span>]`);
}

export const focusTimerFormatStatic = ( time, activeColor = "#fff" ) => {
  return `<span style="color: ${time[0] != '' ? activeColor : ''}" class="ft-time ${time[0] != '' ? 'active' : ''}">${time[0] != '' ? time[0] : '00'} : </span><span style="color: ${time[1] != '' ? activeColor : ''}" class="ft-time  ${time[1] != '' ? 'active' : ''}">${time[1] != '' ? time[1] : '00'} : </span><span  style="color: ${time[2] != '' ? activeColor : ''}" class="ft-time  ${time[2] != '' ? 'active' : ''}">${time[2] != '' ? time[2] : '00'}</span>`;
}

export const focusTimerFormat = ( time, activeColor = "#fff", h = 'HH', m = 'mm', s = 'ss' ) => {
    return moment.utc(time * 1000).format(`[<span style="color: ${time >= 3600 ? activeColor : ''}" class="ft-time ${time >= 3600 ? 'active' : ''}">]${h}[ : </span><span style="color: ${time >= 60 ? activeColor : ''}" class="ft-time  ${time >= 60 ? 'active' : ''}">]${m}[ : </span><span  style="color: ${time > 0 ? activeColor : ''}" class="ft-time  ${time > 0 ? 'active' : ''}">]${s}[</span>]`)
}

export const focusTimerFormatBreak = ( time, activeColor = "#fff" ) => {
    return moment.utc(time * 1000).format(`[<span style="color: ${time >= 3600 ? activeColor : ''}" class="ft-time ${time >= 3600 ? 'active' : ''}">]HH[ : </span><span style="color: ${time >= 60 ? activeColor : ''}" class="ft-time  ${time >= 60 ? 'active' : ''}">]mm[ : </span><span  style="color: ${time > 0 ? activeColor : ''}" class="ft-time  ${time > 0 ? 'active' : ''}">]ss[</span>]`)
}

export const focusTimerFormatV2 = ( time ) => {
    return moment.utc(time * 1000).format(`[<span class="ft-time time]HH[">]H [hr][ <span class="${time % 3600 === 0 ? 'time00' : ''}">:</span> </span><span class="ft-time time]mm[">]m [min][ <span class="${(time % 3600) % 60 === 0 ? 'time00' : ''}">:</span> </span><span class="ft-time time]ss[">]s [sec][</span>]`)
}

export const focusTimerFormatV3 = ( time, showSec = false ) => {
  return time < 60 && showSec ? moment.utc(time * 1000).format(`[<span class="ft-time ${showSec ? 'timeshow' : ''} time]ss[">]s [sec][</span>]`) : moment.utc(time * 1000).format(`[<span class="ft-time time]HH[">]H [${time / 3600 >= 2 ? 'hrs' : 'hr'}][ </span><span class="ft-time min time]mm[">]m [${time / 60 >= 2 ? 'mins' : 'min'}][</span>]`)
}

export const focusTimerFormatV4 = ( time ) => {
  return moment.utc(time * 1000).format(`[<span class="ft-time time]HH[">]H [${time / 3600 >= 2 ? 'hours' : 'hour'}][ </span><span class="ft-time min time]mm[">]m [${time / 60 >= 2 ? 'minutes' : 'minute'}][</span>]`)
}

export const focusTimerFormatV5 = ( time, showSec = false ) => {
  return time < 60 && showSec ? moment.utc(time * 1000).format(`[<span class="ft-time ${showSec ? 'timeshow' : ''} time]ss[">]s [seconds][</span>]`) : moment.utc(time * 1000).format(`[<span class="ft-time time]HH[">]H [${time / 3600 >= 2 ? 'hours' : 'hour'}][ </span><span class="ft-time min time]mm[">]m [${time / 60 >= 2 ? 'minutes' : 'minutes'}][</span>]`)
}

export const format = (date, format) => {
  return moment(date).format(format);
}

export const calendarFormat = (date) => {
  return isValidDate(date) ? moment(date).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
      nextWeek: 'D MMM YYYY',
      lastWeek: 'D MMM YYYY',
      sameElse: 'D MMM YYYY'
  }) : 'No Date';
}

export const calendarFormat2 = (date) => {
  return isValidDate(date) ? moment(date).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
      nextWeek: 'dddd',
      lastWeek: 'dddd',
      sameElse: 'dddd'
  }) : 'No Date';
}

export const dateWithOverdueColor = (date, dateFormat = 'DD MMM YYYY') => {
  const taskDate = moment(date);
  const today = moment();
  const diff = taskDate.diff(today, 'days');
  
  if (diff === 0) return <span>Today</span>;
  if (diff === 1) return <span>Tomorrow</span>;
  if (diff === -1) return <span className="overdueTaskColor">Yesterday</span>;
  
  return <span className={diff < 0 ? 'overdueTaskColor' : ''}>
    {taskDate.format(dateFormat)}
  </span>;
}

export const planTimeFormat = ( time ) => {
  return moment.utc(time * 1000).format(time / 3600 >= 1 ? 'HH:mm:ss' : 'mm:ss')
}

export function authentication() {
  if (typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null)
    return true;
  else
    return false;
};

export function loadScript(id, src, attrs = {}, callback = null){  
  const existingScript = document.getElementById(id);  
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;    
    script.id = id;
    Object.keys(attrs).map(attr => {
      script.setAttribute(attr, attrs[attr]);
    });
    document.body.appendChild(script);    
    script.onload = () => { 
      if (callback) 
        callback();
    };
  }  
  if (existingScript && callback) 
    callback();
}

export function hexToRgb(colorPicker) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorPicker);
  return result ? `rgb(${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)} / 10%)` : null;
}

export function setFocusOnTimer(){
  document.querySelector('.timer-custom-time').focus();
}

export const dateToTimeLine = ( myDate, dateFormat = 'MM/DD/YY', timeFormat = 'h:mm A' ) => {
  // ensure the date is displayed with today and yesterday
  return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastDay:  `[Yesterday] ${timeFormat}`,
      sameDay:  timeFormat,
      // when the date is further away, use from-now functionality             
      sameElse: function () {
          return dateFormat;
      }
  });
}

export const parseMentionInput = (text) => {
  const projectRegex = /\@\[([^\]]+)\]/g;
  const tagRegex = /\#\[([^\]]+)\]/g;
  const projectMatches = [...text.matchAll(projectRegex)];
  const tagMatches = [...text.matchAll(tagRegex)];
  const projects = projectMatches.map((match) => match[1]);
  const tags = tagMatches.map((match) => match[1]);
  const title = text.replace(projectRegex, "").replace(tagRegex, "");
  const result = {
    title,
    project: projects.at(0),
    tags,
  };

  return result;
}

export const verifyRepeatedTask = (task, date, condition) => {
  if(task?.repeat?.rule){
    return getRepeatedTask(task, date)?.length > 0
  }

  return condition;
}

export const getRepeatedTask = (task, date) => {
  const taskDate = date ?? new Date();
  const DSTOffset = 2;
 
  try{
    return rrulestr(task.repeat.rule?.replace(/\;{0,}$/, '')).between(momentTimezone(taskDate).tz(moment.tz.guess()).startOf('day').add(DSTOffset, 'hours').toDate(), momentTimezone(taskDate).tz(moment.tz.guess()).endOf('day').add(DSTOffset, 'hours').toDate(), true)
  }catch(e){
    return false;
  }
}

export const isValidDate = (dateString) => {
  const date = new Date(dateString ?? '');
  return date instanceof Date && !isNaN(date);
}

export const generateArcPath = (cx, cy, radius, startAngle, endAngle) => {
  // Convert angles to radians
  const startAngleRad = (startAngle * Math.PI) / 180;
  const endAngleRad = (endAngle * Math.PI) / 180;

  // Calculate start and end points
  const startX = cx + radius * Math.cos(startAngleRad);
  const startY = cy + radius * Math.sin(startAngleRad);
  const endX = cx + radius * Math.cos(endAngleRad);
  const endY = cy + radius * Math.sin(endAngleRad);

  // Determine if the arc is greater than 180 degrees
  const largeArcFlag = (endAngle - startAngle) % 360 > 180 ? "1" : "0";;

  // Create the SVG path using the 'A' (arc) command
  const pathData = [
      `M ${startX} ${startY}`,       // Move to start point
      `A ${radius} ${radius}`,       // Arc command
      `0`,                           // No rotation of the arc
      largeArcFlag,                  // Large arc flag
      `1`,                           // Sweep flag (1 for clockwise)
      `${endX} ${endY}`              // Endpoint of the arc
  ].join(" ");

  return pathData;
}