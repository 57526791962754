/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from "@emotion/core";
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setChat, clearChat } from '../../redux/slices/AiAssistant';
import { deleteConversation, fetchConversations, undoConversation } from '../action/chatGPT';
import { dateToTimeLine } from '../action/utilities';
import { ChatIcon, Edit, EditIcon, Trash, TrashIcon } from '../LandingHeader/Feedback/icons/Icons';
import DeletedChat from './DeletedChat';
import moment from 'moment';
import EditChatTitle from './EditChatTitle';
import Tooltip from '../common/Tooltip';
import SkeletonLoader from '../SkeletonLoading/AiChatHistory';

const History = ({theme, colorPicker, chatInputRef}) => {
    const [conversations, setConversations] = useState([]);
    const [editChatTitleId, setEditChatTitleId] = useState(null);
    const [loading, setLoading] = useState(false);
    const { settings } = useSelector(state => state.settings);
    const {chatData, changed, id} = useSelector(state =>  state.aiAssistant);
    const dateFormat = settings?.dateFormat ? settings?.dateFormat : "DD/MM/YY";
    const timeFormat = settings?.timeFormat ? settings?.timeFormat : "LT"
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        fetchConversations().then(result => {
            setLoading(false);
            setConversations(result.data.responseData);
        });
    }, [])

    useEffect(() => {
        changed === false && fetchConversations().then(result => setConversations(result.data.responseData));
    }, [chatData, changed])

    const chatContent = (c, data) => {
        return c?.title ?? (data?.[data.length - 1].content ?? '');
    }

    const chatPersona = (data) => {
        return data?.[data.length - 1].sender ?? 'Default';
    }

    const changeConversation = (e, c) => {
        e.preventDefault();
        if(!e.target.closest('.delete-chat-btn') && !e.target.closest('.trashMessage') && c.deletedAt === null && c.id != editChatTitleId){
            dispatch(setChat({data: JSON.parse(c.conversation), title: c.title, id: c.id, changed: true}));
            chatInputRef.current.focus();
        }
    }

    const deleteChat = (e, chatId) => {
        e.preventDefault();
        deleteConversation(chatId).then(result => {
            const index = _.findIndex(conversations, ["id", chatId]);
            if(index > -1){
                setConversations(conversations => {
                    conversations[index].deletedAt = new Date();
                    return [...conversations];
                });
                chatId == id && dispatch(clearChat({changed: true}));
            }
        });
    }

    const undoChat = (e, id) => {
        e.preventDefault();
        undoConversation(id).then(result => {
            const index = _.findIndex(conversations, ["id", id]);
            if(index > -1){
                setConversations(conversations => {
                    conversations[index].deletedAt = null;
                    return [...conversations];
                });
            }
       });
    }

    const disappearChat = (id) => {
        setConversations(conversations => {
            return [...conversations].filter(c => c.id != id);
        });
    }

    const editChatTitle = (e, id) => {
        e.preventDefault();

        setEditChatTitleId(id);
    }

    const hideEditChatTitle = (c) => {
        setEditChatTitleId(null);
        dispatch(setChat({data: JSON.parse(c.conversation), title: c.title, id: c.id}));
    } 

    return (
        <>
            <div className={`histoery-timeline`}>
                <ul className='timeline-ul'>
                    {
                        loading
                        ?
                        <SkeletonLoader loop={4} />
                        :
                        conversations.map(c => {
                            let content = JSON.parse(c.conversation);
                            return (
                                <li key={c.id} className={`timeline-li ${c.deletedAt === null ? '' : 'deleted-task'} ${c.id == id ? 'active' : ''} ${editChatTitleId !== null && c.id == editChatTitleId ? 'editing' : ''}`} onClick={e => changeConversation(e, c)} style={{backgroundColor: (c.id == id || c.deletedAt !== null || (editChatTitleId !== null && c.id == editChatTitleId) ? colorPicker : 'transparent')}}>
                                    {
                                        c.deletedAt !== null
                                        ?
                                        <DeletedChat theme={theme} trigger={disappearChat} undoChat={undoChat} c={c} />
                                        :
                                        (
                                            editChatTitleId !== null && c.id == editChatTitleId
                                            ?
                                            <EditChatTitle theme={theme} trigger={hideEditChatTitle} c={c} />
                                            :
                                            <>
                                                <div className='time-slot'>
                                                    <span className='time'>{moment(c.updatedAt).format(timeFormat)}</span> 
                                                    <span className='date'>{moment(c.updatedAt).format(dateFormat)}</span>  
                                                </div>
                                                <div className='histry-icon'>
                                                <div className='his-icon' style={{backgroundColor: `${colorPicker} !important`}}><ChatIcon /></div> 
                                                </div>
                                                <div className='histry-title'>
                                                    <span className='histry-title-name'>{chatContent(c, content)}</span>
                                                </div>
                                                <div className='histry-tag'>
                                                    <div className='hs-tag-name'>
                                                        <img src='/images/personas.svg' className='tagCoimg' />
                                                        <span className='tag-name'>{`${chatPersona(content)}`}</span>
                                                    </div>
                                                    <span onClick={e => deleteChat(e, c.id)} className='trashMessage' css={{
                                                        '&:hover svg rect': {
                                                        fill: colorPicker
                                                        },
                                                        '&:hover svg path': {
                                                            stroke: '#fff'
                                                        }
                                                    }}>
                                                        
                                                        <Tooltip text="Delete" className='tooltip-mt0 delete-tooltip'>
                                                            <TrashIcon fill={theme == 'dark' ? 'gray' : 'white'}  />
                                                        </Tooltip>
                                                    </span>
                                                    <span onClick={e => editChatTitle(e, c.id)} className='trashMessage editMessage' css={{
                                                        '&:hover svg rect': {
                                                        fill: colorPicker
                                                        },
                                                        '&:hover svg path': {
                                                            stroke: '#fff'
                                                        }
                                                    }}>
                                                        
                                                        <Tooltip text="Edit" className='tooltip-mt0 delete-tooltip'>
                                                            <EditIcon fill={theme == 'dark' ? 'gray' : 'white'}  />
                                                        </Tooltip>
                                                    </span>
                                                    
                                                </div>
                                            </>
                                        )
                                        
                                    }
                                    
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default History