/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { setActiveTask, setHeaderName } from '../../redux/slices/TaskSlice'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from "@emotion/core";
import TodayMenu from './TodayMenu'
import TaskMenuIcon from './TaskMenuIcon'
import SettingMenuIcon from './SettingMenuIcon'
import TimerMenuIcon from './TimerMenuIcon'
import JournalMenuIcon from './JournalMenuIcon'
import HabbitMenuIcon from './HabbitMenuIcon'
import { setAddQuickLinkClick } from '../../redux/slices/SettingSclice'
import PlanMenuIcon from './PlanMenuIcon'
import { AuthContext } from '../../AuthProvider'
// import { setAddQuickLinkClick } from '../../redux/slices/CardSlice'


function SideBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [disableMenu, setDisableMenu] = useState(false)
  const { colorPicker, theme } = useSelector(state => state.theme)
  const [activeStyleHome, setActiveStyleHome] = useState(false)
  const [activeTasks, setActiveTask] = useState(false)
  const [activeSetting, setActiveSetting] = useState(false)
  const [activeTimerSetting, setActiveTimerSetting] = useState(false)
  const [activeAdminSetting, setActiveAdminSetting] = useState(false)
  const [activePlanSetting, setActivePlanSetting] = useState(false)
  const [isMouseEnter,setIsMouseEnter]=useState(false)
  const [isMouseEnterTasks,setIsMouseEnterTasks]=useState(false)
  const [isMouseEnterSettingTask,setIsMouseEnterSettingTask]=useState(false)
  const [isMouseEnterSettingTimer, setIsMouseEnterSettingTimer]=useState(false)
  const [isMouseEnterSettingAdmin, setIsMouseEnterSettingAdmin]=useState(false)
  const [isMouseEnterSettingPlan, setIsMouseEnterSettingPlan]=useState(false)
  const { subscription, userProfile } = useContext(AuthContext);

  useEffect(() => {
    subscription?.isFreeTrial && subscription?.accountStage > 3 && setDisableMenu(true)
  }, [subscription])

  return (
    <div className="left-setting-tab" id="setting-tab">
      <ul className="ul-setting" css={{
          'a.li-links': {
            pointerEvents: disableMenu ? 'none' : 'auto',
          },
          'a.subscription': {
            pointerEvents: 'auto'
          }
      }}>
        <li className="li-setting" 
        css={{
          '&:hover .block-on-hover':{
            color:(activeStyleHome && isMouseEnter) ? "black !important" : theme == "dark" ? "#4C4C4C" :"",
            background:theme == "dark" && "black",
          }
        }}
        onMouseEnter={()=>{setIsMouseEnter(true)}}
        onMouseLeave={()=>{setIsMouseEnter(false)}}
        onClick={() => { 
          dispatch(setActiveTask(null)); 
          dispatch(setAddQuickLinkClick(false)); 
          dispatch(setHeaderName('Upcoming')) }}>
          <NavLink to={process.env.REACT_APP_ROOT || '/index.html'} end className={({ isActive }) => isActive ? 'li-links active' : 'li-links'} style={({ isActive }) =>
            isActive ? setActiveStyleHome(true) : setActiveStyleHome(false)
          } >
            <span className={`menu-block ${(theme == "dark" && activeStyleHome == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activeStyleHome ? colorPicker : '' }}>
              {
                theme == "dark" ? <TodayMenu isMouseEnter={isMouseEnter} activeStyleHome={activeStyleHome} /> : <img src="/images/today-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover" style={{ backgroundColor: activeStyleHome ? colorPicker : '' }} 
            css={{
             color: isMouseEnter ? "black" :"white"
            }}
            >Today</span>
          </NavLink>
        </li>

        <li className="li-setting"
        css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onMouseEnter={()=>{setIsMouseEnterTasks(true)}}
        onMouseLeave={()=>{setIsMouseEnterTasks(false)}}
        onClick={() => {          
          dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)); dispatch(setHeaderName('Upcoming')) }}>
          <NavLink to="/tasks" className={({ isActive }) => isActive ? 'li-links active' : 'li-links'} style={({ isActive }) =>
            isActive ? setActiveTask(true) : setActiveTask(false)
          } >
            <span className={`menu-block ${(theme == "dark" && activeTasks == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activeTasks ? colorPicker : '' }}>
              {
                theme == "dark" ? <TaskMenuIcon isMouseEnterTasks={isMouseEnterTasks} activeTasks={activeTasks} /> : <img src="/images/task-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover"
            css={{
              color: (isMouseEnterTasks && activeTasks) ? "black !important" :"white "
             }}
            style={{ backgroundColor: activeTasks ? colorPicker : '' }} >Tasks</span>
          </NavLink>
        </li>

        <li className="li-setting" 
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onClick={() => { dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
          <a to="/tracker" className="li-links" >
            <span className={`menu-block ${(theme == "dark") ? "sideBarDarkMode" : ""}`} >
              {
                theme == "dark" ? <HabbitMenuIcon /> : <img src="/images/habbit-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover">Habit Tracker (Coming Soon)</span>
          </a>
        </li>

        <li className="li-setting"
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onClick={() => { dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
          <a to="/journal" className="li-links" >
            <span className={`menu-block ${(theme == "dark") ? "sideBarDarkMode" : ""}`} >

              {
                theme == "dark" ? <JournalMenuIcon /> : <img src="/images/journal-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover">Journal (Coming Soon)</span>
          </a>
        </li>

        <li className="li-setting" 
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onMouseEnter={()=>{setIsMouseEnterSettingTimer(true)}}
        onMouseLeave={()=>{setIsMouseEnterSettingTimer(false)}}
        onClick={() => {dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
          <NavLink to='/focus-mode-settings' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'} style={({ isActive }) =>
            isActive ? setActiveTimerSetting(true) : setActiveTimerSetting(false)
          } >
            <span className={`menu-block ${(theme == "dark" && activeTimerSetting == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activeTimerSetting ? colorPicker : '' }}>
              {
                theme == "dark" ? <TimerMenuIcon isMouseEnterSettingTimer={isMouseEnterSettingTimer} activeTimerSetting={activeTimerSetting} /> : <img src="/images/timer-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover"
             css={{
              color: (isMouseEnterSettingTimer && activeTimerSetting) ? "black !important" :"white "
             }}
            style={{ backgroundColor: activeTimerSetting ? colorPicker : '' }}>Focus Timer Settings</span>
          </NavLink>
        </li>
        {
          (userProfile?.email === "vikasgarg.wd@gmail.com" || userProfile?.email === "sam@lifetab.co") &&
          <li className="li-setting" 
            css={{
              '&:hover .block-on-hover':{
                background:theme == "dark" && "black",
                color: theme == "dark" && "#4C4C4C",
              }
            }}
            onMouseEnter={()=>{setIsMouseEnterSettingAdmin(true)}}
            onMouseLeave={()=>{setIsMouseEnterSettingAdmin(false)}}
            onClick={() => {dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
              <NavLink to='/admin' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'} style={({ isActive }) =>
                isActive ? setActiveAdminSetting(true) : setActiveAdminSetting(false)
              } >
                <span className={`menu-block ${(theme == "dark" && activeAdminSetting == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activeAdminSetting ? colorPicker : '' }}>
                  {
                    theme == "dark" ? <TimerMenuIcon isMouseEnterSettingTimer={isMouseEnterSettingAdmin} activeTimerSetting={activeAdminSetting} /> : <img src="/images/timer-menu.svg" alt='' />
                  }
                </span>
                <span className="block-on-hover"
                css={{
                  color: (isMouseEnterSettingAdmin && activeAdminSetting) ? "black !important" :"white "
                }}
                style={{ backgroundColor: activeAdminSetting ? colorPicker : '' }}>Admin</span>
              </NavLink>
            </li>
        }
        
        {
        subscription?.isFreeTrial 
        &&
        <li className="li-setting" 
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onMouseEnter={()=>{setIsMouseEnterSettingPlan(true)}}
        onMouseLeave={()=>{setIsMouseEnterSettingPlan(false)}}
        onClick={() => {dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
          <NavLink to='/subscription-plan' className={({ isActive }) => isActive ? 'li-links active subscription' : 'li-links subscription'} style={({ isActive }) =>
            isActive ? setActivePlanSetting(true) : setActivePlanSetting(false)
          } >
            <span className={`menu-block ${(theme == "dark" && activePlanSetting == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activePlanSetting ? colorPicker : '' }}>
              {
                theme == "dark" ? <PlanMenuIcon isMouseEnterSetting={isMouseEnterSettingPlan} activeSetting={activePlanSetting} /> : <img src="/images/arrow-up-dot.svg" alt='' />
              }
            </span>
            <span className="block-on-hover"
             css={{
              color: (setIsMouseEnterSettingPlan && activePlanSetting) ? "black !important" :"white "
             }}
            style={{ backgroundColor: activePlanSetting ? colorPicker : '' }}>Plan</span>
          </NavLink>
        </li>
        }

        {/* <li className="li-setting" 
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onClick={() => { dispatch(setAddQuickLinkClick(false)) }}>
          <a to="/timer" className="li-links" >
            <span className={`menu-block ${(theme == "dark") ? "sideBarDarkMode" : ""}`} >
              {
                theme == "dark" ? <TimerMenuIcon /> : <img src="/images/timer-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover">Timer (Coming Soon)</span>
          </a>
        </li> */}

        <li className="li-setting" 
         css={{
          '&:hover .block-on-hover':{
            background:theme == "dark" && "black",
            color: theme == "dark" && "#4C4C4C",
          }
        }}
        onMouseEnter={()=>{setIsMouseEnterSettingTask(true)}}
        onMouseLeave={()=>{setIsMouseEnterSettingTask(false)}}
        onClick={() => {dispatch(setAddQuickLinkClick(false)); dispatch(setActiveTask(null)) }}>
          <NavLink to='/settings' className={({ isActive }) => isActive ? 'li-links active' : 'li-links'} style={({ isActive }) =>
            isActive ? setActiveSetting(true) : setActiveSetting(false)
          } >
            <span className={`menu-block ${(theme == "dark" && activeSetting == false) ? "sideBarDarkMode" : ""}`} style={{ backgroundColor: activeSetting ? colorPicker : '' }}>
              {
                theme == "dark" ? <SettingMenuIcon isMouseEnterSettingTask={isMouseEnterSettingTask} activeSetting={activeSetting} /> : <img src="/images/settings-menu.svg" alt='' />
              }
            </span>
            <span className="block-on-hover"
             css={{
              color: (isMouseEnterSettingTask && activeSetting) ? "black !important" :"white "
             }}
            style={{ backgroundColor: activeSetting ? colorPicker : '' }}>Settings</span>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default SideBar