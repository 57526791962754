import React from 'react'
import { useSelector } from 'react-redux';
import FocusTimer from './FocusTimer';
import RunningFocusTimerV2 from './RunningFocusTimerV2';

const TimerWidget = () => {
    const { activeTimer } = useSelector(state => state.focusTimer);

    return (
        activeTimer !== null ? <RunningFocusTimerV2 /> : <FocusTimer timeSetting = {false} /> 
    )
}

export default TimerWidget