/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useSelector } from 'react-redux'
import { jsx } from "@emotion/core";

function TodayMenu({ activeStyleHome, color = "black", isMouseEnter }) {

  const { theme, colorPicker } = useSelector(state => state.theme)

  let strokeColorCondition = (((theme == "dark") && activeStyleHome == false)) ? "#4C4C4C" : "white"
  let strCol = ((theme == "dark" && (colorPicker == "#27cb9a" || colorPicker == "#f8961e" || colorPicker == "#ffca3a" || colorPicker == "#4cc9f0" )) && activeStyleHome) ? color : ""
  let onMouseEnterCondition = isMouseEnter && color
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1463_905)">
      
        {
          strCol ? <path d="M10.0001 13.3334C11.841 13.3334 13.3334 11.841 13.3334 10.0001C13.3334 8.15913 11.841 6.66675 10.0001 6.66675C8.15913 6.66675 6.66675 8.15913 6.66675 10.0001C6.66675 11.841 8.15913 13.3334 10.0001 13.3334Z" fill={strCol} stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> :         <path d="M10.0001 13.3334C11.841 13.3334 13.3334 11.841 13.3334 10.0001C13.3334 8.15913 11.841 6.66675 10.0001 6.66675C8.15913 6.66675 6.66675 8.15913 6.66675 10.0001C6.66675 11.841 8.15913 13.3334 10.0001 13.3334Z" fill={(isMouseEnter || activeStyleHome) ? onMouseEnterCondition : color} stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ?  <path d="M10 1.66675V3.33341" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M10 1.66675V3.33341" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M10 16.6667V18.3334" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M10 16.6667V18.3334" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M4.16675 4.16675L5.41675 5.41675" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M4.16675 4.16675L5.41675 5.41675" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M14.5833 14.5833L15.8333 15.8333" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M14.5833 14.5833L15.8333 15.8333" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M1.66675 10H3.33341" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M1.66675 10H3.33341" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }

        {
          strCol ? <path d="M16.6667 10H18.3334" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M16.6667 10H18.3334" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M4.16675 15.8333L5.41675 14.5833" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M4.16675 15.8333L5.41675 14.5833" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }
        {
          strCol ? <path d="M14.5833 5.41675L15.8333 4.16675" stroke={strCol} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> : <path d="M14.5833 5.41675L15.8333 4.16675" stroke={(isMouseEnter && activeStyleHome) ? onMouseEnterCondition : strokeColorCondition} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        }

      </g>
      <defs>
        <clipPath id="clip0_1463_905">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default TodayMenu