import React from 'react'
import SignUpOutseta from './SignUp/SignUpOutseta'
import LoginOutseta from './SignUp/LoginOutseta'
import ResetPasswordConfirmation from './ResetPasswordConfirmation'
import EmailSentToInbox from './EmailSentToInbox'
import VerificationCode from './VerificationCode'
import PasswordReset from './PasswordReset'
import Welcome from './SignUp/Welcome'
import BackgroundImage from '../Components/common/BackgroundImage'
import { Route, Routes } from 'react-router-dom'
import Logout from './Logout'
import PlanUpgrade from './PlanUpgrade'
import ExperimentalSignUpOutseta from './SignUp/ExperimentalSignUpOutseta'
import ControlSignUpOutseta from './SignUp/ControlSignUpOutseta'

const PublicRoutes = () => {
  return (
    <div className="main-body-wrap auth-page boddyInner bg-white">
      <div className="page-wrapper">
        <div className="page-container">
          <Routes>
            <Route path='/logout' element={<Logout />} />
            <Route path='/plan-upgrade' element={<PlanUpgrade />} />
            <Route path='/welcome' element={<Welcome />}/>
            <Route path='/signup' element={<SignUpOutseta />} />
            <Route path='/experimental-signup' element={<ExperimentalSignUpOutseta />} />
            <Route path='/control-signup' element={<ControlSignUpOutseta />} />
            <Route path='/login' element={<LoginOutseta />} />
            <Route path='/reset-password' element={<ResetPasswordConfirmation />} />
            <Route path='/email-sent' element={<EmailSentToInbox />} />
            <Route path='/verification-code' element={<VerificationCode />} />
            <Route path='/password-reset' element={<PasswordReset />} />
          </Routes>
        </div>
      </div>
      <div className='backgroundBottom'>
        <BackgroundImage />
      </div>
    </div>
  )
}

export default PublicRoutes