/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import _ from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker"
import { useDispatch, useSelector } from 'react-redux'
import { setDateChange, setSelectedDate, setTaskDaySelected } from '../../redux/slices/TaskSlice'
import { fetchTaskList, temperatureToday } from '../action/common'
import Datepicker from '../DatePicker/components/Datepicker'
import CloudImg from "./CloudImg"
import FeedbackButton from "./Feedback/FeedbackButton"
import LeftArrow from "./LeftArrow"
import RightArrow from './RightArrow'
import GroupByDropdown from "../common/GroupByDropdown"
import { AuthContext } from "../../AuthProvider"
import { Link } from "react-router-dom"

function LandingHeader() {

  const dispatch = useDispatch()
  const todayDate = moment().format('YYYY-MM-DD')
  const { subscription } = useContext(AuthContext);
  // global state
  const { taskList, dateChange, taskDaySelected } = useSelector(state => state.task)
  const {   colorPicker, theme } = useSelector(state => state.theme);
  const [datePickerPos, setDatePickerPos] = useState(0);

  const [dateRange, setDateRange] = useState(new Date())
  const [isGettingData, setIsGettingData] = useState(false)
  const dateTime = moment(dateChange).format('YYYY-MM-DD')
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [temperature, setTemperature] = useState()
  const [loader, setLoader] = useState(true)
  const [closeDatePicker, setCloseDatePicker] = useState(null);

  const {settings} =useSelector(state=>state.settings)
  const timeFormat = settings?.timeFormat ? settings?.timeFormat : "LT"

  useEffect(() => {
    dispatch(setDateChange(new Date()))

    setDatePickerPos(document.querySelector('.header .dif-tag').getBoundingClientRect().left);
  }, [])

  useEffect(() => {
    dispatch(setDateChange(dateRange))
    setTimeout(() => {
      setDatePickerPos(document.querySelector('.header .dif-tag').getBoundingClientRect().left);
    }, 100)
  }, [dateRange])

  useEffect(() => {
    if (moment(dateChange).format('YYYY-MM-DD') == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }

    // if (!_.find(taskList, ["date", moment(dateChange).startOf('date').toISOString()])) {
    //   const requestBody = {
    //     "date": moment(dateChange).startOf('date').toISOString(),
    //   }
    //   let response = fetchTaskList(requestBody)
    // }

  }, [dateChange])


  const onDateChange = (date) => {
    dispatch(setSelectedDate(date))
    dispatch(setDateChange(date))
    setDateRange(date)
    // setCloseDatePicker(Date.now());
  }

  const onRightArrowClick = async () => {

    const newDate = new Date(moment(dateRange).subtract(1, 'days').startOf('date'));
    setDateRange(newDate);
    try {
    } catch ({ response }) {
      setIsGettingData(false)
    }
  }

  const onLeftArrowClick = async () => {
    if (dateTime == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }
    const newDate = new Date(moment(dateRange).add(1, 'days').startOf('date'));
    setDateRange(newDate);

  }

  const goToToday = async () => {
    if (dateTime == todayDate) {
      dispatch(setTaskDaySelected('Today'))
    } else {
      dispatch(setTaskDaySelected('DatePicker'))
    }
    const newDate = new Date(moment().startOf('date'));
    setDateRange(newDate);
  }


  const fetchTemp = async () => {

    let requestBody = {
      "latitude": latitude,
      "longitude": longitude
    }
    if (latitude) {
      try {
        setLoader(true)
        let response = await temperatureToday(requestBody)
        setLoader(false)
        setTemperature(response?.data?.responseData?.temperature)
      } catch ({ response }) {
        setLoader(false)
      }
    }
  }


  const successCallback = (position) => {
    setLatitude(position?.coords?.latitude)
    setLongitude(position?.coords?.longitude)
  };

  const errorCallback = (error) => {
  };


  useEffect(() => {
   // navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  }, [])

  // useEffect(() => {
  //   fetchTemp()
  // }, [latitude, longitude])

  const hideDatePickerOnHoverOut = (e) => {
    //e.target.closest('.days')?.querySelector('.react-datetime-picker__calendar-button')?.click();
    e.target.closest('.days')?.querySelector('.advancedDatePicker')?.classList?.remove('active');
  }

  // const showDatePickerOnHoverIn = (e) => {
  //   e.target.closest('.days').querySelector('.react-datetime-picker--closed')?.querySelector('.react-datetime-picker__calendar-button')?.click();
  // }
  
  
  return (
    <header className="header" id="header-id">
      <div className="d-flex header-inner">
        <div className="site-logo">
          <a className="logo">LifeTab</a>
        </div>
        <div className="heder-midle">
          <div className="date-form">
            <span className={`right_arrow cl-arrow ${theme == "dark" ? "cl-arrowDarkMode" : ""}`} style={{ cursor: isGettingData == true ? "not-allowed" : "pointer", border: theme == "dark" ? "1 px solid gray" : "" }} onClick={isGettingData == true ? {} : onRightArrowClick}>
              <RightArrow />
            </span>

            {/* <span className="days" style={{ color: theme == "dark" ? "gray" : "" }} onMouseLeave={hideDatePickerOnHoverOut}> */}
            <span className="day-time-slot">

            <span className="days" css={{
              'button.react-calendar__tile--active':{
                backgroundColor: `${colorPicker} !important`,
              },
              '.react-calendar .react-calendar__tile--now':{
                backgroundColor: `${colorPicker} !important`,
              },
              '.react-calendar__month-view__days button:hover':{
                backgroundColor: `${colorPicker} !important`,
              },
              '.advancedDatePicker.active':{
                marginLeft: `calc(-${datePickerPos}px + 50vw - 157px)`,
                marginTop: '50px'
              }
            }} 
            
            //  onMouseEnter={showDatePickerOnHoverIn} 
            onMouseLeave={hideDatePickerOnHoverOut}
            style={{ paddingBottom: '40px', marginTop: '40px', color: theme == "dark" ? "gray" : "" }}>
              {moment(dateChange).format('dddd')}
              <b className="dif-tag" style={{ color: theme == "dark" ? "white" : "" }}>
                {moment(dateChange).format('Do MMMM, YYYY')}
                {/* {useMemo(() => <DateTimePicker value={dateChange} onChange={(date) => { onDateChange(date) }} />, [dateChange])} */}
                {useMemo(() => <Datepicker showTopDates={false} value={dateChange} onChange={(date) => { onDateChange(date) }} />, [dateChange])}
              </b>
            </span>

            {
              dateTime == todayDate &&  <span className="time currentTime-landingheader"
              css={{
                color: `${colorPicker} !important`,
              }}

            >{moment().format(timeFormat)}</span>
            }

            {
              dateTime !== todayDate && <span className="time" style={{ cursor: "pointer", color: colorPicker }} onClick={goToToday}><img src="/images/dark-sun.svg" alt='' />Today</span>
            }
            </span>
            <span className={`left_arrow cl-arrow ${theme == "dark" ? "cl-arrowDarkMode" : ""}`} style={{ cursor: isGettingData == true ? "not-allowed" : "pointer" }} onClick={isGettingData == true ? {} : onLeftArrowClick}>
              {/* <img src='/images/left_arrow.svg' /> */}
              <LeftArrow />
            </span>
          </div>
        </div>

         <div className="header-right">
          {/*<div className="head-temp">

            <figure> <CloudImg /> </figure>

            <span className="temperature" style={{ color: theme == "dark" ? "white" : "" , opacity : theme == "dark" ? 0.3 :"" }}>{
              loader ? <span className="loaderTemp"></span> : (isNaN(temperature) ? null : <span>{parseInt(temperature)}{' C'}</span>)
            }</span>
            <span className="weatherType">Cloudy</span>
          </div>*/}
          {/* <GroupByDropdown /> */}
          {
            subscription?.isFreeTrial 
            && 
            <Link className="btn upgrade-plan-btn" to="/subscription-plan" css={{
              background: `linear-gradient(0deg,rgba(255,255,255,0.6),rgba(255,255,255,0.6)),linear-gradient(0deg,${colorPicker},${colorPicker}),linear-gradient(0deg,rgba(0,0,0,0.05),rgba(0,0,0,0.05)),#FFFFFF`
            }}>
              <span css={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${colorPicker}`,
                '-webkit-text-fill-color': 'transparent',
                '-webkit-background-clip': 'text !important'
              }}>Upgrade</span> {subscription?.daysLeft} {subscription?.daysLeft > 1 ? 'days' : 'day'} left
            </Link>
          }
          <FeedbackButton />
        </div> 
      </div>
    </header>
  )
}

export default LandingHeader
