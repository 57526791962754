import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setFocusTasksList } from '../redux/slices/TaskSlice';
import { cancelTimer, decreaseTimer, increaseTimer, initFocusTimer, pauseTimer, resetTimer, resumeTimer, setTimerFullscreen, stopTimer, tmpStopTimer } from '../redux/slices/timer';
import { useNavigate } from 'react-router-dom';

const FocusTimerSockets = React.memo(({socket}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(socket) {
            const handleFocusTimer = (data) => {
                const { action, timerSpan, redirect, focusTimer, fullscreenValue, tasks } = data;
                console.log('action', action);
                // Stop timer in other opened tabs
                switch (action) {
                    case 'initTimer':
                        dispatch(initFocusTimer(focusTimer));
                        break;

                    case 'fullscreen':
                        dispatch(setTimerFullscreen(fullscreenValue));
                        break;
                        
                    case "stopTimer":
                        dispatch(stopTimer());
                        redirect && navigate(window?.chrome?.storage ? '/index.html' : '/');
                        break;
    
                    case "tmpStopTimer":
                        dispatch(tmpStopTimer());
                        navigate('/focus-mode-completed');
                        break;

                    case "cancelTimer":
                        dispatch(cancelTimer());
                        break;
    
                    case "focusModePause":
                        dispatch(pauseTimer('FocusModePause'));
                        break;
    
                    case "resumeTimer":
                        dispatch(resumeTimer('focusMode'));
                        redirect && navigate(redirect);
                        break;
    
                    case "resetTimer":
                        dispatch(resetTimer());
                        break;
    
                    case "increaseTimer":
                        dispatch(increaseTimer(timerSpan));
                        break;
    
                    case "decreaseTimer":
                        dispatch(decreaseTimer(timerSpan));
                        break;

                    case 'focusTasksList':
                        dispatch(setFocusTasksList(tasks));
                        break;
                }
            };

            socket.off('focusTimer', handleFocusTimer);
            socket.on('focusTimer', handleFocusTimer);

            // Cleanup function to remove the event listener
            return () => {
                socket.off('focusTimer', handleFocusTimer);
            };
        }
    }, [socket])

    return (
        null
    )
});

export default FocusTimerSockets