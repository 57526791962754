/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from 'react-redux';
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFocusTasksList } from "../../../redux/slices/TaskSlice";
import AddTask from "../../../pages/AddTasks/AddTask";
import { startTimer } from "../../../redux/slices/timer";
import { Modal } from "react-bootstrap";
import { CheckFilledIcon, CirclePlusIcon, PlayRound } from "../../../Components/LandingHeader/Feedback/icons/Icons";
import FocusTimer from "../../../Components/FocusTimer/FocusTimer";

function FocusModeSettingsModal({ onHide, show }) {
    const { themeColor, colorPicker, theme } = useSelector(state => state.theme);
    const {taskList} = useSelector(state => state.task);
    const [todayTasks, setTodayTasks] = useState([]);
    const [masterTodayTasks, setMasterTodayTasks] = useState([]);
    const [focusTasks, setFocusTasks] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setFocusTasks([]);
    }, []);

    useEffect(() => {
        const pendingTasks = taskList.filter(t => t.status != 1 && moment(t?.date).startOf('date').toISOString() == moment().startOf('date').toISOString());
        
        if(pendingTasks?.length > 0){
            setTodayTasks(pendingTasks);
            setMasterTodayTasks(pendingTasks)
        }
    }, [taskList])

    useEffect(() => {
        setTodayTasks(masterTodayTasks.filter(a => !focusTasks.map(b=>b.id).includes(a.id)));

        dispatch(setFocusTasksList(focusTasks));
    }, [focusTasks])

    const addToFocusList = (e, task) => {
        e.preventDefault();
        setFocusTasks(focusTasks => [...focusTasks, task]);
        setTodayTasks(todayTasks => todayTasks.filter(t => t.id != task.id));
    };

    const removeFocusTask = (e, task) => {
        e.preventDefault();

        setFocusTasks(focusTasks => focusTasks.filter(t => t.id != task.id));
    }

    const startTimerHandler = (e) => {
        e.preventDefault();
        if(focusTasks.length){
            dispatch(startTimer('focusMode'));
        }
    }

    return (
        <Modal show={show} onHide={onHide} className="focus-mode-settings-modal">
            <Modal.Body>
            <div className="full-model md-model-updates" style={{ display: 'block' }}>
                <div className="full-model-body" style={{background : theme == "dark" ? "black" :""}}>
                    <div className="body-data">
                    <div className="page-wrapper" id="focus-mode-settings-page">
                        <div className="page-container">
                            <FocusTimer defaultOption="focusMode" timerOptions = {false} showTimeTabs = {true} /> 
                            <div className="tasks-list-container">
                                <div className="focus-session-tasks" style={{background: theme == "dark" ? '#000' : '#fff'}}>
                                    <div className="focused-items-heading">
                                    Selected Tasks {focusTasks.length > 0 ? <span style={{color: colorPicker}}>{focusTasks.length} Selected</span> : null}
                                    </div>
                                    <li className="task-item li-task-list" css={{'&:hover': {background: 'none'}}}>
                                        <AddTask />
                                    </li>
                                    <ul className="ul-task-list">
                                    {
                                        focusTasks.map(task => {
                                            return <li className="task-item li-task-list" key={task.id} 
                                                    css={{
                                                    background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`
                                                    }}
                                                >
                                                <span className="checkmark"
                                                    css={{
                                                        border: theme == "dark" ? '1px solid white !important' : "1px solid black !important"
                                                    }}
                                                />
                                                <span
                                                    className="title"
                                                    css={{
                                                        color: theme == "dark" ? "white !important" : "black !important",
                                                    }}
                                                >{task?.title}
                                                </span>
                                                <a href="#" onClick={e => removeFocusTask(e, task)}><CheckFilledIcon color={colorPicker} /></a>
                                            </li>
                                        })
                                    }
                                    {
                                        todayTasks.map(task => {
                                            return <li className="task-item li-task-list" key={task.id}
                                            css={{
                                            '&:hover': {
                                                background: theme == "dark" ? `linear-gradient(0deg,rgba(0,0,0,0.8),rgba(0,0,0,0.8)),linear-gradient(0deg,${colorPicker},${colorPicker}),#000000 !important;` : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${colorPicker} !important`,
                                            },
                                            '&:hover span.title': {
                                                color: theme == "dark" ? "white !important" : "black !important"
                                            },
                                
                                            }}
                                            >
                                            <span className="checkmark"
                                                css={{
                                                    border: theme == "dark" ? '1px solid white !important' : "1px solid black !important"
                                                }}
                                            />
                                            <span
                                                className="title"
                                                css={{
                                                    color: theme == "dark" ? "white !important" : "black !important",
                                                }}
                                            >{task?.title}
                                            </span>
                                            <a href="#" onClick={e => addToFocusList(e, task)}><CirclePlusIcon color={colorPicker} /></a>
                                        </li>
                                        })
                                    }
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-actions">
                                <a href="#" className="cancel-btn" style={{background: theme == 'dark' ? 'grey' : 'white'}} onClick={onHide}>Cancel</a>        
                                <a href="#" className="start-session-btn" style={{background: colorPicker}} onClick={startTimerHandler}><PlayRound color="white" /> Skip Tasks</a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default FocusModeSettingsModal